import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import ProjectRepository from '../../api/repository/ProjectRepository';
import { type GetOneProject } from '../../api/repository/types/project.type';
import ProjectBaseForm, { type ProjectBaseFormProps } from './form/project.form';
import { notify } from '../../services/notification/notification';
import './styles/project.edit.scss';
import Translation from './component/translation/translation';
import ProjectDonationList from './project.donations.list';

export type SubPage = 'informations' | 'translations' | 'products' | 'dons';

const ProjectEdit: React.FC = () => {
  const [project, setProject] = useState<GetOneProject>();
  const { id, type } = useParams() as { id: string; type: SubPage };

  useEffect(() => {
    ProjectRepository.get<GetOneProject>(id).then((response) => {
      const currentProject = response.data;
      setProject(currentProject);
    });
  }, [id]);

  const onSubmitBase: ProjectBaseFormProps['onSubmit'] = (values) => {
    ProjectRepository.edit(id, values).then(() => {
      notify({
        title: 'Mise à jour effectuée',
        message: `Le projet ${values.name} a été mis à jour`
      });
    });
  };

  if (!project) {
    return null;
  }

  const getPage = () => {
    switch (type) {
      case 'informations':
        return <ProjectBaseForm onSubmit={onSubmitBase} initialProject={project} status="edit" />;
      case 'translations':
        return <Translation project={project} />;
      case 'dons':
        return <ProjectDonationList project={project} />;
    }

    return null;
  };

  const menus: Array<{ title: string; path: SubPage }> = [
    {
      title: 'Traductions',
      path: 'translations'
    },
    {
      title: 'Informations',
      path: 'informations'
    },
    {
      title: 'Dons',
      path: 'dons'
    }
  ];

  const menu = (
    <nav className="menu">
      <ul className="m-0 p-0">
        {menus.map((item) => {
          return (
            <Link key={item.title} to={`/projects/${project.id}/edit/${item.path}`}>
              <li>
                <Button variant={type === item.path ? 'primary' : 'outline-primary'}>{item.title}</Button>
              </li>
            </Link>
          );
        })}
      </ul>
    </nav>
  );

  return (
    <Container className="edit-project-wrapper" fluid>
      <div className="position-fixed title-fixed">Project courant: {project.name}</div>
      <Row>
        <Col>{menu}</Col>
      </Row>
      <Row className="mt-3">
        <Col>{getPage()}</Col>
      </Row>
    </Container>
  );
};

export default ProjectEdit;
