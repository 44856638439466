import React, { useState } from 'react';
import ToggleShowWrapper, { IconEdit } from '../../../components/wrapper/toggle-show.wrapper';
import { Donation } from '../../../api/repository/types/payment.type';
import MetadataPaymentModal from '../modal/metadata.modal';

const MetadataCell: React.FC<{ donation: Donation }> = ({ donation }) => {
  const [metadata, setMetadata] = useState(donation.data);

  if (!metadata) {
    return null;
  }

  return (
    <ToggleShowWrapper>
      {({ toggle, hidden, shown }) => (
        <>
          {shown && (
            <MetadataPaymentModal
              donation={donation}
              onSuccess={(donation) => {
                setMetadata(donation.data);
                toggle();
              }}
            />
          )}
          {hidden && JSON.stringify(metadata)}
        </>
      )}
    </ToggleShowWrapper>
  );
};

export default MetadataCell;
