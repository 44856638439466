import { menu, type NavigationItem } from '../../route/route';
import { Auth } from '../../../services/Auth';
import { omit } from 'underscore';

const generateMenu = () => {
  return menu
    .filter((item) => {
      return Auth.hasAccess(item);
    })
    .map((item) => {
      return omit(item, ['page']);
    });
};

export const navigationMenu: NavigationItem[] = generateMenu();
