import { type ColumnsType } from '../../../components/table/types/columns.type';
import { Newsletter } from '../../../api/repository/types/newsletter.type';

export const columns: Array<ColumnsType<Newsletter>> = [
  {
    dataField: 'email',
    text: 'Email',
    sort: true
  },
  {
    dataField: 'createdAt',
    text: 'Créé le',
    preRender: {
      type: 'date'
    },
    sort: true
  }
];
