import { BaseRepository } from './BaseRepository';
import { type EditCurrency, type GetCurrency, type GetOneCurrency } from './types/currency.type';

class CurrencyRepository<T = GetCurrency> extends BaseRepository<T> {
  getArea() {
    return '/api/currencies';
  }

  get<T = GetOneCurrency>(id: string) {
    return super.get<T>(id);
  }

  edit<T = EditCurrency>(id: string, type: T | any) {
    return super.edit<T>(id, type);
  }
}

export default new CurrencyRepository();
