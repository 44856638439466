import React from 'react';
import { Form } from 'react-bootstrap';
import { type BaseFieldType, type HandleChange } from './types/base.field.type';
import Error from './transverse/error';
import Helper from './transverse/helper';

type Props = BaseFieldType & {
  type?: 'checkbox' | 'switch' | 'radio';
  onChange: HandleChange;
};

const BooleanField: React.FC<Props> = ({ formGroupProps, label, ...inputProps }) => {
  const { name, type = 'checkbox', showError, onChange, errors, helper, ...restFieldProps } = inputProps;

  const valueProps = {
    checked: restFieldProps.value,
    defaultChecked: restFieldProps.defaultValue
  };

  return (
    <Form.Group {...formGroupProps}>
      <Form.Check id={name} {...restFieldProps} label={label} type={type} name={name} onChange={onChange} isInvalid={showError && undefined !== errors?.[name]} {...valueProps} />
      <Error error={errors?.[name]} showError={showError} />
      <Helper text={helper} />
    </Form.Group>
  );
};

export default BooleanField;
