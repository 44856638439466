import React from 'react';
import { type FormikConfig } from 'formik';
import { type AdminCreateType } from '../types/admin.create.type';
import * as yup from 'yup';
import AdminRepository from '../../../api/repository/AdminRepository';
import AdminForm from '../admin-form/admin.form';
import { type AdminType } from '../../../api/repository/types/admin.type';
import { notify } from '../../../services/notification/notification';

export interface Props {
  onCreate?: (admin: AdminType) => void;
}

const AdminCreate: React.FC<Props> = ({ onCreate }) => {
  const schema = yup.object<Partial<AdminCreateType>>({
    firstName: yup.string().required(),
    password: yup.string().required().min(6),
    lastName: yup.string().required(),
    email: yup.string().email().required()
  });

  const onSubmit: FormikConfig<AdminCreateType>['onSubmit'] = (values) => {
    AdminRepository.new(values).then((res) => {
      onCreate?.(res.data);
      notify({
        title: 'Un nouvel administrateur a été créé.',
        message: `${values.firstName} vient d'être créé`
      });
    });
  };

  return <AdminForm onSubmit={onSubmit} validationSchema={schema} />;
};

export default AdminCreate;
