import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { type BaseFieldType, type HandleChange } from './types/base.field.type';
import Label from './transverse/label';
import Helper from './transverse/helper';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import dayjs from 'dayjs';

type Props = BaseFieldType & {
  onChange: HandleChange;
  // the format date is given in the onChange(value).
  valueFormat?: string;
  dateProps?: Partial<ReactDatePickerProps>;
};

const DatetimeField: React.FC<Props> = React.memo(
  ({ formGroupProps, label, ...inputProps }) => {
    const { showError, name, onChange, errors, helper, dateProps, valueFormat = 'YYYY-MM-DD', ...restFieldProps } = inputProps;
    const [selectedDate, setSelectedDate] = useState<Date>();

    const onDateChange = (date: Date) => {
      setSelectedDate(date);

      onChange(date ? dayjs(date).format(valueFormat) : '');
    };

    return (
      <Form.Group className="mb-3" {...formGroupProps}>
        <Label label={label} />

        <DatePicker wrapperClassName="d-block" className="form-control" dateFormat="dd/MM/yyyy" isClearable onChange={onDateChange} selected={selectedDate} {...dateProps} />

        <Helper text={helper} />
      </Form.Group>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.errors?.[prevProps.name] === nextProps.errors?.[nextProps.name] && prevProps.value === nextProps.value;
  }
);

export default DatetimeField;
