import React, { useEffect, useState } from 'react';
import { type FormikConfig } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { notify } from '../../../services/notification/notification';
import { pick } from 'underscore';
import FaqRepository from '../../../api/repository/FaqRepository';
import { type CreateFaqType, type GetFaqType } from '../../../api/repository/types/faq.type';
import FaqForm from '../form/faq.form';
import { Button } from 'react-bootstrap';

export interface Props {
  id?: string;
}

const FaqEdit: React.FC<Props> = () => {
  const [faq, setFaq] = useState<GetFaqType>();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    FaqRepository.get(id).then((response) => {
      setFaq(response.data);
    });
  }, [id]);

  const onSubmit: FormikConfig<CreateFaqType>['onSubmit'] = (faq: CreateFaqType) => {
    const edit = pick(faq, ['title', 'description', 'position', 'state', 'locale', 'page']);

    FaqRepository.edit(id, edit).then(() => {
      notify({
        title: 'Faq modifiée.',
        message: "l'entrée de la faq a été modifiée"
      });
    });
  };

  const schema = yup.object<Partial<CreateFaqType>>({
    title: yup.string().required(),
    description: yup.string().required()
  });

  if (!faq) {
    return <>Chargement...</>;
  }

  const duplicate = () => {
    FaqRepository.duplicate(id).then(({ data }) => {
      notify({
        title: 'Dupliqué avec succès',
        message: 'Vous allez être redirigé vers la faq dupliqué'
      });

      setTimeout(() => {
        window.location.replace(`/faqs/${data.id}/edit`);
      }, 2000);
    });
  };

  return (
    <>
      <div className="my-2">
        <Button onClick={duplicate}>Dupliquer</Button>
      </div>
      <FaqForm initialValue={faq} onSubmit={onSubmit} validationSchema={schema} />
    </>
  );
};

export default FaqEdit;
