import React from 'react';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import LoadingLayout from './components/layout/loading/loading';
import { createRoot } from 'react-dom/client';
import './index.scss';

const container = document.getElementById('root')!;

createRoot(container).render(
  <BrowserRouter>
    <ReactNotifications />
    <LoadingLayout />
    <App />
  </BrowserRouter>
);

reportWebVitals();
