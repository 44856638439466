import { type AdminType } from '../api/repository/types/admin.type';
import { Auth } from '../services/Auth';
import { signal } from '@preact/signals-react';

export const menuState = signal<Menu>({ opened: false, activeMenu: 0 });
export const loadingState = signal(false);
export const userState = signal<AdminType | null>(Auth.getUser());
export const maintenanceState = signal(false);

export const toggleLoading = () => {
  loadingState.value = !loadingState.value;
};

export const toggleMenu = () => {
  menuState.value = { ...menuState.value, opened: !menuState.value.opened };
};

export interface Menu {
  opened: boolean;
  activeMenu: number; // index of active menu
}

export interface UserLogged {
  id: string;
  firstName: string;
  lastName: string;
  createdAt: string;
  roles: string[];
}
