import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Formik, type FormikConfig } from 'formik';
import InputField from '../../../components/form/fields/input.field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSearchMinus } from '@fortawesome/free-solid-svg-icons';

interface Props {
  onSubmit: FormikConfig<PaymentFilterType>['onSubmit'];
}

interface PaymentFilterType {
  emailPayments?: string;
}

const PaymentFilter: React.FC<Props> = ({ onSubmit }) => {
  const filter: PaymentFilterType = {};
  const [showFilter, setShowFilter] = useState<boolean>(false);

  return (
    <div className="my-4">
      <Button
        onClick={() => {
          setShowFilter(!showFilter);
        }}
      >
        <FontAwesomeIcon icon={showFilter ? faSearchMinus : faSearch} /> Filtrer
      </Button>
      <Formik initialValues={filter} onSubmit={onSubmit}>
        {(formik) => {
          const { handleSubmit, handleChange, resetForm, values } = formik;
          const { emailPayments } = values;

          return (
            <Form className={showFilter ? 'alert-primary p-2 my-2 w-50 rounded' : 'd-none'} onSubmit={handleSubmit}>
              <Row>
                <Col sm="4">
                  <InputField name="emailPayments" value={emailPayments} placeholder="Email" onChange={handleChange} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="d-flex justify-content-end">
                    <Button className="mr-2" variant="primary" type="submit">
                      Filtrer
                    </Button>
                    <Button
                      variant="secondary"
                      type="reset"
                      onClick={() => {
                        resetForm();
                        handleSubmit();
                      }}
                    >
                      Supprimer les filtres
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PaymentFilter;
