import React from 'react';
import { Formik } from 'formik';
import { type Asset, type DonAsset, type GetOneProject, type ProjectTranslationType } from '../../../../api/repository/types/project.type';
import { Button, Container, Form } from 'react-bootstrap';
import ProjectRepository from '../../../../api/repository/ProjectRepository';
import { notify } from '../../../../services/notification/notification';
import { type LocaleType } from '../../../../api/repository/types/transverse.type';
import ProjectTranslation from '../project.translation';

interface Props {
  locale: LocaleType;
  project: GetOneProject;
  initialTranslation?: ProjectTranslationType;
  assets: Asset[];
  images: DonAsset[];
}

/**
 * @constructor
 */
const TranslationStep: React.FC<Props> = ({ assets, images, locale, project, initialTranslation }) => {
  const projectId = project.id;
  const products = project.products ? project.products : [];

  const initialValue: ProjectTranslationType | any = {
    content: '',
    summary: '',
    state: 'draft',
    locale: locale,
    assets: assets,
    products: products,
    slug: '',
    title: '',
    assetTitle: '',
    assetDescription: '',
    ...initialTranslation
  };

  const onSubmit = (values: ProjectTranslationType) => {
    ProjectRepository.editTranslation(projectId, values).then(() => {
      notify({
        title: 'Mise à jour avec succès',
        message: 'La traduction a été mise à jour',
        onRemoval: () => {
          window.location.reload();
        }
      });
    });
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValue}>
      {(formik) => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Container>
              <ProjectTranslation formik={formik} initialTranslation={initialTranslation} project={project} assets={project.assets} images={project.images} locale={locale} />

              <div className="text-center">
                <Button type="submit">Valider</Button>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TranslationStep;
