import React from 'react';
import { Formik, type FormikConfig } from 'formik';
import InputField from '../../../components/form/fields/input.field';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { type RequestProjectFilter } from '../../../api/repository/types/request-project.type';
import DatetimeField from '../../../components/form/fields/datetime.field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import UserAutocomplete from '../../../components/autocomplete/user.autocomplete';

export interface Props {
  initialValue?: RequestProjectFilter;
  onSubmit: FormikConfig<RequestProjectFilter>['onSubmit'];
}

const RequestProjectFilterForm: React.FC<Props> = ({ initialValue, onSubmit }) => {
  const requestProject: RequestProjectFilter = {
    user: '',
    creationDateEnd: '',
    creationDateStart: '',
    ...initialValue
  };

  return (
    <Formik initialValues={requestProject} onSubmit={onSubmit} validateOnChange={false}>
      {(formik) => {
        const { errors, setFieldValue } = formik;

        const baseProps = {
          errors: errors,
          showError: true
        };

        const handleDateChange = (name: string) => {
          return (value: any) => {
            setFieldValue(name, value);
          };
        };

        return (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <Container fluid>
              <Row>
                <Col xs={6}>
                  <UserAutocomplete label="Email de l'utilisateur" onChange={(user) => setFieldValue('user', user.value)} />
                </Col>
                <Col>
                  <DatetimeField label="Date de création (depuis)" onChange={handleDateChange('creationDateStart')} name="creationDateStart" {...baseProps} />
                </Col>
                <Col>
                  <DatetimeField label="Date de création (jusqu'à)" onChange={handleDateChange('creationDateEnd')} name="creationDateEnd" />
                </Col>
              </Row>

              <div className="clearfix">
                <div className="float-end">
                  <Button variant="primary" type="submit">
                    <FontAwesomeIcon icon={faSearch} /> Filtrer
                  </Button>
                </div>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RequestProjectFilterForm;
