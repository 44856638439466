import React from 'react';
import { type ColumnsType } from '../../../components/table/types/columns.type';
import { type DonationDetails } from '../../../api/repository/types/payment.type';
import { Link } from 'react-router-dom';

export const columns: Array<ColumnsType<DonationDetails>> = [
  {
    dataField: 'payment',
    text: 'Payment id & status',
    isDummyField: true,
    formatter: (_cell, row) => {
      if (!row) {
        return <></>;
      }

      return (
        <>
          <Link to={`/payments/${row.id}/view`}>{row.id}</Link>
          <br />
        </>
      );
    },
    style: { width: 150 }
  },

  {
    dataField: 'payment',
    text: 'Donateur',
    formatter: (payment) => {
      return <>{payment ? <Link to={`/users/${payment[0].user.id}/edit/`}>{payment[0].user.email}</Link> : ''}</>;
    }
  },
  {
    dataField: 'total',
    text: 'Total',
    formatter: (total) => <>{total} €</>
  },
  {
    dataField: 'createdAt',
    text: 'Créé le',
    preRender: {
      type: 'datetime'
    }
  },
  {
    dataField: 'project',
    text: 'Projet',
    formatter: (project) => {
      return <>{project[0] ? <Link to={`/projects/${project[0].id}/edit/informations`}>{project[0].name}</Link> : 'Dons globaux'}</>;
    }
  },

  {
    dataField: 'media',
    text: 'Media',
    formatter: (media) => {
      return <>{media ? <Link to={`${media}`}>{media}</Link> : 'Aucun média'}</>;
    }
  }
];
