import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  label?: string;
}

const Label: React.FC<Props> = ({ label }) => {
  if (!label) {
    return null;
  }

  return <Form.Label>{label}</Form.Label>;
};

export default Label;
