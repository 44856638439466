import { BaseRepository } from './BaseRepository';
import { GetStatistics } from './types/statitics.type';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

class StatiticsRepository extends BaseRepository<GetStatistics> {
  getArea() {
    return '/api/statistics';
  }

  async getStatistic<A = GetStatistics>(filters: any = {}, otherConfig: Partial<AxiosRequestConfig> = {}): Promise<AxiosResponse<A>> {
    return this.client.get<A>(this.getBaseEndpoint() + '/', filters, otherConfig);
  }
}

export default new StatiticsRepository();
