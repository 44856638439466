import { BaseRepository } from './BaseRepository';
import { type CreateKeyNumber, type EditKeyNumber, type GetKeyNumber, type GetOneKeyNumber } from './types/key-number.type';

class KeyNumberRepository<T = GetKeyNumber> extends BaseRepository<T> {
  getArea() {
    return '/api/key-numbers';
  }

  get<T = GetOneKeyNumber>(id: string) {
    return super.get<T>(id);
  }

  edit<T = EditKeyNumber>(id: string, type: T | any) {
    return super.edit<T>(id, type);
  }

  editState<T>(id: string, state: CreateKeyNumber['state']) {
    const url = this.getBaseEndpoint() + '/' + id + '/state';

    return this.client.put<T>(url, { state });
  }
}

export default new KeyNumberRepository();
