import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import KeyNumberRepository from '../../api/repository/KeyNumberRepository';
import { notify } from '../../services/notification/notification';
import { type GetKeyNumber } from '../../api/repository/types/key-number.type';
import TablePagination from '../../components/table/TablePagination';
import { columns } from './services/columns';

const KeyNumberList: React.FC = () => {
  const [selected, setSelected] = useState<GetKeyNumber>();
  const [deletedRows, setDeletedRows] = useState<any[]>([]);

  const deleteContact = () => {
    KeyNumberRepository.delete(selected!.id).then(() => {
      deletedRows.push(selected!.id);
      setDeletedRows([...deletedRows]);
      notify({
        title: 'Chifre clé supprimé avec succès',
        message: 'Le chiffre clé a été supprimé'
      });
    });
  };

  return (
    <div className="crud-list">
      <h1>Liste des Chiffres clés.</h1>

      <div className="action-wrapper">
        <Button disabled={selected === undefined} onClick={deleteContact}>
          Supprimer
        </Button>

        <Link to={'/key-numbers/new'}>
          <Button>Nouveau</Button>
        </Link>
      </div>

      <TablePagination
        repository={KeyNumberRepository}
        columns={columns}
        keyField="id"
        rowClasses={(row) => {
          return deletedRows.includes(row.id) ? 'd-none' : '';
        }}
        selectRow={{
          clickToSelect: true,
          mode: 'radio',
          onSelect: (entity) => {
            setSelected(entity);
          }
        }}
      />
    </div>
  );
};

export default KeyNumberList;
