import React, { useEffect, useState } from 'react';
import { Button, Modal, type ModalProps } from 'react-bootstrap';
import ImageField, { type CropImageProps } from '../fields/image.field';
import { type Crop } from 'react-image-crop';

interface Props {
  show: boolean;
  title?: string;
  onSelect: (imageEncoded: string) => void;
  onHide?: () => void;
  modalProps?: ModalProps;
  cropProps?: Crop;
  reactCropProps?: Partial<CropImageProps['reactCropProps']>;
}

const ImageModal: React.FC<Props> = ({ reactCropProps, cropProps, title = 'Edition de limage', onSelect, onHide, ...rest }) => {
  const [imageEncoded, setImageEncoded] = useState('');
  const [show, setShow] = useState(rest.show);

  useEffect(() => {
    setShow(rest.show);
  }, [rest.show]);

  useEffect(() => {
    !show && onHide?.();
  }, [show]);

  return (
    <Modal
      {...rest.modalProps}
      show={show}
      onHide={() => {
        onHide?.();
      }}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ImageField
          reactCropProps={reactCropProps}
          cropProps={cropProps}
          onChange={(name, encoded) => {
            setImageEncoded(encoded);
          }}
          name={'image'}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          size="lg"
          variant="primary"
          onClick={() => {
            onSelect(imageEncoded);
            setShow(false);
          }}
        >
          Valider
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
