import React, { useEffect, useState } from 'react';
import './Header.scss';
import { Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser } from '@fortawesome/free-solid-svg-icons';
import BooleanField from '../../form/fields/boolean.field';
import MaitenanceStatusRepository from '../../../api/repository/MaitenanceStatusRepository';
import { notify } from '../../../services/notification/notification';
import { maintenanceState, toggleMenu, userState } from '../../../state-management/global-store';
import { confirm } from 'react-bootstrap-confirmation';

const Header: React.FC = () => {
  useEffect(() => {
    MaitenanceStatusRepository.getMaintenanceStatus().then(({ data }) => {
      maintenanceState.value = data.maintenance;
    });
  }, []);

  const handleMaintenanceToggle = () => {
    confirm(maintenanceState.value ? 'Voulez-vous vraiment désactiver le mode maintenance ?' : 'Voulez-vous vraiment activer le mode maintenance ?').then(async (validate) => {
      if (!validate) {
        return;
      }

      const newMaintenance = !maintenanceState.value;
      maintenanceState.value = newMaintenance;

      await MaitenanceStatusRepository.toggleMaintenance(newMaintenance);

      if (newMaintenance) {
        notify({
          title: 'Toggle maintenance successful',
          message: 'Mode maintenance activé'
        });
      } else {
        notify({
          title: 'Toggle maintenance successful',
          message: 'Mode maintenance désactivé'
        });
      }
    });
  };

  return (
    <Navbar bg="light" className="header-wrapper" sticky="top">
      <Navbar.Brand href="#home">
        <div className="d-flex align-items-center">
          <div className="toggle-menu" tabIndex={0} onClick={toggleMenu}>
            <i>
              <FontAwesomeIcon icon={faBars} />
            </i>
          </div>

          <img src="/logo.png" width="120" className="d-inline-block align-top" alt="Nouvelle optique" />
        </div>
      </Navbar.Brand>

      <Navbar.Collapse className="justify-content-end">
        <span className="me-3">
          <BooleanField type="switch" name="maintenance" onChange={handleMaintenanceToggle} label="Activer mode Maintenance" value={maintenanceState.value} />
        </span>
        <Navbar.Text className="d-inline-block me-3">
          <FontAwesomeIcon className="me-2 text-primary" icon={faUser} />
          {userState.value?.firstName}
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
