import React from 'react';
import { type FormikConfig } from 'formik';
import * as yup from 'yup';
import ConfigurationRepository from '../../api/repository/ConfigurationRepository';
import { type CreateConfiguration } from '../../api/repository/types/configuration.type';
import { notify } from '../../services/notification/notification';
import ConfigurationForm from './form/configuration.form';

export interface Props {
  id?: string;
}

const ConfigurationCreate: React.FC<Props> = () => {
  const onSubmit: FormikConfig<CreateConfiguration>['onSubmit'] = (entity: CreateConfiguration) => {
    ConfigurationRepository.new(entity).then(() => {
      notify({
        title: 'Création effectuée.',
        message: 'La configuration a été créé'
      });
    });
  };

  const schema = yup.object<Partial<CreateConfiguration>>({
    value: yup.string().required(),
    key: yup.string().required()
  });

  return <ConfigurationForm onSubmit={onSubmit} validationSchema={schema} />;
};

export default ConfigurationCreate;
