import { useEffect, useState } from 'react';
import { type OptionItem } from '../../components/form/fields/types/option.type';
import ProjectRepository from '../repository/ProjectRepository';

export const useProjectParents = (): [OptionItem[], any] => {
  const [parents, setParents] = useState<OptionItem[]>([]);
  useEffect(() => {
    ProjectRepository.getAll({
      groups: 'short',
      isParent: true
    }).then(({ data }) => {
      setParents(
        data.results.map((project) => ({
          label: project.name,
          value: project.id
        }))
      );
    });
  }, []);

  return [parents, setParents];
};
