import { BaseRepository } from './BaseRepository';
import { type UserGet, type UserListType } from './types/user.type';
import { type AxiosRequestConfig } from 'axios';

class UserRepository<T = UserGet> extends BaseRepository<T> {
  getArea() {
    return '/api/users';
  }

  getAll<T = UserListType>(filters: any = {}, otherConfig: Partial<AxiosRequestConfig> = {}) {
    return super.getAll<T>(filters, otherConfig);
  }

  getMe() {
    const url = this.getBaseEndpoint() + '/me';

    return this.client.get<T>(url);
  }
}

export default new UserRepository();
