import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { type GetConfiguration } from '../../../api/repository/types/configuration.type';
import { type ColumnsType } from '../../../components/table/types/columns.type';

export const columns: Array<ColumnsType<GetConfiguration>> = [
  {
    dataField: 'id',
    text: 'Id'
  },
  {
    dataField: 'key',
    text: 'Clé'
  },
  {
    dataField: 'value',
    text: 'Valeur'
  },
  {
    dataField: 'type',
    text: 'type'
  },
  {
    dataField: 'updatedAt',
    text: 'Date dernière modif.',
    preRender: {
      type: 'date'
    }
  },
  {
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    formatter: (_cell, entity) => {
      return (
        <Link to={`/configurations/${entity.id}/edit`}>
          <FontAwesomeIcon icon={faEdit} />
        </Link>
      );
    }
  }
];
