import React from 'react';
import { Form } from 'react-bootstrap';
import { type BaseFieldType } from './types/base.field.type';
import Label from './transverse/label';
import Error from './transverse/error';
import { type OptionItem } from './types/option.type';
import Helper from './transverse/helper';
import Select, { type ActionMeta } from 'react-select';

type Props = BaseFieldType & {
  options: OptionItem[];
  onChange: (value: any, action: ActionMeta<any>) => void;
};

const MultipleSelectField: React.FC<Props> = ({ formGroupProps, label, ...inputProps }) => {
  const { options, name, showError, onChange, errors, helper, ...restFieldProps } = inputProps;

  const valueProps = {
    value: restFieldProps.value,
    defaultValue: restFieldProps.defaultValue
  };

  return (
    <Form.Group className="mb-3" {...formGroupProps}>
      <Label label={label} />
      <Select {...restFieldProps} className="multi-select" classNamePrefix="ms" isMulti name={name} options={options} isSearchable onChange={onChange} {...valueProps} />
      <Error error={errors?.[name]} showError={showError} />
      <Helper text={helper} />
    </Form.Group>
  );
};

export default MultipleSelectField;
