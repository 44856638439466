import { BaseRepository } from './BaseRepository';
import { type CreateFaqType, type EditFaqType, type GetFaqType } from './types/faq.type';

class FaqRepository<T = GetFaqType> extends BaseRepository<T> {
  getArea() {
    return '/api/faqs';
  }

  edit<T = EditFaqType>(id: string, type: T | any) {
    return super.edit<T>(id, type);
  }

  duplicate(id: string) {
    const url = this.getBaseEndpoint() + `/${id}/duplicate`;

    return this.client.post<GetFaqType>(url, {});
  }

  editState<T>(id: string, state: CreateFaqType['state']) {
    const url = this.getBaseEndpoint() + '/' + id + '/state';

    return this.client.put<T>(url, { state });
  }
}

export default new FaqRepository();
