import React, { useEffect, useState } from 'react';
import ProjectRepository from '../../../api/repository/ProjectRepository';
import { GetListProject } from '../../../api/repository/types/project.type';
import { Paginator } from '../../../api/repository/types/paginator.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const LastProjectReached: React.FC = () => {
  const [projects, setProjects] = useState<Paginator<GetListProject>>();

  useEffect(() => {
    ProjectRepository.getLastProjectReached().then((response) => {
      setProjects(response.data);
    });
  }, []);

  if (!projects) {
    return null;
  }

  return (
    <div className="leaderboard">
      <h1>Liste des derniers projets terminés</h1>
      <ul>
        {projects.results.map((project) => (
          <li key={project.id}>
            <mark>{project.name}</mark>
            <small>
              <a rel="noreferrer" href={`${process.env.REACT_APP_API_BASE_URL}/fr/donations/${project.translations[0]?.slug}`} target="_blank">
                <FontAwesomeIcon icon={faGlobe} />
              </a>
              <Link target="_blank" to={`/projects/${project.id}/edit/informations`}>
                <FontAwesomeIcon icon={faEdit} />
              </Link>
              <span>{project.total} €</span>
            </small>
          </li>
        ))}

        {projects.results.length === 0 && <li>Aucun actuellement</li>}
      </ul>
    </div>
  );
};

export default LastProjectReached;
