import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { GetStatistics, Metric } from '../../../api/repository/types/statitics.type';
import DailyChart from './daily.chart';
import { generateOption } from './service/generate-option';
import dayjs from 'dayjs';
import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'dayjs/locale/fr';

dayjs.locale('fr');

interface Props {
  stats: GetStatistics['payments'];
}

const NB_MONTH = 12;

const PaymentChart: React.FC<Props> = ({ stats }) => {
  const options = generateOption('Nb. dons mensuel');

  const labels = useMemo(() => {
    return [...Array(NB_MONTH)].map((_, month) => {
      return dayjs()
        .subtract(NB_MONTH - (month + 1), 'months')
        .format('MMM');
    });
  }, []);

  const getData = useCallback((stats: (Metric | undefined)[]) => {
    return [...Array(NB_MONTH)].map((_, month) => {
      const date = dayjs()
        .subtract(NB_MONTH - (month + 1), 'months')
        .format('MM');

      return stats?.find((stat) => stat?.date && stat.date.includes(date))?.total || 0;
    });
  }, []);

  const data: ChartData<'bar'> = useMemo(() => {
    return {
      labels: labels,
      datasets: [
        {
          label: 'Année dernière',
          data: getData(stats.months_last_year),
          backgroundColor: '#325F96'
        },
        {
          label: 'Année courante',
          data: getData(stats.months),
          backgroundColor: 'orange'
        }
      ]
    };
  }, [labels]);

  return (
    <Row>
      <h2>Statistiques sur les paiements</h2>
      <Col>
        <DailyChart stats={stats.days} label="Don en € du jour" backgroundColor="orange" title="Montant récolté journalier" />
      </Col>
      <Col>
        <Bar options={options} data={data} />
      </Col>
    </Row>
  );
};

export default PaymentChart;
