import React, { useState } from 'react';
import { Formik, type FormikConfig } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { type CreateProductType } from '../../../api/repository/types/product.type';
import { type LocaleType } from '../../../api/repository/types/transverse.type';
import { type GetOneProject } from '../../../api/repository/types/project.type';
import ProjectAutocomplete from '../../../components/autocomplete/project.autocomplete';
import SelectField from '../../../components/form/fields/select.field';
import ProjectRepository from '../../../api/repository/ProjectRepository';

export interface Props {
  product?: CreateProductType;
  onSubmit: FormikConfig<ProductDuplicateValue>['onSubmit'];
  locale: LocaleType;
}

export interface ProductDuplicateValue {
  product: string;
}

const ProductDuplicate: React.FC<Props> = ({ locale, product, onSubmit }) => {
  const [project, setProject] = useState<GetOneProject>();

  const initialValue: ProductDuplicateValue = {
    product: ''
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValue} validateOnMount>
      {(formik) => {
        const { handleChange, handleSubmit, isValid, setFieldValue } = formik;

        return (
          <Form onSubmit={handleSubmit}>
            <h4>A quel projet appartient le produit</h4>
            <ProjectAutocomplete
              onChange={(project) => {
                ProjectRepository.get(project.value).then(({ data }) => {
                  setProject(data);

                  if (data.products) {
                    if (data.products.length > 0) {
                      setFieldValue('product', data.products[0].id);
                    }
                  }
                });
              }}
            />

            <SelectField
              name={'product'}
              options={
                project
                  ? project.products.map((product) => ({
                      label: product.title,
                      value: product.id
                    }))
                  : []
              }
              onChange={handleChange}
            />

            <div className="text-center">
              <Button type="submit" disabled={!isValid}>
                Valider
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProductDuplicate;
