import React, { useMemo } from 'react';
import BootstrapTable, { type BootstrapTableProps } from 'react-bootstrap-table-next';
import './Table.scss';
import { transform } from './services/column/column.transformer';

interface Props<T extends object = any> extends BootstrapTableProps<T> {}

const Table: React.FC<Props> = ({ columns, ...bootstrap }) => {
  const reworkedColumns = useMemo(() => {
    return transform(columns);
  }, [columns]);

  return (
    <div className="table-wrapper">
      <BootstrapTable {...bootstrap} bootstrap4 columns={reworkedColumns} />
    </div>
  );
};

export default Table;
