import React from 'react';
import { Form, Formik, type FormikConfig } from 'formik';
import { Button } from 'react-bootstrap';
import InputField from '../../../components/form/fields/input.field';

export interface MediaData {
  lien_media?: string;
}

export interface MediaModalProps {
  onSubmit: FormikConfig<MediaData>['onSubmit'];
}

const MediaModal: React.FC<MediaModalProps> = ({ onSubmit }) => {
  const data: MediaData = {
    lien_media: ''
  };

  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnMount
      validate={() => {
        return {};
      }}
    >
      {(formikProps) => {
        const { values, isValid } = formikProps;
        const { lien_media } = values;

        return (
          <Form onSubmit={formikProps.handleSubmit}>
            <InputField defaultValue={lien_media} label={'Lien Média'} name="lien_media" onChange={formikProps.handleChange} helper={'Lien media'} />

            <Button type="submit" disabled={!isValid}>
              Valider
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MediaModal;
