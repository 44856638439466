import { BaseRepository } from './BaseRepository';
import { type EditDemandeRecuType, type GetDemandeRecuType } from './types/demande_recu.type';

class DemandeRecuRepository<T = GetDemandeRecuType> extends BaseRepository<T> {
  getArea() {
    return '/api/demandes-recu';
  }

  edit<T = EditDemandeRecuType>(id: string, type: T | any) {
    return super.edit<T>(id, type);
  }
}

export default new DemandeRecuRepository();
