import React from 'react';
import { columns } from './services/columns';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TablePagination from '../../../components/table/TablePagination';
import AdminRepository from '../../../api/repository/AdminRepository';

const AdminList: React.FC = () => {
  return (
    <div className="crud-list">
      <h1>Liste des Administrateurs.</h1>
      <div className="action-wrapper">
        <Link to="/admins/new">
          <Button>Nouveau</Button>
        </Link>
      </div>
      <TablePagination repository={AdminRepository} columns={columns} keyField="id" />
    </div>
  );
};

export default AdminList;
