import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ConfigurationRepository from '../../api/repository/ConfigurationRepository';
import { notify } from '../../services/notification/notification';
import { type GetConfiguration } from '../../api/repository/types/configuration.type';
import TablePagination from '../../components/table/TablePagination';
import { columns } from './services/columns';

const ConfigurationList: React.FC = () => {
  const [selected, setSelected] = useState<GetConfiguration>();
  const [deletedRows, setDeletedRows] = useState<any[]>([]);

  const deleteContact = () => {
    ConfigurationRepository.delete(selected!.id).then(() => {
      deletedRows.push(selected!.id);
      setDeletedRows([...deletedRows]);
      notify({
        title: 'Config supprimé avec succès',
        message: 'Config a été supprimé'
      });
    });
  };

  return (
    <div className="crud-list">
      <h1>Liste des configs.</h1>

      <div className="action-wrapper">
        <Button disabled={selected === undefined} onClick={deleteContact}>
          Supprimer
        </Button>

        <Link to={'/configurations/new'}>
          <Button>Nouveau</Button>
        </Link>
      </div>

      <TablePagination
        repository={ConfigurationRepository}
        columns={columns}
        keyField="id"
        rowClasses={(row) => {
          return deletedRows.includes(row.id) ? 'd-none' : '';
        }}
        selectRow={{
          clickToSelect: true,
          mode: 'radio',
          onSelect: (entity) => {
            setSelected(entity);
          }
        }}
      />
    </div>
  );
};

export default ConfigurationList;
