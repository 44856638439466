import React from 'react';
import { Card } from 'react-bootstrap';
import './product.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { type GetProductType } from '../../../api/repository/types/product.type';
import { type CreateProductProps, ProductProvider } from './services/CreateProduct';
import { type LocaleType } from '../../../api/repository/types/transverse.type';

export interface ProductViewProps {
  product: GetProductType;
  onCreate: CreateProductProps['onSubmit'];
  onDelete?: (product: GetProductType) => void;
  locale: LocaleType;
}

const ProductView: React.FC<ProductViewProps> = ({ locale, product, onCreate, onDelete }) => {
  const image = typeof product?.image === 'string' ? product.image : product?.image?.url;

  return (
    <Card className="product-card my-2">
      <Card.Img variant="top" src={image} />
      <div className={'actions'}>
        <i>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => {
              ProductProvider.create({ product, onSubmit: onCreate, locale });
            }}
          />
        </i>
        <i>
          <FontAwesomeIcon icon={faTimesCircle} onClick={() => onDelete?.(product)} />
        </i>
      </div>
      <Card.Body>
        <Card.Title className="bg-primary c-white" as="div" dangerouslySetInnerHTML={{ __html: product.title }}></Card.Title>
        <Card.Text>
          <strong>Prix:</strong> {product.price} <br />
          <strong>Produit variable:</strong> {product.isVariable ? 'Oui' : 'Non'} <br />
          <strong>Demande de nom de projet:</strong> {product.askName ? 'Oui' : 'Non'} <br />
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProductView;
