import React, { useEffect, useState } from 'react';
import { type FormikConfig } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { pick } from 'underscore';
import { notify } from '../../services/notification/notification';
import CurrencyForm from './form/currency.form';
import { type CreateCurrency, type GetOneCurrency } from '../../api/repository/types/currency.type';
import CurrencyRepository from '../../api/repository/CurrencyRepository';

export interface Props {
  id?: string;
}

const CurrencyEdit: React.FC<Props> = () => {
  const [entity, setEntity] = useState<GetOneCurrency>();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    CurrencyRepository.get(id).then((response) => {
      setEntity(response.data);
    });
  }, [id]);

  const onSubmit: FormikConfig<CreateCurrency>['onSubmit'] = (entity) => {
    const edit = pick(entity, ['key', 'number', 'position', 'state']);

    CurrencyRepository.edit(id, edit).then(() => {
      notify({
        title: 'Modifié',
        message: "L'entrée a été modifié."
      });
    });
  };

  const schema = yup.object({});

  if (!entity) {
    return <>Chargement...</>;
  }

  return <CurrencyForm initialValue={entity} onSubmit={onSubmit} validationSchema={schema} />;
};

export default CurrencyEdit;
