import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import TranslationStep from './translation.step';
import { type GetOneProject } from '../../../../api/repository/types/project.type';
import { locales } from '../../../../services/translations/locales';

interface Props {
  project: GetOneProject;
}

const Translation: React.FC<Props> = ({ project }) => {
  const [currentLocale, setCurrentLocale] = useState('fr');

  const getTranslation = (locale: string) => {
    if (!project.translations) {
      return undefined;
    }

    return project.translations.find((t) => t.locale === locale);
  };

  const getAssets = (locale: string) => {
    if (!project.assets) {
      return [];
    }
    return project.assets.filter((a) => a.locale === locale);
  };

  const getImages = (locale: string) => {
    if (!project.images) {
      return [];
    }
    return project.images.filter((a) => a.locale === locale);
  };

  return (
    <div className="mt-2">
      <Tabs
        activeKey={currentLocale}
        onSelect={(k) => {
          setCurrentLocale(k!);
        }}
      >
        {locales.map((locale) => (
          <Tab key={locale.locale} eventKey={locale.locale} title={locale.title}>
            <TranslationStep project={project} assets={getAssets(locale.locale)} images={getImages(locale.locale)} initialTranslation={getTranslation(locale.locale)} locale={locale.locale} />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default Translation;
