import React from 'react';
import { type ColumnsType } from '../../types/columns.type';
import { type ColumnDescription } from 'react-bootstrap-table-next';
import dayjs from 'dayjs';

export const transform = (columns: Array<ColumnsType<any>>): ColumnDescription[] => {
  let transformedColumns: ColumnDescription[] = [];
  transformedColumns = columns.map((column) => {
    preRenderTransformer(column);

    return column;
  });

  return transformedColumns;
};

const preRenderTransformer = (column: ColumnsType) => {
  if (column.preRender) {
    switch (column.preRender.type) {
      case 'datetime':
        column.formatter = (cell) => <>{cell ? dayjs(cell).format('DD/MM/YYYY [-] HH[h]mm') : ''}</>;
        break;
      case 'date':
        column.formatter = (cell) => <>{cell ? dayjs(cell).format('DD/MM/YYYY') : ''}</>;
        break;
      case 'email':
        column.formatter = (cell) => (
          <>
            {cell ? (
              <a className="cell-email" href={`mailto:${cell}`}>
                {cell}
              </a>
            ) : (
              ''
            )}
          </>
        );
        break;
      case 'tel':
        column.formatter = (cell) => (
          <>
            {cell ? (
              <a className="cell-tel" href={`tel:${cell}`}>
                {cell}
              </a>
            ) : (
              ''
            )}
          </>
        );
        break;
      case 'html':
        column.formatter = (cell) => <div dangerouslySetInnerHTML={{ __html: cell }} />;
        break;
    }
  }
};
