import React, { useRef } from 'react';
import AutocompleteField, { type AutocompleteProps } from '../form/fields/autocomplete.field';
import UserRepository from '../../api/repository/UserRepository';
import axios, { type CancelTokenSource } from 'axios';
import { type FieldTypeDefaultValue } from '../form/fields/types/base.field.type';

interface Props extends Partial<FieldTypeDefaultValue> {
  defaultValue?: any;
  onChange: AutocompleteProps['onChange'];
}

const UserAutocomplete: React.FC<Props> = ({ defaultValue, onChange, ...rest }) => {
  let cancelTokenSource = useRef<CancelTokenSource>();

  return (
    <AutocompleteField
      {...rest}
      name=""
      defaultValue={defaultValue}
      onChange={onChange}
      placeholder={'Rechercher un utilisateur par email'}
      loadOptions={async (input, callback) => {
        if (cancelTokenSource.current !== undefined) {
          cancelTokenSource.current.cancel();
        }
        cancelTokenSource.current = axios.CancelToken.source();
        return await UserRepository.getAll({ email: input }, { cancelToken: cancelTokenSource.current.token }).then((response) => {
          callback(response.data.results.map((user) => ({ value: user.id, label: user.email })));
        });
      }}
    />
  );
};

export default UserAutocomplete;
