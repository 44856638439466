import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { GetStatistics } from '../../../api/repository/types/statitics.type';
import DailyChart from './daily.chart';

interface Props {
  stats: GetStatistics['user'];
}

const UserChart: React.FC<Props> = ({ stats }) => {
  return (
    <Row>
      <h2>Statistiques sur les utilisateurs</h2>
      <Col>
        <DailyChart stats={stats.creation} label="Nombre de nouveau compte journalier" backgroundColor="#325F96" title="Nombre de nouveau compte utilisateur" />
      </Col>
      <Col>
        <DailyChart stats={stats.login} label="Nombre de personne connecté par jour" backgroundColor="#325F96" title="Nombre de personne connecté" />
      </Col>
    </Row>
  );
};

export default UserChart;
