import React, { useEffect, useState } from 'react';
import { type FormikConfig } from 'formik';
import { useParams } from 'react-router-dom';
import { notify } from '../../../services/notification/notification';
import { type CreateDemandeRecuType } from '../../../api/repository/types/demande_recu.type';
import DemandeRecuRepository from '../../../api/repository/DemandeRecuRepository';
import DemandeRecuForm from '../form/demande_recu.form';
import { pick } from 'underscore';
import * as yup from 'yup';

export interface Props {
  id?: string;
}

const DemandeRecuEdit: React.FC<Props> = () => {
  const [demande, setDemande] = useState<CreateDemandeRecuType>();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    DemandeRecuRepository.get(id).then((response) => {
      setDemande(response.data);
    });
  }, [id]);

  const onSubmit: FormikConfig<CreateDemandeRecuType>['onSubmit'] = (demande: CreateDemandeRecuType) => {
    const edit = pick(demande, ['name', 'email', 'fromDate', 'toDate', 'state']);

    DemandeRecuRepository.edit(id, edit).then(() => {
      notify({
        title: 'Edition effectuée.',
        message: 'La demande de reçu a été modifiée'
      });
    });
  };

  const schema = yup.object<Partial<CreateDemandeRecuType>>({
    email: yup.string().email().required()
  });

  if (!demande) {
    return <>Chargement...</>;
  }

  return <DemandeRecuForm initialDemande={demande} onSubmit={onSubmit} validationSchema={schema} />;
};

export default DemandeRecuEdit;
