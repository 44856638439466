import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  error?: any;
  showError?: boolean;
}

const Error: React.FC<Props> = ({ error, showError = false }) => {
  if (!showError) {
    return null;
  }

  return <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>;
};

export default Error;
