import { type Route } from '../components/route/route';
import { type AdminType } from '../api/repository/types/admin.type';

export class Auth {
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('until');
    localStorage.removeItem('user');
  }

  static getToken(): string | null {
    return localStorage.getItem('token');
  }

  static isTokenValid(): boolean {
    const until = localStorage.getItem('until');

    if (!until) {
      return false;
    }

    const now = new Date();
    const untilDate = new Date(Number(until));

    return now < untilDate;
  }

  setToken(token: string) {
    const date = new Date();
    localStorage.setItem('token', token);
    localStorage.setItem('until', '' + Number(date.getTime() + Number(process.env.REACT_APP_TOKEN_TTL) * 1000));
  }

  setUser(user: AdminType) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  static getUser(): AdminType | null {
    const jsonUser = localStorage.getItem('user');

    if (jsonUser) {
      return JSON.parse(jsonUser)! as AdminType;
    }

    return null;
  }

  isLogged(): boolean {
    return Auth.getToken() !== null && Auth.isTokenValid();
  }

  static hasAccess(route: Route): boolean {
    const currentUser = this.getUser();

    if (!currentUser || !Auth.isTokenValid()) {
      return false;
    }

    if (!route.roles) {
      return true;
    }

    if (currentUser.roles.includes('ROLE_ADMIN')) {
      return true;
    }

    return undefined !== route.roles.find((role) => currentUser.roles.includes(role));
  }
}
