import React, { useEffect, useMemo, useState } from 'react';
import BootstrapTable, { type BootstrapTableProps } from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import './Table.scss';
import { transform } from './services/column/column.transformer';
import * as XLSX from 'xlsx';
import { Badge, Button } from 'react-bootstrap';
import DonationRepository from '../../api/repository/DonationRepository';
import { toggleLoading } from '../../state-management/global-store';

// @ts-expect-error
interface Props<T extends object = any> extends BootstrapTableProps<T> {
  data?: any[];
  getAllQuery?: any;
  defaultSort?: { sortField?: string; sortOrder?: 'asc' | 'desc' };
  itemPerPage?: number;
}

const DonationsTablePagination: React.FC<Props> = ({ columns, getAllQuery, defaultSort = {}, ...bootstrap }) => {
  const reworkedColumns = useMemo(() => {
    return transform(columns);
  }, [columns]);

  const [data, setData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [, setHasNextPage] = useState<boolean>(false);
  const [itemPerPage, setItemPerPage] = useState<number>(bootstrap.itemPerPage ?? 40);
  const [nbItem, setNbItem] = useState<number>(0);
  const [sort, setSort] = useState<{ sortField?: string; sortOrder?: 'asc' | 'desc' }>(defaultSort);
  const [total, setTotal] = useState<number>(0);
  const [donationTotal, setDonationTotal] = useState<number>(0);
  const [exportNonNullNames, setExportNonNullNames] = useState(false);

  const pagination = paginationFactory({
    page: currentPage,
    sizePerPage: itemPerPage,
    showTotal: true,
    totalSize: nbItem,
    hideSizePerPage: true,
    paginationTotalRenderer: (from, to, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total">
          {from} ligne à {to} ligne / total de {size} résultats
        </span>
      );
    },
    custom: true
  });

  useEffect(() => {
    toggleLoading();
    DonationRepository.getAll({ ...getAllQuery, ...sort, page: currentPage, itemPerPage }).then(({ data }) => {
      setHasNextPage(data.hasNextPage);
      setData([...data.results]);
      setItemPerPage(data.itemPerPage);
      setNbItem(data.nbItem);
      setTotal(data.results.reduce((sum, item) => sum + item.total, 0));
    });

    DonationRepository.getTotal({ ...getAllQuery, ...sort }).then(({ data }) => {
      setDonationTotal(data.total);
      toggleLoading();
    });
  }, [currentPage, getAllQuery, sort]);

  const exportToExcel = async () => {
    try {
      const response = await DonationRepository.getAll({
        ...getAllQuery,
        ...sort,
        page: 1,
        itemPerPage: nbItem
      });

      const data = response.data.results;
      const excelData = data
        .filter((item) => !exportNonNullNames || (item.data && item.data.name !== null))
        .map((item) => ({
          Nom: item.payment?.user?.firstName || '',
          Prenom: item.payment?.user?.lastName || '',
          Email: item.payment?.user?.email || '',
          Montant: item.total,
          Autres_Data: item.data?.name || '',
          Destination: item.project?.name || ''
        }));

      const ws = XLSX.utils.json_to_sheet(excelData);

      excelData.forEach((row, rowIndex) => {
        Object.keys(row).forEach((key, colIndex) => {
          ws[XLSX.utils.encode_cell({ r: rowIndex + 1, c: colIndex })].t = 's';
        });
      });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      const excelBuffer = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array'
      });

      const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.xlsx';
      a.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };

  return (
    <div className="table-wrapper">
      <PaginationProvider pagination={pagination}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <div>
              <Badge pill bg="primary">
                Total des dons: {donationTotal} €
              </Badge>

              <div className="d-flex justify-content-end">
                <Button onClick={exportToExcel}>Exporter en Excel</Button>
              </div>

              <div style={{ textAlign: 'right' }}>
                <input
                  id="AllDonations"
                  type="checkbox"
                  checked={exportNonNullNames}
                  onChange={(e) => {
                    setExportNonNullNames(e.target.checked);
                  }}
                  style={{ transform: 'scale(1.5)', marginRight: '25px' }}
                />

                <label htmlFor="AllDonations" style={{ transform: 'scale(1.2)', marginRight: '5px' }}>
                  Exporter les dons nominatifs
                </label>
              </div>

              <PaginationListStandalone {...paginationProps} />
              <BootstrapTable
                {...bootstrap}
                bootstrap4
                {...paginationTableProps}
                columns={reworkedColumns}
                data={data}
                remote={{ sort: true, pagination: true }}
                onTableChange={(_event, tableChange) => {
                  setCurrentPage(tableChange.page);
                  if (tableChange.sortField) {
                    setSort({
                      sortField: tableChange.sortField,
                      sortOrder: tableChange.sortOrder
                    });
                  }
                }}
              />
              <div className="d-flex justify-content-end my-3">
                <Badge bg="primary">Total: {total} €</Badge>
              </div>
              <PaginationListStandalone {...paginationProps} />
            </div>
          );
        }}
      </PaginationProvider>
    </div>
  );
};

export default DonationsTablePagination;
