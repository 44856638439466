import React from 'react';
import { Form } from 'react-bootstrap';
import { type BaseFieldType } from '../types/base.field.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface Props {
  text?: BaseFieldType['helper'];
}

const Helper: React.FC<Props> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <Form.Text muted className="helper-wrapper">
      <i>
        <FontAwesomeIcon icon={faInfoCircle} />
      </i>
      {text}
    </Form.Text>
  );
};

export default Helper;
