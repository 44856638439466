import React, { useState } from 'react';
import { Formik, type FormikConfig } from 'formik';
import { Container, Form } from 'react-bootstrap';
import { type OptionItem } from '../../../components/form/fields/types/option.type';
import { type CreateCurrency, type GetOneCurrency } from '../../../api/repository/types/currency.type';
import FormBuilder from '../../../services/form/form.builder';

export interface Props {
  initialValue?: GetOneCurrency;
  onSubmit: FormikConfig<CreateCurrency>['onSubmit'];
  validationSchema?: any;
  disabled?: boolean;
}

const CurrencyForm: React.FC<Props> = ({ disabled = true, validationSchema, initialValue, onSubmit }) => {
  const currency: CreateCurrency = {
    code: '',
    label: {
      fr: '',
      en: ''
    },
    ratio: 1,
    position: 1,
    state: 'published',
    ...initialValue
  };

  const states: OptionItem[] = [
    {
      label: 'Publié',
      value: 'published'
    },
    {
      label: 'Non publié',
      value: 'unpublished'
    }
  ];

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  const buider = new FormBuilder<CreateCurrency>();
  buider.setDisable(disabled);
  buider.add('code', 'text').add('label', 'translation').add('position', 'number').add('ratio', 'number').add('state', 'select', {
    items: states
  });

  return (
    <Formik initialValues={currency} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange={submittedOnce}>
      {(formik) => {
        const result = buider.renderView(formik);

        return (
          <Form
            onSubmit={(e) => {
              setSubmittedOnce(true);
              formik.handleSubmit();
              e.preventDefault();
            }}
            noValidate
          >
            <Container className="px-2">{result}</Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CurrencyForm;
