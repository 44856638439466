import { type AdminListType } from '../../types/admin.list.type';
import { type ColumnsType } from '../../../../components/table/types/columns.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimesCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';

export const columns: Array<ColumnsType<AdminListType>> = [
  {
    dataField: 'email',
    text: 'Email',
    preRender: {
      type: 'email'
    },
    sort: true
  },
  {
    dataField: 'firstName',
    text: 'Prénom Nom',
    formatter: (_cell, user) => <>{user.firstName + ' ' + user.lastName}</>
  },
  {
    dataField: 'isActive',
    text: 'Compte actif ?',
    formatter: (isActive: boolean) => {
      if (isActive) {
        return (
          <i>
            <FontAwesomeIcon icon={faCheck} />
          </i>
        );
      } else {
        return (
          <i>
            <FontAwesomeIcon icon={faTimesCircle} />
          </i>
        );
      }
    },
    style: { textAlign: 'center', width: 140 }
  },
  {
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    formatter: (_cell, user) => {
      return (
        <Link to={`/admins/${user.id}/edit`}>
          <FontAwesomeIcon icon={faEdit} />
        </Link>
      );
    },
    style: { textAlign: 'center', width: 100 }
  }
];
