import { BaseRepository } from './BaseRepository';
import { type CreateContactType, type GetContactType } from './types/contact.type';

class ContactRepository<T = GetContactType> extends BaseRepository<T> {
  getArea() {
    return '/api/contacts';
  }

  edit<T = CreateContactType>(id: string, type: T | any) {
    return super.edit<T>(id, type);
  }

  editState<T>(id: string, state: CreateContactType['state']) {
    const url = this.getBaseEndpoint() + '/' + id + '/state';

    return this.client.put<T>(url, { state });
  }
}

export default new ContactRepository();
