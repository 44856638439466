import { BaseRepository } from './BaseRepository';
import { type GetOnePayment, type GetPayments } from './types/payment.type';

class PaymentRepository<T = GetPayments> extends BaseRepository<T> {
  getArea() {
    return '/api/payments';
  }

  get<T = GetOnePayment>(id: string) {
    return super.get<T>(id);
  }

  sendReceipt(id: string) {
    const url = this.getBaseEndpoint() + `/${id}/send-receipt`;

    return this.client.post(url, {});
  }
}

export default new PaymentRepository();
