import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { DateMetrics, Metric } from '../../../api/repository/types/statitics.type';
import dayjs from 'dayjs';
import { ChartData } from 'chart.js';
import { generateOption } from './service/generate-option';

interface Props {
  stats: DateMetrics[];
  title: string;
  label?: string;
  backgroundColor?: string;
  /** generate dataset if not default one with basic data. **/
  generateDataset?: (basicDataDaily: (Metric | undefined)[]) => ChartData<'bar'>['datasets'];
}

const DailyChart: React.FC<Props> = ({ stats, label = '', backgroundColor = 'orange', title, generateDataset }) => {
  const options = generateOption(title);

  const dayLabels = useMemo(() => {
    return [...Array(7)].map((_, day) => {
      return dayjs()
        .subtract(7 - (day + 1), 'days')
        .format('DD/MM');
    });
  }, []);

  const getData = useMemo(() => {
    return [...Array(7)].map((_, day) => {
      const date = dayjs()
        .subtract(7 - (day + 1), 'days')
        .format('YYYY-MM-DD');

      return stats.find((stat) => stat.date === date)?.total || 0;
    });
  }, []);

  const basicDataDaily = useMemo(() => {
    return [...Array(7)].map((_, day) => {
      const date = dayjs()
        .subtract(7 - (day + 1), 'days')
        .format('YYYY-MM-DD');

      return stats.find((stat) => stat.date === date);
    });
  }, []);

  const data: ChartData<'bar'> = useMemo(() => {
    return {
      labels: dayLabels,
      datasets: generateDataset
        ? generateDataset(basicDataDaily)
        : [
            {
              label: label,
              data: getData,
              backgroundColor: backgroundColor
            }
          ]
    };
  }, [dayLabels]);

  return <Bar options={options} data={data} />;
};

export default DailyChart;
