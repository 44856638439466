import React, { useState } from 'react';
import { type ColumnsType } from '../../../components/table/types/columns.type';
import { type Donation } from '../../../api/repository/types/payment.type';
import { Link } from 'react-router-dom';
import ToggleShowWrapper, { IconEdit } from '../../../components/wrapper/toggle-show.wrapper';
import MetadataPaymentModal from '../modal/metadata.modal';
import MetadataCell from './metadata.cell';

export const columns: Array<ColumnsType<Donation>> = [
  {
    dataField: 'payment',
    text: 'Payment id & status',
    isDummyField: true,
    formatter: (_cell, row) => {
      if (!row.payment) {
        return <></>;
      }

      return (
        <>
          <Link to={`/payments/${row.payment.id}/view`}>{row.payment.id.substr(0, 5)}</Link>
          <br />
          {row.payment.status}
        </>
      );
    },
    style: { width: 150 }
  },
  {
    dataField: 'status',
    text: 'Status'
  },
  {
    dataField: 'total',
    text: 'Total',
    formatter: (total) => <>{total} €</>
  },
  {
    dataField: 'project',
    text: 'Projet',
    formatter: (project) => {
      return <>{project ? <Link to={`/projects/${project.id}/edit/informations`}>{project.name}</Link> : 'Dons globaux'}</>;
    }
  },
  {
    dataField: 'data',
    text: 'Autre data',
    isDummyField: true,
    formatter: (_, donation) => <MetadataCell donation={donation} />
  },
  {
    dataField: 'createdAt',
    text: 'Créé le',
    preRender: {
      type: 'datetime'
    },
    sort: true,
    classes: 'datetime'
  }
];
