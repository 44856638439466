import React, { useEffect, useMemo, useState } from 'react';
import BootstrapTable, { type BootstrapTableProps } from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import './Table.scss';
import { transform } from './services/column/column.transformer';
import { type BaseRepository } from '../../api/repository/BaseRepository';

// @ts-expect-error
interface Props<T extends object = any> extends BootstrapTableProps<T> {
  repository: BaseRepository<any>;
  data?: any[];
  getAllQuery?: any;
  defaultSort?: { sortField?: string; sortOrder?: 'asc' | 'desc' };
  itemPerPage?: number;
  columns: any;
}

const TablePagination: React.FC<Props> = ({ columns, repository, getAllQuery, defaultSort = {}, ...bootstrap }) => {
  const reworkedColumns = useMemo(() => {
    return transform(columns);
  }, [columns]);

  const [data, setData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [itemPerPage, setItemPerPage] = useState<number>(bootstrap.itemPerPage ?? 40);
  const [nbItem, setNbItem] = useState<number>(0);
  const [sort, setSort] = useState<{ sortField?: string; sortOrder?: 'asc' | 'desc' }>(defaultSort);

  const pagination = paginationFactory({
    page: currentPage,
    sizePerPage: itemPerPage,
    showTotal: true,
    totalSize: nbItem,
    hideSizePerPage: true,
    paginationTotalRenderer: (from, to, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total">
          {from} ligne à {to} ligne / total de {size} résultats
        </span>
      );
    },
    custom: true
  });

  useEffect(() => {
    repository.getAll({ ...getAllQuery, ...sort, page: currentPage, itemPerPage }).then(({ data }) => {
      setHasNextPage(data.hasNextPage);
      setData([...data.results]);
      setItemPerPage(data.itemPerPage);
      setNbItem(data.nbItem);
    });
  }, [currentPage, repository, getAllQuery, sort]);

  return (
    <div className="table-wrapper table-responsive">
      <PaginationProvider pagination={pagination}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <div>
              <PaginationListStandalone {...paginationProps} />
              <BootstrapTable
                {...bootstrap}
                {...paginationTableProps}
                columns={reworkedColumns}
                data={data}
                remote={{ sort: true, pagination: true }}
                onTableChange={(_event, tableChange) => {
                  setCurrentPage(tableChange.page);
                  if (tableChange.sortField) {
                    setSort({
                      sortField: tableChange.sortField,
                      sortOrder: tableChange.sortOrder
                    });
                  }
                }}
              />
              <PaginationListStandalone {...paginationProps} />
            </div>
          );
        }}
      </PaginationProvider>
    </div>
  );
};

export default TablePagination;
