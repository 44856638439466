import React from 'react';
import { Form, Formik, type FormikConfig } from 'formik';
import { Button } from 'react-bootstrap';
import ProjectAutocomplete from '../../../components/autocomplete/project.autocomplete';
import * as yup from 'yup';

export interface TransfertProjectData {
  sourceId: string;
  targetId: string;
}

export interface TransfertProjectModalProps {
  onSubmit: FormikConfig<TransfertProjectData>['onSubmit'];
}

const TransfertProjectModal: React.FC<TransfertProjectModalProps> = ({ onSubmit }) => {
  const data: TransfertProjectData = {
    sourceId: '',
    targetId: ''
  };

  const schema = yup.object<Partial<TransfertProjectData>>({
    sourceId: yup.string().required(),
    targetId: yup.string().required()
  });

  return (
    <Formik initialValues={data} onSubmit={onSubmit} validateOnChange={true} validateOnMount validationSchema={schema}>
      {(formikProps) => {
        const { setFieldValue, values, isValid } = formikProps;
        const { sourceId, targetId } = values;
        return (
          <Form onSubmit={formikProps.handleSubmit}>
            <h4>Source: </h4>
            <ProjectAutocomplete
              defaultValue={sourceId}
              onChange={(project) => {
                setFieldValue('sourceId', project.value);
              }}
            />

            <h4>Destination: </h4>
            <ProjectAutocomplete
              defaultValue={targetId}
              onChange={(project) => {
                setFieldValue('targetId', project.value);
              }}
            />
            <Button type="submit" disabled={!isValid}>
              Transférer
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TransfertProjectModal;
