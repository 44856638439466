import React from 'react';
import { Form, Formik, type FormikConfig } from 'formik';
import UserAutocomplete from '../../../components/autocomplete/user.autocomplete';
import { Button } from 'react-bootstrap';
import SelectField from '../../../components/form/fields/select.field';
import ProjectAutocomplete from '../../../components/autocomplete/project.autocomplete';

export interface TransfertData {
  userId?: string;
  projectId?: string;
  type: string;
}

export interface TransfertPaymentModalProps {
  onSubmit: FormikConfig<TransfertData>['onSubmit'];
}

const TransfertPaymentModal: React.FC<TransfertPaymentModalProps> = ({ onSubmit }) => {
  const data: TransfertData = {
    userId: undefined,
    projectId: '',
    type: 'project'
  };

  const transerableOptions = [
    {
      value: 'user',
      label: 'Utilisateur'
    },
    {
      value: 'project',
      label: 'Projet'
    }
  ];

  return (
    <Formik
      initialValues={data}
      onSubmit={onSubmit}
      validateOnChange={true}
      validateOnMount
      validate={(values) => {
        if (values.type === 'project' && !values.projectId) {
          return {
            projectId: 'Erreur'
          };
        } else if (values.type === 'user' && !values.userId) {
          return {
            userId: 'Erreur'
          };
        }

        return {};
      }}
    >
      {(formikProps) => {
        const { setFieldValue, values, isValid } = formikProps;
        const { type, projectId, userId } = values;

        return (
          <Form onSubmit={formikProps.handleSubmit}>
            <SelectField defaultValue={values.type} label={'Transférer un projet ou un utilisateur ?'} options={transerableOptions} name="type" onChange={formikProps.handleChange} helper={"Switch sur 'on' si projet"} />
            <h5>{type === 'project' ? 'Vers projet:' : 'Vers utilisateur:'}</h5>
            {type === 'user' && (
              <UserAutocomplete
                defaultValue={userId}
                onChange={(user) => {
                  setFieldValue('userId', user.value);
                }}
              />
            )}

            {type === 'project' && (
              <ProjectAutocomplete
                defaultValue={projectId}
                onChange={(project) => {
                  setFieldValue('projectId', project.value);
                }}
              />
            )}

            <Button type="submit" disabled={!isValid}>
              Transférer
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TransfertPaymentModal;
