import React from 'react';
import { type BaseFieldType, type HandleChange } from './types/base.field.type';
import InputField from './input.field';

type Props = BaseFieldType & {
  type?: string;
  onChange: HandleChange;
};

const locales = ['fr', 'en'];

const TranslatableField: React.FC<Props> = React.memo(
  ({ formGroupProps, label, ...inputProps }) => {
    const { name, onChange, placeholder, ...restFieldProps } = inputProps;

    return (
      <>
        {locales.map((locale) => {
          return (
            <InputField
              key={locale}
              {...restFieldProps}
              value={restFieldProps.value[locale]}
              label={label ? `${label} (${locale} trad.)` : undefined}
              name={`${name}[${locale}]`}
              onChange={onChange}
              placeholder={placeholder ? `${placeholder} (${locale} trad.)` : undefined}
            />
          );
        })}
      </>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.errors?.[prevProps.name] === nextProps.errors?.[nextProps.name] && prevProps.value === nextProps.value;
  }
);

export default TranslatableField;
