import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-with-alignment';
import { type FieldTypeDefaultValue, type OnChange } from './types/base.field.type';
import Helper from './transverse/helper';
import Label from './transverse/label';
import { Alert, Form } from 'react-bootstrap';

export type RichTextProps = FieldTypeDefaultValue & {
  config?: any;
  onChange: OnChange;
  onBlur?: OnChange;
};

const RichTextField: React.FC<RichTextProps> = ({ formGroupProps, onBlur, helper, label, defaultValue = '', name, config, onChange, errors, showError }) => {
  const handleChange = (event: any, editor: any) => {
    onChange(name, editor.getData());
  };

  const handleBlur = (event: any, editor: any) => {
    onBlur?.(name, editor.getData());
  };

  return (
    <Form.Group className="mb-3" {...formGroupProps}>
      <Label label={label} />
      <CKEditor
        editor={ClassicEditor}
        data={defaultValue}
        config={{
          // ['heading', '|','alignment', '|','bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|','link', '|','bulletedList', 'numberedList', 'todoList','-','fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|','code', 'codeBlock', '|','insertTable', '|','outdent', 'indent', '|','imageUpload', 'blockQuote', '|','undo', 'redo']
          toolbar: ['heading', 'alignment', '|', 'bold', 'link', 'bulletedList', 'numberedList'],
          ...config
        }}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {showError && errors?.[name] ? <Alert variant={'danger'}>{`${errors[name]}`}</Alert> : null}

      <Helper text={helper} />
    </Form.Group>
  );
};

export default RichTextField;
