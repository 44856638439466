import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop, faPause } from '@fortawesome/free-solid-svg-icons';
import { GetListProject } from '../../../api/repository/types/project.type';
import { notify } from '../../../services/notification/notification';
import ProjectRepository from '../../../api/repository/ProjectRepository';
import './project-unpublish.modal.scss';

interface Props {
  project: GetListProject;
}

const ProjectUnpublishModal: React.FC<Props> = ({ project }) => {
  const [show, setShow] = useState<boolean>(false);
  const [action, setAction] = useState<string>('unpublish');
  const handleClose = () => {
    setShow(false);
  };

  const onUnpublishClick = () => {
    setShow(true);
    setAction('unpublish');
  };

  const onInvisibleClick = () => {
    setShow(true);
    setAction('invisible');
  };

  const handleConfirm = () => {
    if ('unpublish' === action) {
      ProjectRepository.unpublish(project.id).then(() => {
        notify({
          title: 'Mise à jour effectuée',
          message: `Le projet ${project.name} a été dépublié`,
          onRemoval: () => setShow(false)
        });
      });
    } else if ('invisible' === action) {
      ProjectRepository.invisible(project.id).then(() => {
        notify({
          title: 'Mise à jour effectuée',
          message: `Le projet ${project.name} a été rendu invisible`,
          onRemoval: () => setShow(false)
        });
      });
    }
  };

  const handleCancel = () => {
    setShow(false);
  };

  return (
    <>
      <span className="icon-wrapper">
        <i className="invisible-icon">
          <FontAwesomeIcon onClick={onInvisibleClick} icon={faPause} />
        </i>
        <i className="unpublish-icon">
          <FontAwesomeIcon onClick={onUnpublishClick} icon={faStop} />
        </i>
      </span>

      <Modal size="lg" centered={true} show={show} onHide={handleClose} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{action === 'unpublish' ? 'Dépublier (non accessible)' : 'Rendre invisible (accessible via url)'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{action === 'unpublish' ? 'Voulez vous dépublier ce projet? (non accessible)' : 'Voulez vous enlever le projects des pages de collectes et actions ? (accessible via url directement)'}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel} active>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleConfirm}>
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProjectUnpublishModal;
