import React from 'react';
import { type FormikConfig } from 'formik';
import { type UserCreateType } from '../types/user.create.type';
import * as yup from 'yup';
import UserForm from '../user-form/user.form';
import UserRepository from '../../../api/repository/UserRepository';
import { type UserGet } from '../../../api/repository/types/user.type';
import { notify } from '../../../services/notification/notification';

export interface Props {
  onCreate?: (user: UserGet) => void;
}

const UserCreate: React.FC<Props> = ({ onCreate }) => {
  const onSubmit: FormikConfig<UserCreateType>['onSubmit'] = (values) => {
    UserRepository.new(values)
      .then((response) => {
        notify({
          title: 'Un nouvel utilisateur a été créé.',
          message: `${values.firstName} vient d'être créé`
        });
        onCreate?.(response.data);
      })
      .catch((reason) => {
        alert('Erreur lors de la création');
      });
  };

  const schema = yup.object<Partial<UserCreateType>>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    enterprise: yup.string().notRequired(),
    password: yup.string().required()
  });

  return <UserForm onSubmit={onSubmit} validationSchema={schema} />;
};

export default UserCreate;
