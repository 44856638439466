import { Auth } from '../services/Auth';
import React from 'react';
import { type Route } from '../components/route/route';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({ children, route }: { children: JSX.Element; route: Route }) => {
  const location = useLocation();

  if (!Auth.hasAccess(route)) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;
