import React from 'react';
import { redirect } from 'react-router-dom';
import ProjectRepository from '../../api/repository/ProjectRepository';
import ProjectBaseForm, { type ProjectBaseFormProps } from './form/project.form';

const ProjectCreate: React.FC = () => {
  const onSubmit: ProjectBaseFormProps['onSubmit'] = (values) => {
    ProjectRepository.new(values).then((response) => {
      const project = response.data as any;
      redirect(`/projects/${project.id}/edit/informations`);
    });
  };

  return <ProjectBaseForm onSubmit={onSubmit} />;
};

export default ProjectCreate;
