class StringUtil {
  static random(length: number = 10): string {
    let text = '';

    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < length; i++) {
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    return text;
  }

  static createOptions(list: string[]) {
    return list.map((type) => ({
      label: type,
      value: type
    }));
  }

  static createCustomOptions(list: any[]) {
    return list.map((type) => ({
      value: type.value,
      label: type.label
    }));
  }

  static removeHTMLTags(string: string) {
    return string.replace(/<[^>]*>?/gm, '');
  }
}

export default StringUtil;
