import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CurrencyForm from './form/currency.form';
import { type GetOneCurrency } from '../../api/repository/types/currency.type';
import CurrencyRepository from '../../api/repository/CurrencyRepository';

export interface Props {
  currencyId?: string;
}

const CurrencyView: React.FC<Props> = ({ currencyId }) => {
  const [entity, setEntity] = useState<GetOneCurrency>();
  let { id } = useParams() as { id: string };
  id = currencyId || id;

  useEffect(() => {
    CurrencyRepository.get(id).then((response) => {
      setEntity(response.data);
    });
  }, [id]);

  if (!entity) {
    return <>Chargement...</>;
  }

  return <CurrencyForm initialValue={entity} onSubmit={() => {}} disabled />;
};

export default CurrencyView;
