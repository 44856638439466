import React, { useState } from 'react';
import { Formik, type FormikConfig } from 'formik';
import InputField from '../../../components/form/fields/input.field';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import StringUtil from '../../../services/string/StringUtil';
import SelectField from '../../../components/form/fields/select.field';
import { type OptionItem } from '../../../components/form/fields/types/option.type';
import BooleanField from '../../../components/form/fields/boolean.field';
import { type UserGet } from '../../../api/repository/types/user.type';
import { type UserCreateType } from '../types/user.create.type';

export interface Props {
  initialUser?: UserGet;
  onSubmit: FormikConfig<UserCreateType>['onSubmit'];
  removeFields?: {
    password: boolean;
  };
  validationSchema?: any;
  disabled?: boolean;
}

const UserForm: React.FC<Props> = ({ disabled, initialUser, onSubmit, removeFields, validationSchema }) => {
  const user: UserCreateType = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    enterprise: '',
    phone: '',
    address: {
      address: '',
      addressComplement: '',
      country: '',
      zip: '',
      city: ''
    },
    isActive: true,
    language: 'fr',
    ...initialUser
  };

  const languages: OptionItem[] = [
    {
      label: 'Français',
      value: 'fr'
    },
    {
      label: 'Anglais',
      value: 'en'
    }
  ];

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  return (
    <Formik initialValues={user} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange={submittedOnce}>
      {(formik) => {
        const { values, setFieldValue } = formik;
        const { firstName, lastName, email, enterprise, phone, address, isActive, password, language } = values;
        const baseProps = {
          onChange: formik.handleChange,
          errors: formik.errors,
          showError: true,
          disabled
        };

        return (
          <Form
            onSubmit={(e) => {
              setSubmittedOnce(true);
              formik.handleSubmit();
              e.preventDefault();
            }}
            noValidate
          >
            <Container fluid>
              <InputField placeholder={'Entreprise'} name="enterprise" value={enterprise} {...baseProps} />
              <Row>
                <Col>
                  <InputField placeholder={'Nom*'} name="lastName" value={lastName} {...baseProps} />
                </Col>
                <Col>
                  <InputField placeholder={'Prénom*'} name="firstName" value={firstName} {...baseProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField placeholder={'Email*'} type="email" name="email" value={email} {...baseProps} />
                </Col>
                <Col>
                  <InputField placeholder={'Téléphone'} type="tel" name="phone" value={phone} {...baseProps} />
                </Col>
              </Row>

              <Row>
                {removeFields?.password !== true && (
                  <>
                    <Col>
                      <InputField placeholder={'Mot de passe*'} type="password" name="password" value={password} {...baseProps} />
                    </Col>
                    <Col sm={3}>
                      <Button
                        variant="light"
                        onClick={() => {
                          setFieldValue('password', StringUtil.random(10));
                        }}
                      >
                        <FontAwesomeIcon icon={faKey} /> Générer aléatoirement
                      </Button>
                    </Col>
                  </>
                )}
              </Row>

              <Row>
                <Col>
                  <InputField placeholder={'Pays'} name="address[country]" value={address.country} {...baseProps} />
                </Col>
                <Col>
                  <SelectField {...baseProps} placeholder="Language" name="language" value={language} options={languages} helper="Langue de l'utilisateur" />
                </Col>
              </Row>

              <Row>
                <Col>
                  <InputField placeholder={'Ville'} name="address[city]" value={address.city} {...baseProps} />
                </Col>
                <Col>
                  <InputField placeholder={'Code postal'} name="address[zip]" value={address.zip} {...baseProps} />
                </Col>
              </Row>

              <InputField placeholder={'Adresse'} name="address[address]" value={address.address} {...baseProps} />
              <InputField placeholder={"complément d'adresse"} name="address[addressComplement]" value={address.addressComplement} {...baseProps} />

              <BooleanField type="switch" label="Compte actif" name={'isActive'} value={isActive} helper="Un utilisateur ne peut pas se connecter sur un compte inactif." {...baseProps} />

              {disabled !== true && (
                <div className="text-center">
                  <Button variant="primary" type="submit" size="lg">
                    Valider
                  </Button>
                </div>
              )}
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserForm;
