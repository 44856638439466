import UserCreate from '../../pages/users/user-create/user.create';
import { faMailBulk, faChartBar, faDonate, faUsers, faSignOutAlt, faUserShield, faProjectDiagram, faAddressCard, faQuestionCircle, faBoxTissue, faListOl, faCartPlus, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import UserList from '../../pages/users/user-list/user.list';
import Home from '../../pages/home/Home';
import Logout from '../../pages/logout/Logout';
import AdminList from '../../pages/admin/admin-list/admin.list';
import AdminCreate from '../../pages/admin/admin-create/admin.create';
import AdminEdit from '../../pages/admin/admin-edit/admin.edit';
import UserEdit from '../../pages/users/user-edit/user.edit';
import ContactList from '../../pages/contact/list/contact.list';
import DemandesRecuList from '../../pages/demandes-recu/list/demandes_recu.list';
import ContactEdit from '../../pages/contact/edit/contact.edit';
import DemandeRecuEdit from '../../pages/demandes-recu/edit/demande_recu.edit';
import FaqList from '../../pages/faq/list/faq.list';
import FaqEdit from '../../pages/faq/edit/faq.edit';
import FaqCreate from '../../pages/faq/create/faq.create';
import RequestProjectEdit from '../../pages/request-project/edit/request-project.edit';
import RequestProjectList from '../../pages/request-project/list/request-project.list';
import KeyNumberList from '../../pages/key-number/key-number.list';
import KeyNumberCreate from '../../pages/key-number/key-number.create';
import KeyNumberEdit from '../../pages/key-number/key-number.edit';
import CurrencyCreate from '../../pages/currency/currency.create';
import PaymentList from '../../pages/payments/payment.list';
import PaymentView from '../../pages/payments/payment.view';
import PaymentCreate from '../../pages/payments/payment.create';
import CurrencyEdit from '../../pages/currency/currency.edit';
import CurrencyList from '../../pages/currency/currency.list';
import ConfigurationCreate from '../../pages/configuration/configuration.create';
import ConfigurationEdit from '../../pages/configuration/configuration.edit';
import ConfigurationList from '../../pages/configuration/configuration.list';
import ProjectCreate from '../../pages/projects/project.create';
import ProjectEdit from '../../pages/projects/project.edit';
import ProjectList from '../../pages/projects/project.list';
import DonationList from '../../pages/donations/donations.list';
import UserDonationList from '../../pages/userdonations/userdonations.list';
import NewsletterList from '../../pages/newsletter/newsletter.list';

export interface Route {
  title: string;
  route: string;
  page?: any;
  roles?: string[];
}

export interface NavigationItem extends Route {
  icon: IconProp;
}

const projectRoutes: Route[] = [
  {
    title: 'Creation de project',
    route: '/projects/new',
    page: ProjectCreate,
    roles: ['ROLE_ADMIN']
  },
  {
    title: "Edition d'Admin",
    route: '/projects/:id/edit/:type',
    page: ProjectEdit,
    roles: ['ROLE_ADMIN']
  }
];

const requestProjectRoutes: Route[] = [
  {
    title: 'Edition de demande',
    route: '/request-projects/:id/edit',
    page: RequestProjectEdit,
    roles: ['ROLE_ADMIN']
  }
];
const adminRoutes: Route[] = [
  {
    title: "Creation d'Admin",
    route: '/admins/new',
    page: AdminCreate,
    roles: ['ROLE_ADMIN']
  },
  {
    title: "Edition d'Admin",
    route: '/admins/:id/edit',
    page: AdminEdit,
    roles: ['ROLE_ADMIN']
  }
];

const userRoutes: Route[] = [
  {
    title: "Creation d'utilisateur",
    route: '/users/new',
    page: UserCreate,
    roles: ['ROLE_USERS']
  },
  {
    title: "Edition d'utilisateur",
    route: '/users/:id/edit',
    page: UserEdit,
    roles: ['ROLE_USERS']
  }
];

const contactRoutes: Route[] = [
  {
    title: 'Edition contact',
    route: '/contacts/:id/edit',
    page: ContactEdit,
    roles: ['ROLE_ADMIN']
  }
];
const demandesRecuRoutes: Route[] = [
  {
    title: 'Edition demande reçu',
    route: '/demandes-recu/:id/edit',
    page: DemandeRecuEdit,
    roles: ['ROLE_ADMIN']
  }
];
const faqRoutes: Route[] = [
  {
    title: 'Creation de la FAQ',
    route: '/faqs/new',
    page: FaqCreate,
    roles: ['ROLE_ADMIN']
  },
  {
    title: 'Edition de la FAQ',
    route: '/faqs/:id/edit',
    page: FaqEdit,
    roles: ['ROLE_ADMIN']
  }
];

const keyNumberRoutes: Route[] = [
  {
    title: 'Creation chiffres clés',
    route: '/key-numbers/new',
    page: KeyNumberCreate,
    roles: ['ROLE_ADMIN']
  },
  {
    title: 'Edition chiffres clés',
    route: '/key-numbers/:id/edit',
    page: KeyNumberEdit,
    roles: ['ROLE_ADMIN']
  }
];

const newslettersRoutes: Route[] = [
  {
    title: 'Newsletter',
    route: '/newsletters',
    page: NewsletterList,
    roles: ['ROLE_ADMIN']
  }
];

export const currencyRoutes = [
  {
    title: 'Currency',
    route: '/currencies/new',
    page: CurrencyCreate,
    roles: ['ROLE_ADMIN']
  },
  {
    title: 'Currency',
    route: '/currencies/:id/edit',
    page: CurrencyEdit
  },
  {
    title: 'Currency',
    route: '/currencies',
    page: CurrencyList
  }
];

export const configurationRoutes = [
  {
    title: 'Configuration',
    route: '/configurations/new',
    page: ConfigurationCreate,
    roles: ['ROLE_ADMIN']
  },
  {
    title: 'Configuration',
    route: '/configurations/:id/edit',
    page: ConfigurationEdit
  },
  {
    title: 'Configuration',
    route: '/configurations',
    page: ConfigurationList
  }
];

export const paymentRoutes: Route[] = [
  {
    title: 'Paiements',
    route: '/payments/:id/view',
    page: PaymentView
  },
  {
    title: 'Nouveau paiement',
    route: '/payments/new',
    page: PaymentCreate
  }
];

export const donationsRoutes: Route[] = [
  {
    title: 'Donations',
    route: '/donations',
    page: DonationList
  }
];
export const userDonationsRoutes: Route[] = [
  {
    title: 'UserDonations',
    route: '/userDonations/:id',
    page: UserDonationList
  }
];
export const routes: Route[] = [
  ...donationsRoutes,
  ...userDonationsRoutes,
  ...paymentRoutes,
  ...configurationRoutes,
  ...projectRoutes,
  ...userRoutes,
  ...adminRoutes,
  ...contactRoutes,
  ...demandesRecuRoutes,
  ...faqRoutes,
  ...requestProjectRoutes,
  ...keyNumberRoutes,
  ...currencyRoutes,
  ...newslettersRoutes,
  ...[
    {
      title: 'Statistics',
      route: '/',
      page: Home
    }
  ]
];

export const menu: NavigationItem[] = [
  {
    title: 'Statistics',
    icon: faChartBar,
    route: '/',
    page: Home
  },
  {
    title: 'Utilisateurs',
    icon: faUsers,
    route: '/users',
    page: UserList,
    roles: ['ROLE_USERS']
  },
  {
    title: 'Paiements',
    icon: faDonate,
    route: '/payments',
    page: PaymentList
  },
  {
    title: 'Dons',
    icon: faCartPlus,
    route: '/donations',
    page: DonationList
  },
  {
    title: 'Projets',
    icon: faProjectDiagram,
    route: '/projects',
    page: ProjectList
  },
  {
    title: 'Demande de projets',
    icon: faBoxTissue,
    route: '/request-projects',
    page: RequestProjectList
  },
  {
    title: 'Contact',
    icon: faAddressCard,
    route: '/contacts',
    page: ContactList
  },
  {
    title: 'Faq',
    icon: faQuestionCircle,
    route: '/faqs',
    page: FaqList
  },
  {
    title: 'Chiffre clés',
    icon: faListOl,
    route: '/key-numbers',
    page: KeyNumberList
  },
  {
    title: 'Newsletter',
    icon: faMailBulk,
    route: '/newsletters',
    page: NewsletterList
  },
  {
    title: 'Admins',
    icon: faUserShield,
    route: '/admins',
    page: AdminList
  },
  {
    title: 'Déconnexion',
    icon: faSignOutAlt,
    route: '/logout',
    page: Logout
  }
];
