import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { type ColumnsType } from '../../../components/table/types/columns.type';
import { type Donation, type GetPayments } from '../../../api/repository/types/payment.type';

export const paymentStatusMapping = (status: string): string => {
  switch (status) {
    case 'validated':
      return 'validé';
    case 'active':
      return 'actif';
    default:
      return status;
  }
};

export const gatewayMapping = (gateway: string): string => {
  switch (gateway) {
    case 'paypal':
      return 'Paypal';
    case 'stripe':
      return 'Stripe';
    case 'no':
      return 'Aucun/Manuel';
    default:
      return gateway;
  }
};

export const columns: Array<ColumnsType<GetPayments>> = [
  {
    dataField: 'id',
    text: 'Id',
    formatter: (id) => <>{id.substring(0, 5) + '..'}</>
  },
  {
    dataField: 'user.email',
    text: 'Email',
    formatter: (_value, row) => {
      return (
        <Link to={`/users/${row.user.id}/edit`} target="_blank">
          {row.user.email}
        </Link>
      );
    }
  },
  {
    dataField: 'total',
    text: 'Total',
    formatter: (total) => <>{total} €</>
  },
  {
    dataField: 'gateway',
    text: 'Plateforme',
    formatter: (gateway) => <>{gatewayMapping(gateway)}</>
  },
  {
    dataField: 'status',
    text: 'Etat',
    formatter: paymentStatusMapping
  },
  {
    dataField: 'type',
    text: 'Type de paiement'
  },
  {
    dataField: 'createdAt',
    text: 'Créé le',
    preRender: {
      type: 'datetime'
    }
  },
  {
    dataField: 'updatedAt',
    text: 'Date dernière modif.',
    preRender: {
      type: 'datetime'
    }
  },
  {
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    formatter: (_cell, entity) => {
      return (
        <Link to={`/payments/${entity.id}/view`} target="_blank">
          <FontAwesomeIcon icon={faEdit} />
        </Link>
      );
    }
  }
];
