import { BaseRepository } from './BaseRepository';
import { type Donation, type GetOnePayment } from './types/payment.type';
import { type CreateManualPayment } from '../../pages/payments/form/payment.form';
import { type TransfertProjectData } from '../../pages/projects/modal/transfert.modal';

class DonationRepository<T = Donation> extends BaseRepository<T> {
  getArea() {
    return '/api/donations';
  }

  get<T = Donation>(id: string) {
    return super.get<T>(id);
  }

  transfertToUser(donationId: string, userId: string) {
    const url = this.baseUrl + this.getArea() + `/${donationId}/user/${userId}`;

    return this.client.post<Donation>(url, {});
  }

  transfertToProject(donationId: string, projectId: string) {
    const url = this.baseUrl + this.getArea() + `/${donationId}/project/${projectId}`;

    return this.client.post<Donation>(url, {});
  }

  transfertDonationsFrom(transfert: TransfertProjectData) {
    const { sourceId, targetId } = transfert;
    const url = this.baseUrl + this.getArea() + `/from-project/${sourceId}/to-project/${targetId}`;

    return this.client.post<Donation>(url, {});
  }

  createManual(manual: CreateManualPayment) {
    const url = this.baseUrl + this.getArea() + '/manual';

    return this.client.post<GetOnePayment>(url, manual);
  }

  sendProjectMediaNotification(id: string) {
    const url = this.getBaseEndpoint() + `/${id}/send-project-media-notification`;

    return this.client.post(url, {});
  }

  getTotal(filter: any) {
    const url = this.getBaseEndpoint() + `/total`;

    return this.client.get<{ total: number }>(url, filter);
  }

  patchMetadata(id: string, metadata: any) {
    const url = this.getBaseEndpoint() + `/${id}/patch/metadata`;

    return this.client.put<Donation>(url, {
      data: metadata
    });
  }
}

export default new DonationRepository();
