import React, { useState } from 'react';
import { columns } from './services/columns';
import TablePagination from '../../../components/table/TablePagination';
import ContactRepository from '../../../api/repository/ContactRepository';
import { type GetContactType } from '../../../api/repository/types/contact.type';
import { Button } from 'react-bootstrap';
import { notify } from '../../../services/notification/notification';

const ContactList: React.FC = () => {
  const [selected, setSelected] = useState<GetContactType[]>([]);
  const [deletedRows, setDeletedRows] = useState<any[]>([]);

  const deleteContact = () => {
    selected.map(async (row) => {
      await ContactRepository.delete(row.id).then(() => {
        deletedRows.push(row.id);
        setDeletedRows([...deletedRows]);
      });

      notify({
        title: 'Contact supprimé avec succès',
        message: 'La prise de contact a bien été supprimée'
      });
    });
  };

  return (
    <div className="crud-list">
      <h1>Liste des prises de contacts.</h1>

      <div className="action-wrapper">
        <Button disabled={selected.length === 0} onClick={deleteContact}>
          Supprimer
        </Button>
      </div>

      <TablePagination
        repository={ContactRepository}
        columns={columns}
        keyField="id"
        rowClasses={(row) => {
          return deletedRows.includes(row.id) ? 'd-none' : '';
        }}
        selectRow={{
          clickToSelect: true,
          mode: 'checkbox',
          hideSelectAll: true,
          onSelect: (contact, isSelected) => {
            if (isSelected) {
              setSelected([...selected, contact]);
            } else {
              setSelected([...selected.filter((s) => s.id !== contact.id)]);
            }
          }
        }}
      />
    </div>
  );
};

export default ContactList;
