import React, { useEffect, useMemo, useState } from 'react';
import { columns as columnDonations } from './services/payment.donations.columns';
import PaymentRepository from '../../api/repository/PaymentRepository';
import { useParams } from 'react-router-dom';
import { type GetOnePayment } from '../../api/repository/types/payment.type';
import Table from '../../components/table/Table';
import UserView from '../users/user.view';
import { Accordion, Button, Card, Container } from 'react-bootstrap';
import CurrencyView from '../currency/currency.view';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransfertModalProvider } from './modal/Transfert';
import { notify } from '../../services/notification/notification';
import { confirm } from 'react-bootstrap-confirmation';
import PaymentStatusForm from './form/payment-status.form';
import { transfertDonation } from '../donations/services/manager';

const PaymentView: React.FC = () => {
  const [entity, setEntity] = useState<GetOnePayment>();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    PaymentRepository.get(id).then((response) => {
      setEntity(response.data);
    });
  }, [id]);

  const columns = useMemo(() => {
    const col = [...columnDonations];
    col.push({
      isDummyField: true,
      text: 'Actions',
      dataField: 'actions',
      formatter: (v, donation) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            TransfertModalProvider.create({
              onSubmit: (data) => {
                transfertDonation(donation.id, data);
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faShareSquare} />
        </a>
      )
    });

    return col;
  }, []);

  if (!entity) {
    return null;
  }

  const sendReceipt = async () => {
    const result = await confirm('Voulez vous vraiment envoyé une notification ? ');
    if (result) {
      PaymentRepository.sendReceipt(id).then(() => {
        notify({
          title: 'Notification par email effetuée',
          message: 'Un reçu a été envoyé au payeur'
        });
      });
    }
  };

  const deletePayment = async () => {
    const result = await confirm('Voulez vous vraiment supprimer ce paiement (action irreversible!) ? ');
    if (result) {
      PaymentRepository.delete(id).then(() => {
        notify({
          title: 'Payement supprimé',
          message: 'Vous allez être redirigé'
        });

        setTimeout(() => {
          window.location.replace('/payments');
        }, 2000);
      });
    }
  };

  return (
    <div className="crud-list">
      <Container fluid>
        <h1>
          Payment #{entity.id} de {entity.user.firstName}.
        </h1>

        <div className="d-flex my-3 justify-content-end">
          <div className="w-25 d-flex justify-content-between">
            <Button onClick={deletePayment}>Supprimer</Button>
            <Button onClick={sendReceipt}>Renvoyer le reçu</Button>
          </div>
        </div>

        <h2 className="my-2">Liste des dons: </h2>
        <Table keyField={'id'} data={entity.donations ? entity.donations : []} columns={columns} />

        <hr />

        <h2>Infos complémentaires</h2>
        <Accordion defaultActiveKey="paiement">
          <Card key={'paiement'}>
            <Accordion.Item eventKey={'paiement'} as={Card.Header}>
              <Accordion.Header>Information sur le paiement</Accordion.Header>
              <Accordion.Body className="p-3">
                <PaymentStatusForm
                  onSubmit={(values) => {
                    PaymentRepository.edit(id, values).then(() => {
                      notify({
                        title: 'Paiement mis à jour',
                        message: 'Le statut a été mis à jour'
                      });
                    });
                  }}
                  payment={entity}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Card>
          <Card key="user">
            <Accordion.Item eventKey={'user'} as={Card.Header}>
              <Accordion.Header>Utilisateur</Accordion.Header>
              <Accordion.Body className="p-3">
                <UserView userId={entity.user.id} />
              </Accordion.Body>
            </Accordion.Item>
          </Card>
          <Card key="devise">
            <Accordion.Item eventKey="devise" as={Card.Header}>
              <Accordion.Header>Devise</Accordion.Header>
            </Accordion.Item>
            <Accordion.Body className="p-3">
              <CurrencyView currencyId={entity.currency.id} />
            </Accordion.Body>
          </Card>
        </Accordion>
      </Container>
    </div>
  );
};

export default PaymentView;
