import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { notify } from '../../services/notification/notification';
import TablePagination from '../../components/table/TablePagination';
import { columns } from './services/columns';
import { type GetCurrency } from '../../api/repository/types/currency.type';
import CurrencyRepository from '../../api/repository/CurrencyRepository';

const CurrencyList: React.FC = () => {
  const [selected, setSelected] = useState<GetCurrency>();
  const [deletedRows, setDeletedRows] = useState<any[]>([]);

  const deleteOne = () => {
    CurrencyRepository.delete(selected!.code).then(() => {
      deletedRows.push(selected!.code);
      setDeletedRows([...deletedRows]);
      notify({
        title: 'Supprimé avec succès',
        message: 'La devise a été supprimée'
      });
    });
  };

  return (
    <div className="crud-list">
      <h1>Liste des devises.</h1>

      <div className="action-wrapper">
        <Button disabled={selected === undefined} onClick={deleteOne}>
          Supprimer
        </Button>

        <Link to={'/currencies/new'}>
          <Button>Nouveau</Button>
        </Link>
      </div>

      <TablePagination
        repository={CurrencyRepository}
        columns={columns}
        keyField="code"
        rowClasses={(row) => {
          return deletedRows.includes(row.code) ? 'd-none' : '';
        }}
        selectRow={{
          clickToSelect: true,
          mode: 'radio',
          onSelect: (entity) => {
            setSelected(entity);
          }
        }}
      />
    </div>
  );
};

export default CurrencyList;
