import React, { useMemo, useState, useEffect } from 'react';
import TablePagination from '../../components/table/TablePagination';
import { columns as columnDonations } from './services/columns';
import { type DonationDetails, SHOW_PAYMENT_STATUS } from '../../api/repository/types/payment.type';
import DonationRepository from '../../api/repository/DonationRepository';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import UserDonationFilter from './form/userdonation.filter';
import { useParams } from 'react-router-dom';
import { MediaModalProvider } from './modal/Media';
import { notify } from '../../services/notification/notification';
import { confirm } from 'react-bootstrap-confirmation';

interface Filter {
  paymentStatus?: string[];
  user?: string;
  project?: string;
}

const UserDonationList: React.FC = () => {
  const { id } = useParams() as { id: string };
  const [donation, setDonation] = useState<any>();
  const [donationId, setDonationId] = useState<any>();

  const [filter] = useState<Filter>({
    paymentStatus: SHOW_PAYMENT_STATUS,
    user: id
  });

  const [donationFilter, setDonationFilter] = useState<any>({});

  const query: Filter = { ...filter, ...donationFilter };

  useEffect(() => {
    DonationRepository.get(donationId).then((response) => {
      setDonation(response.data);
    });
  }, [donationId]);

  const NotifyMedia = async (donationId: any) => {
    const result = await confirm('Voulez vous vraiment envoyé une notification ? ');
    if (result) {
      DonationRepository.sendProjectMediaNotification(donationId).then(() => {
        notify({
          title: 'Notification par email effetuée',
          message: 'Un email a été envoyé au donteur'
        });
      });
    }
  };

  const columns = useMemo(() => {
    const col = [...columnDonations];
    col.push({
      isDummyField: true,
      text: 'Actions',
      dataField: 'actions',
      formatter: (v, donation: DonationDetails) => (
        <div>
          <a
            href="#"
            onClick={(e) => {
              setDonationId(donation.id);
              e.preventDefault();
              MediaModalProvider.create({
                onSubmit: async (data) =>
                  DonationRepository.edit(donation.id, data).then(() => {
                    notify({
                      title: 'Mise à jour effectuée',
                      message: 'Le lien média a été mis à jour'
                    });
                  })
              });
            }}
          >
            <FontAwesomeIcon icon={faEdit} /> Lien Média
          </a>
          {'         '}
          <a
            href="#"
            onClick={(e) => {
              console.log('donationId==>' + donation.id);
              setDonationId(donation.id);
              e.preventDefault();
              NotifyMedia(donation.id);
            }}
          >
            <FontAwesomeIcon icon={faEnvelope} /> Notifier
          </a>
        </div>
      )
    });

    return col;
  }, []);

  return (
    <div className="crud-list">
      <h1>Historique des dons.</h1>

      <UserDonationFilter
        onSubmit={(donationFilter) => {
          setDonationFilter({
            ...donationFilter
          });
        }}
      />

      <TablePagination repository={DonationRepository} getAllQuery={query} columns={columns} keyField="id" itemPerPage={20} />
    </div>
  );
};

export default UserDonationList;
