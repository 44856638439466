import React from 'react';
import { type FormikConfig } from 'formik';
import * as yup from 'yup';
import { notify } from '../../../services/notification/notification';
import FaqRepository from '../../../api/repository/FaqRepository';
import { type CreateFaqType } from '../../../api/repository/types/faq.type';
import FaqForm from '../form/faq.form';

export interface Props {
  id?: string;
}

const FaqCreate: React.FC<Props> = () => {
  const onSubmit: FormikConfig<CreateFaqType>['onSubmit'] = (faq: CreateFaqType) => {
    FaqRepository.new(faq).then(() => {
      notify({
        title: 'Création effectuée.',
        message: 'La faq a été créé'
      });
    });
  };

  const schema = yup.object<Partial<CreateFaqType>>({
    title: yup.string().required(),
    description: yup.string().required()
  });

  return <FaqForm onSubmit={onSubmit} validationSchema={schema} />;
};

export default FaqCreate;
