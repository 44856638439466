import React, { useState } from 'react';
import { Formik, type FormikConfig } from 'formik';
import InputField from '../../../components/form/fields/input.field';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { type OptionItem } from '../../../components/form/fields/types/option.type';
import SelectField from '../../../components/form/fields/select.field';
import { type CreateKeyNumber, type GetOneKeyNumber } from '../../../api/repository/types/key-number.type';
import TranslatableField from '../../../components/form/fields/translatable.field';

export interface Props {
  initialValue?: GetOneKeyNumber;
  onSubmit: FormikConfig<CreateKeyNumber>['onSubmit'];
  validationSchema?: any;
}

export const keyNumberStates: OptionItem[] = [
  {
    label: 'Publié',
    value: 'published'
  },
  {
    label: 'Non publié',
    value: 'unpublished'
  }
];

const KeyNumberForm: React.FC<Props> = ({ validationSchema, initialValue, onSubmit }) => {
  const keyNumber: CreateKeyNumber = {
    key: {
      fr: '',
      en: ''
    },
    number: undefined,
    position: 1,
    state: 'published',
    ...initialValue
  };

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  return (
    <Formik initialValues={keyNumber} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange={submittedOnce}>
      {(formik) => {
        const { values, handleChange, errors } = formik;
        const { state, position, key, number } = values;

        const baseProps = {
          onChange: handleChange,
          errors: errors,
          showError: true
        };

        return (
          <Form
            onSubmit={(e) => {
              setSubmittedOnce(true);
              formik.handleSubmit();
              e.preventDefault();
            }}
            noValidate
          >
            <Container fluid>
              <Row>
                <Col>
                  <TranslatableField placeholder={'Nom*'} name="key" value={key} {...baseProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField type="number" placeholder={'Chiffre*'} name="number" value={number} {...baseProps} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <InputField type="number" label="Position" name={'position'} value={position} {...baseProps} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <SelectField label={'Etat*'} name="state" options={keyNumberStates} defaultValue={state} {...baseProps} helper={"Seul l'état publié sera visible pour l'utilistaeur"} />
                </Col>
              </Row>

              <div className="text-center">
                <Button variant="primary" type="submit" size="lg">
                  Valider
                </Button>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default KeyNumberForm;
