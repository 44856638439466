import React from 'react';
import Header from '../header/Header';
import Navigation from '../navigation/Navigation';
import { menuState } from '../../../state-management/global-store';
import './Base.scss';

interface Props {
  children: any;
}

const BaseLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className={`app-wrapper ${menuState.value.opened ? 'menu-open' : ''}`}>
      <Header />
      <div className="content-wrapper">
        <Navigation />
        <main className="main-content">{React.createElement(children)}</main>
      </div>
    </div>
  );
};

export default BaseLayout;
