import { BaseRepository } from './BaseRepository';
import { type GetOneConfiguration } from './types/configuration.type';

class ConfigurationRepository<T = GetOneConfiguration> extends BaseRepository<T> {
  getArea() {
    return '/api/configurations';
  }
}

export default new ConfigurationRepository();
