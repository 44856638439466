import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { type GetKeyNumber } from '../../../api/repository/types/key-number.type';
import { type ColumnsType } from '../../../components/table/types/columns.type';
import { toggleLoading } from '../../../state-management/global-store';
import SelectTable from '../../../components/form/table/select.table';
import KeyNumberRepository from '../../../api/repository/KeyNumberRepository';
import { keyNumberStates } from '../form/key-number.form';

export const columns: Array<ColumnsType<GetKeyNumber>> = [
  {
    dataField: 'key',
    text: 'Clé',
    sort: true
  },
  {
    dataField: 'number',
    text: 'Nombre',
    sort: true
  },
  {
    dataField: 'position',
    text: 'Position',
    sort: true
  },
  {
    dataField: 'state',
    text: 'Etat',
    sort: true,
    formatter: (_cell, entity) => {
      const onChange = (e) => {
        entity.state = e.target.value;
        toggleLoading();
        KeyNumberRepository.editState(entity.id, entity.state).then(() => {
          toggleLoading();
        });
      };

      return <SelectTable onChange={onChange} defaultValue={entity.state} options={keyNumberStates}></SelectTable>;
    }
  },
  {
    dataField: 'createdAt',
    text: 'Créé le',
    preRender: {
      type: 'date'
    },
    sort: true
  },
  {
    dataField: 'updatedAt',
    text: 'Date dernière modif.',
    preRender: {
      type: 'date'
    },
    sort: true
  },
  {
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    formatter: (_cell, entity) => {
      return (
        <Link to={`/key-numbers/${entity.id}/edit`}>
          <FontAwesomeIcon icon={faEdit} />
        </Link>
      );
    }
  }
];
