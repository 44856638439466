import { BaseRepository } from './BaseRepository';
import { type GetRequestProjectType } from './types/request-project.type';

class RequestProjectRepository<T = GetRequestProjectType> extends BaseRepository<T> {
  getArea() {
    return '/api/request-projects';
  }

  edit<T = any>(id: string, type: T | any) {
    return super.edit<T>(id, type);
  }

  attachProjectAndDone(requestId: string, projectId: string) {
    const url = this.getBaseEndpoint() + `/${requestId}/project`;

    return this.client.post(url, {
      project: projectId
    });
  }
}

export default new RequestProjectRepository();
