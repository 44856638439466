import { BaseRepository } from './BaseRepository';
import { BasicEmailType } from '../../components/email/basicMail';

export class DefaultRepository<T = any> extends BaseRepository<T> {
  getArea() {
    return '/api';
  }

  sendEmail(email: BasicEmailType) {
    const url = this.getBaseEndpoint() + '/mail/send';

    return this.client.post<T>(url, email);
  }
}

export default new DefaultRepository();
