import React from 'react';
import ReactDOM from 'react-dom';
import TransfertPaymentModal, { type TransfertPaymentModalProps } from './transfert.modal';
import Modal from '../../../components/modal/modal';

export interface TransfertModalProps {
  onSubmit: TransfertPaymentModalProps['onSubmit'];
}

export const TransfertModalProvider = {
  create: (props: TransfertModalProps) => {
    const container = document.createElement('transfert');
    document.body.append(container);
    const options = {
      onClose: () => {
        container.remove();
        ReactDOM.unmountComponentAtNode(container);
      }
    };

    ReactDOM.render(
      <Modal title="Transférer un don" {...options}>
        <TransfertPaymentModal
          onSubmit={(transfert, formik) => {
            props.onSubmit?.(transfert, formik);
            options.onClose();
          }}
        />
      </Modal>,
      container
    );
  }
};
