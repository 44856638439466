import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Auth } from '../../services/Auth';

const Logout: React.FC = () => {
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    new Auth().logout();
    setRedirect(true);
  }, []);

  return redirect ? <Navigate to={'/login'} /> : <>Chargement...</>;
};

export default Logout;
