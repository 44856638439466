import React, { useState } from 'react';
import { Formik, type FormikConfig } from 'formik';
import { type AdminCreateType } from '../types/admin.create.type';
import InputField from '../../../components/form/fields/input.field';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { type OptionItem } from '../../../components/form/fields/types/option.type';
import BooleanField from '../../../components/form/fields/boolean.field';
import MultipleSelectField from '../../../components/form/fields/multiple-select.field';
import { type AdminType } from '../../../api/repository/types/admin.type';
import { generateOptionsItemSelected } from '../../../components/form/fields/services/select.service';

export interface Props {
  initialUser?: AdminType;
  onSubmit: FormikConfig<AdminCreateType>['onSubmit'];
  removeFields?: {
    password: boolean;
  };
  validationSchema?: any;
}

const AdminForm: React.FC<Props> = ({ validationSchema, initialUser, onSubmit, removeFields }) => {
  const user: AdminCreateType = {
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    isActive: true,
    ...initialUser,
    password: ''
  };

  const rolesAvailable: OptionItem[] = [
    {
      label: 'Admin',
      value: 'ROLE_ADMIN'
    },
    {
      label: 'Utilisateurs',
      value: 'ROLE_USERS'
    },
    {
      label: 'Dons',
      value: 'ROLE_DONS'
    }
  ];

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  return (
    <Formik initialValues={user} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange={submittedOnce}>
      {(formik) => {
        const { values, setFieldValue } = formik;
        const { firstName, lastName, email, password, isActive, roles } = values;

        const baseProps = {
          onChange: formik.handleChange,
          errors: formik.errors,
          showError: true
        };

        const multipleProps = {
          ...baseProps,
          onChange: (values: OptionItem[] | null) => {
            if (values) {
              if (values.find((value) => value.value === 'ROLE_ADMIN')) {
                setFieldValue('roles', rolesAvailable);
              }

              setFieldValue(
                'roles',
                values.map((val) => val.value)
              );
            } else {
              setFieldValue('roles', []);
            }
          }
        };

        return (
          <Form
            onSubmit={(e) => {
              setSubmittedOnce(true);
              formik.handleSubmit();
              e.preventDefault();
            }}
            noValidate
          >
            <Container fluid>
              <Row>
                <Col>
                  <InputField placeholder={'Nom*'} name="lastName" value={lastName} {...baseProps} />
                </Col>
                <Col>
                  <InputField placeholder={'Prénom*'} name="firstName" value={firstName} {...baseProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField placeholder={'Email*'} type="email" name="email" value={email} {...baseProps} />
                </Col>
              </Row>

              <Row>
                <Col>{!removeFields?.password && <InputField placeholder={'Mot de passe'} type="password" name="password" value={password} {...baseProps} showError />}</Col>
              </Row>

              <MultipleSelectField placeholder={'Liste des rôles'} options={rolesAvailable} name={'roles'} value={generateOptionsItemSelected(roles, rolesAvailable)} {...multipleProps} />

              <BooleanField type="switch" label="Compte actif" name={'isActive'} value={isActive} helper="Un admin ne peut pas se connecter sur un compte inactif." {...baseProps} />

              <div className="text-center">
                <Button variant="primary" type="submit" size="lg">
                  Valider
                </Button>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AdminForm;
