import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link } from 'react-router-dom';

interface Props {
  isActive: boolean;
  icon: IconProp;
  title: string;
  route: string;
  onClick: () => void;
}

const NavigationItem: React.FC<Props> = React.memo(
  ({ route, isActive, icon, title, onClick }) => {
    return (
      <Link
        to={route}
        onClick={() => {
          onClick();
        }}
      >
        <li className={`${isActive ? 'active' : ''}`}>
          <i>
            <FontAwesomeIcon icon={icon} />
          </i>
          <span>{title}</span>
        </li>
      </Link>
    );
  },
  (prev, next) => prev.isActive === next.isActive
);

export default NavigationItem;
