import React, { useEffect, useState } from 'react';
import UserForm from './user-form/user.form';
import UserRepository from '../../api/repository/UserRepository';
import { type UserGet } from '../../api/repository/types/user.type';
import { useParams } from 'react-router-dom';

export interface Props {
  userId?: string;
}

const UserView: React.FC<Props> = ({ userId }) => {
  const [user, setUser] = useState<UserGet>();
  let { id } = useParams() as { id: string };
  id = userId || id;

  useEffect(() => {
    UserRepository.get(id).then((response) => {
      setUser(response.data);
    });
  }, [id]);

  if (!user) {
    return <>Chargement...</>;
  }

  return <UserForm onSubmit={() => {}} initialUser={user} removeFields={{ password: true }} disabled={true} />;
};

export default UserView;
