import React from 'react';
import { Form } from 'react-bootstrap';
import { type FieldTypeDefaultValue } from './types/base.field.type';
import Label from './transverse/label';
import Error from './transverse/error';
import { type OptionItem } from './types/option.type';
import Helper from './transverse/helper';
import { type ActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';

type Props = FieldTypeDefaultValue & {
  options?: OptionItem[];
  onChange: (value: any, action: ActionMeta<any>) => void;
  isMulti?: boolean;
  isValidNewOption?: any; // CreatableProps['isValidNewOption'];
};

const TagsField: React.FC<Props> = ({ formGroupProps, label, ...inputProps }) => {
  const { options, name, showError, onChange, defaultValue, errors, helper, isMulti = true, isValidNewOption, placeholder } = inputProps;

  return (
    <Form.Group className="mb-3" {...formGroupProps}>
      <Label label={label} />
      <CreatableSelect
        placeholder={placeholder}
        isMulti={isMulti}
        isValidNewOption={isValidNewOption}
        defaultValue={defaultValue}
        onChange={onChange}
        options={options}
        formatCreateLabel={() => 'Ajouter (appuyer sur entré)'}
        noOptionsMessage={() => 'Ajouter (appuyer sur entré)'}
      />
      <Error error={errors?.[name]} showError={showError} />
      <Helper text={helper} />
    </Form.Group>
  );
};

export default TagsField;
