import { type ColumnsType } from '../../../../components/table/types/columns.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { type GetContactType } from '../../../../api/repository/types/contact.type';
import SelectTable from '../../../../components/form/table/select.table';
import { statesContact } from '../../form/contact.form';
import ContactRepository from '../../../../api/repository/ContactRepository';
import { toggleLoading } from '../../../../state-management/global-store';

export const columns: Array<ColumnsType<GetContactType>> = [
  {
    dataField: 'email',
    text: 'Email',
    preRender: {
      type: 'email'
    },
    sort: true
  },
  {
    dataField: 'name',
    text: 'Nom / Prenom'
  },
  {
    dataField: 'state',
    text: 'Etat',
    formatter: (_cell, contact) => {
      const onChange = (e) => {
        contact.state = e.target.value;
        toggleLoading();
        ContactRepository.editState(contact.id, contact.state).then(() => {
          toggleLoading();
        });
      };

      return <SelectTable onChange={onChange} defaultValue={contact.state} options={statesContact}></SelectTable>;
    }
  },
  {
    dataField: 'createdAt',
    text: 'Créé le',
    preRender: {
      type: 'datetime'
    }
  },
  {
    dataField: 'updatedAt',
    text: 'Date dernière modif.',
    preRender: {
      type: 'datetime'
    }
  },
  {
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    formatter: (_cell, contact) => {
      return (
        <Link to={`/contacts/${contact.id}/edit`}>
          <FontAwesomeIcon icon={faEdit} />
        </Link>
      );
    }
  }
];
