import React from 'react';
import ReactDOM from 'react-dom';
import { type FormikConfig } from 'formik';
import Modal from '../../../../components/modal/modal';
import { type CreateProductType, type GetProductType } from '../../../../api/repository/types/product.type';
import { type LocaleType } from '../../../../api/repository/types/transverse.type';
import ProductCreate from '../product.create';
import ProductDuplicate, { type ProductDuplicateValue } from '../product.duplicate';

export interface CreateProductProps {
  product?: GetProductType;
  onSubmit?: FormikConfig<CreateProductType>['onSubmit'];
  locale: LocaleType;
}

export interface DuplicateProductProps {
  onSubmit?: FormikConfig<ProductDuplicateValue>['onSubmit'];
  locale: LocaleType;
}

export const ProductProvider = {
  create: (props: CreateProductProps) => {
    const { product, onSubmit } = props;
    const container = document.createElement('AddPopup');
    document.body.prepend(container);
    const options = {
      onClose: () => {
        container.remove();
        ReactDOM.unmountComponentAtNode(container);
      }
    };

    ReactDOM.render(
      <Modal modalProps={{ size: 'lg' }} title={props.product ? 'Editer un produit' : 'Créer un produit'} {...options}>
        <ProductCreate
          locale={props.locale}
          product={product}
          onSubmit={(product, formik) => {
            onSubmit?.(product, formik);
            options.onClose();
          }}
        />
      </Modal>,
      container
    );
  },
  duplicate: (props: DuplicateProductProps) => {
    const { onSubmit } = props;
    const container = document.createElement('DuplicatePopup');
    document.body.prepend(container);
    const options = {
      onClose: () => {
        container.remove();
        ReactDOM.unmountComponentAtNode(container);
      }
    };

    ReactDOM.render(
      <Modal modalProps={{ size: 'lg' }} title={'Dupliquer un produit pour ce projet'} {...options}>
        <ProductDuplicate
          locale={props.locale}
          onSubmit={(product, formik) => {
            onSubmit?.(product, formik);
          }}
        />
      </Modal>,
      container
    );
  }
};
