import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { DateWithStateMetrics, GetStatistics, Metric } from '../../../api/repository/types/statitics.type';
import DailyChart from './daily.chart';

interface Props {
  stats: GetStatistics['contact'];
}

const RequestProjectChart: React.FC<Props> = ({ stats }) => {
  const generateData = (basicData: (Metric | undefined)[]) => {
    return [
      {
        label: 'Nb. demande total',
        data: basicData.map((data) => data?.total || 0),
        backgroundColor: 'orange'
      }
    ];
  };

  return (
    <Row>
      <h2>Statistiques sur les demande de création de collectes</h2>
      <Col xs={{ span: 6, offset: 3 }}>
        <DailyChart generateDataset={generateData} stats={stats.creation} title="Nombre de demande de nouvelle collecte" />
      </Col>
    </Row>
  );
};

export default RequestProjectChart;
