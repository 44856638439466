import React, { useEffect, useState } from 'react';
import { type FormikConfig } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { pick } from 'underscore';
import { type CreateKeyNumber, type GetOneKeyNumber } from '../../api/repository/types/key-number.type';
import KeyNumberRepository from '../../api/repository/KeyNumberRepository';
import { notify } from '../../services/notification/notification';
import KeyNumberForm from './form/key-number.form';

export interface Props {
  id?: string;
}

const KeyNumberEdit: React.FC<Props> = () => {
  const [entity, setEntity] = useState<GetOneKeyNumber>();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    KeyNumberRepository.get(id).then((response) => {
      setEntity(response.data);
    });
  }, [id]);

  const onSubmit: FormikConfig<CreateKeyNumber>['onSubmit'] = (entity: CreateKeyNumber) => {
    const edit = pick(entity, ['key', 'number', 'position', 'state']);

    KeyNumberRepository.edit(id, edit).then(() => {
      notify({
        title: 'Modifié',
        message: "L'entrée a été modifié."
      });
    });
  };

  const schema = yup.object<Partial<CreateKeyNumber>>({
    number: yup.number().required(),
    key: yup.object().shape({
      fr: yup.string().required(),
      en: yup.string().required()
    })
  });

  if (!entity) {
    return <>Chargement...</>;
  }

  return <KeyNumberForm initialValue={entity} onSubmit={onSubmit} validationSchema={schema} />;
};

export default KeyNumberEdit;
