import React, { useEffect, useState } from 'react';
import { Formik, type FormikConfig } from 'formik';
import InputField from '../../../components/form/fields/input.field';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { type OptionItem } from '../../../components/form/fields/types/option.type';
import SelectField from '../../../components/form/fields/select.field';
import { type CreateRequestProjectType } from '../../../api/repository/types/request-project.type';
import StringUtil from '../../../services/string/StringUtil';
import ProductRepository from '../../../api/repository/ProductRepository';

export interface Props {
  initialValue?: CreateRequestProjectType;
  onSubmit: FormikConfig<CreateRequestProjectType>['onSubmit'];
  validationSchema?: any;
}

const RequestProjectForm: React.FC<Props> = ({ validationSchema, initialValue, onSubmit }) => {
  const requestProject: CreateRequestProjectType = {
    name: '',
    description: '',
    destination: '',
    product: '',
    nbPerson: '',
    type: '',
    state: 'new',
    ...initialValue
  };

  // les types de projets
  const types: any[] = StringUtil.createOptions(['FAIRE UN PUITS', 'CONSTRUIRE UNE ÉCOLE', 'CONSTRUIRE UNE MOSQUÉE']);

  // les projets fils du projet "Faire un puit"
  const destinations_puits: OptionItem[] = StringUtil.createOptions([
    'PUITS AU CAMBODGE',
    'PUITS AU GHANA',
    'PUITS AU TOGO',
    'PUITS AU BURKINA FASO',
    'PUITS À MADAGASCAR',
    'PUITS AU BÉNIN',
    "PUITS EN CÔTE D'IVOIRE",
    'PUITS AU NÉPAL',
    'PUITS EN INDONÉSIE',
    'PUITS SENEGAL'
  ]);

  // les projets fils du projet "Construire une école"
  const destinations_ecole: OptionItem[] = StringUtil.createOptions([
    'ECOLES AU TOGO',
    'ÉCOLES AU BÉNIN',
    "ÉCOLES EN CÔTE D'IVOIRE",
    'ÉCOLES AU GHANA',
    'ÉCOLES AU BURKINA FASO',
    'ÉCOLES À MADAGASCAR',
    'ÉCOLES AU CAMBODGE',
    'ÉCOLES AU NÉPAL',
    'ÉCOLES AU SENEGAL'
  ]);

  // les projets fils du projet "Construire une mosquée"
  const destinations_mosquee: OptionItem[] = StringUtil.createOptions([
    'MOSQUÉE AU GHANA',
    'MOSQUÉE AU TOGO',
    'MOSQUÉE AU BURKINA FASO',
    'MOSQUÉE AU BÉNIN',
    "MOSQUÉE EN CÔTE D'IVOIRE",
    'MOSQUÉE À MADAGASCAR',
    'MOSQUÉES AU CAMBODGE',
    'MOSQUÉES AU NÉPAL',
    'MOSQUÉE AU SENEGAL'
  ]);

  // pour le choix du Nombre de personnes
  const nbPersonnes: OptionItem[] = StringUtil.createOptions(['0-10', '10-20', '20-30+']);

  // création d'un état pour stocker dynamiquement la liste des produits selon la destination
  const [products, setProducts] = useState(StringUtil.createOptions([]));

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  const allStates: OptionItem[] = [
    {
      label: 'Nouveau',
      value: 'new'
    },
    {
      label: 'En cours',
      value: 'pending'
    },
    {
      label: 'Terminé',
      value: 'done'
    }
  ];
  // variable permettant de récupérer la destination en cours (après changement aussi depuis le select)
  const [destinationValue, setDestinationValue] = useState(requestProject.destination);

  // variable permettant de récupérer le type de projet en cours (après changement aussi depuis le select)
  const [typeValue, setTypeValue] = useState(requestProject.type);

  // Dans cette variable "destinations" on va affecter l'une des trois: destination_puits, destinations_ecole, destinations_mosquee
  const [destinations, setDestinations] = useState(StringUtil.createOptions([]));

  // au changement du select de type, on récupère les destinations associés
  useEffect(() => {
    // on affecte les options du select destination selon le type de projet choisit
    if (typeValue == 'FAIRE UN PUITS') {
      setDestinations(destinations_puits);
    }
    if (typeValue == 'CONSTRUIRE UNE ÉCOLE') {
      setDestinations(destinations_ecole);
    }
    if (typeValue == 'CONSTRUIRE UNE MOSQUÉE') {
      setDestinations(destinations_mosquee);
    }
  }, [typeValue]);

  // au changement du select de destination, on récupère les produits associés
  useEffect(() => {
    let list_products: any = [];
    ProductRepository.getProductsByProjectName(destinationValue).then((result) => {
      let prj_products: any = result.data;
      prj_products.map((prod: any) => list_products.push(prod));
      let v_products = StringUtil.createCustomOptions(list_products);
      // on met à jour la valeur du destination pour mettre à jour la liste des produits
      setProducts(v_products);
    });
  }, [destinationValue]);

  return (
    <Formik initialValues={requestProject} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange={submittedOnce}>
      {(formik) => {
        const { values, handleChange, errors } = formik;
        const { name, description, destination, product, type, nbPerson, state } = values;

        const onFormChange = (e: React.ChangeEvent<any>) => {
          if (e.target.name == 'type') {
            setTypeValue(e.target.value);
          }
          if (e.target.name == 'destination') {
            setDestinationValue(e.target.value);
          }
          handleChange(e);
        };

        const baseProps = {
          onChange: onFormChange,
          errors: errors,
          showError: true
        };

        return (
          <Form
            onSubmit={(e) => {
              setSubmittedOnce(true);
              formik.handleSubmit();
              e.preventDefault();
            }}
            noValidate
          >
            <Container fluid>
              {initialValue && (
                <Row>
                  <Col>
                    <InputField label="Email" name="unknow" onChange={handleChange} disabled value={initialValue.creator?.email} />
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <InputField label="Nom" placeholder={'Name*'} name="name" value={name} {...baseProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField label="Description" as="textarea" placeholder={'Description*'} name="description" value={description} {...baseProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectField label={'Type'} name="type" options={types} defaultValue={type} {...baseProps} />
                </Col>
                <Col>
                  <SelectField label={'Destination'} name="destination" options={destinations} value={destination} {...baseProps} />
                </Col>
                <Col>
                  <SelectField label={'Product'} name="product" options={products} value={product} {...baseProps} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <SelectField label={'Etat*'} name="state" options={allStates} defaultValue={state} {...baseProps} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <SelectField label={'Nb Personne*'} name="nbPerson" options={nbPersonnes} defaultValue={nbPerson} {...baseProps} helper={'Visible sur quelle page'} />
                </Col>
              </Row>

              <div className="text-center">
                <Button variant="primary" type="submit" size="lg">
                  Valider
                </Button>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RequestProjectForm;
