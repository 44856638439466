import React, { useEffect, useState } from 'react';
import { type Crop, type ReactCropProps } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { type FieldTypeDefaultValue, type OnChange } from './types/base.field.type';
import ImageModal from '../modals/image.modal';
import { Image, type ImageProps } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Helper from './transverse/helper';
import Error from './transverse/error';

export type CropImageProps = FieldTypeDefaultValue & {
  onChange: OnChange;
  reactCropProps?: Partial<ReactCropProps>;
  cropProps?: Partial<Crop>;
  showPreview?: boolean;
  imageProps?: ImageProps;
  label?: string;
};

const ImageEditableField: React.FC<CropImageProps> = ({ reactCropProps, onChange, name, defaultValue = '', imageProps, cropProps, showPreview = true, ...restProps }) => {
  const { helper, errors, showError, label } = restProps;
  const [show, setShow] = useState<boolean>(false);
  const [image, setImage] = useState<string>(defaultValue);

  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({
    width: Number(imageProps?.width) || 0,
    height: Number(imageProps?.height) || 0
  });

  useEffect(() => {
    if (image !== defaultValue) {
      onChange(name, image);
    }
  }, [image, defaultValue, name]);

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const img = e.currentTarget;
    setDimensions({ width: img.naturalWidth, height: img.naturalHeight });
  };

  return (
    <div className="d-flex justify-content-center">
      <div className={'image-editable-field'} style={{ width: dimensions?.width, height: dimensions?.height }}>
        <ImageModal
          cropProps={{ x: 0, y: 0, ...cropProps } as Crop}
          reactCropProps={reactCropProps}
          onHide={() => {
            setShow(false);
          }}
          show={show}
          onSelect={(encoded) => {
            setImage(encoded);
          }}
        />
        <div className="image-editor-wrapper">
          <div className={'actions'}>
            <i>
              <FontAwesomeIcon
                className="text-primary"
                icon={faEdit}
                onClick={() => {
                  setShow(true);
                }}
              />
            </i>
            <i>
              <FontAwesomeIcon
                className="text-danger"
                icon={faTimesCircle}
                onClick={() => {
                  setImage('');
                }}
              />
            </i>
          </div>
          {showPreview && image && <Image src={image} onLoad={handleImageLoad} />}
        </div>
        <Helper text={helper} />
        <Error showError={showError} error={errors?.[name]} />
      </div>
    </div>
  );
};

export default ImageEditableField;
