import React, { useEffect, useState } from 'react';
import StatisticsRepository from '../../api/repository/StatisticsRepository';
import { GetStatistics } from '../../api/repository/types/statitics.type';
import PaymentChart from './charts/payment.chart';
import UserChart from './charts/user.chart';
import ContactChart from './charts/contact.chart';
import RequestProjectChart from './charts/request_project.chart';
import './style.scss';
import { toggleLoading } from '../../state-management/global-store';
import LastProjectReached from './charts/last-project-reached';

const Home: React.FC = () => {
  const [statistics, setStatistics] = useState<GetStatistics>();

  useEffect(() => {
    toggleLoading();
    StatisticsRepository.getStatistic().then((response) => {
      setStatistics(response.data);
      toggleLoading();
    });
  }, []);

  if (!statistics) {
    return null;
  }

  return (
    <div className="home-wrapper">
      <LastProjectReached />
      <PaymentChart stats={statistics.payments}></PaymentChart>
      <UserChart stats={statistics.user}></UserChart>
      <ContactChart stats={statistics.contact}></ContactChart>
      <RequestProjectChart stats={statistics.request_projects}></RequestProjectChart>
    </div>
  );
};

export default Home;
