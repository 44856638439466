import React, { useState } from 'react';
import { Formik, type FormikConfig } from 'formik';
import InputField from '../../../components/form/fields/input.field';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { type OptionItem } from '../../../components/form/fields/types/option.type';
import { type CreateContactType } from '../../../api/repository/types/contact.type';
import SelectField from '../../../components/form/fields/select.field';

export interface Props {
  initialContact?: CreateContactType;
  onSubmit: FormikConfig<CreateContactType>['onSubmit'];
  validationSchema?: any;
}

export const statesContact: OptionItem[] = [
  {
    label: 'Nouveau',
    value: 'new'
  },
  {
    label: 'En attente',
    value: 'pending'
  },
  {
    label: 'Terminé',
    value: 'done'
  }
];

const ContactForm: React.FC<Props> = ({ validationSchema, initialContact, onSubmit }) => {
  const contact: CreateContactType = {
    message: '',
    phone: '',
    state: 'new',
    subject: '',
    name: '',
    email: '',
    ...initialContact
  };

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  return (
    <Formik initialValues={contact} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange={submittedOnce}>
      {(formik) => {
        const { values, handleChange, errors } = formik;
        const { name, email, state, message, phone, subject } = values;

        const baseProps = {
          onChange: handleChange,
          errors: errors,
          showError: true
        };

        return (
          <Form
            onSubmit={(e) => {
              setSubmittedOnce(true);
              formik.handleSubmit();
              e.preventDefault();
            }}
            noValidate
          >
            <Container fluid>
              <Row>
                <Col>
                  <InputField placeholder={'Name*'} name="name" value={name} {...baseProps} />
                </Col>
                <Col>
                  <InputField placeholder={'Email*'} name="email" value={email} {...baseProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField label="Phone" name={'phone'} value={phone} {...baseProps} />
                </Col>

                <Col>
                  <InputField label="Sujet" name={'subject'} value={subject} {...baseProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField as="textarea" placeholder={'Message*'} name="message" value={message} {...baseProps} />
                </Col>
              </Row>

              <SelectField label={'Statut'} name="state" options={statesContact} defaultValue={state} {...baseProps} />

              <div className="text-center">
                <Button variant="primary" type="submit" size="lg">
                  Valider
                </Button>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactForm;
