import React, { useMemo, useState } from 'react';
import TablePagination from '../../components/table/TablePagination';
import { columns as columnDonations } from './services/columns';
import { SHOW_PAYMENT_STATUS } from '../../api/repository/types/payment.type';
import DonationRepository from '../../api/repository/DonationRepository';
import { TransfertModalProvider } from '../payments/modal/Transfert';
import { transfertDonation } from './services/manager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import DonationFilter from './form/donation.filter';

interface Filter {
  paymentStatus?: string[];
  project?: string;
}

const DonationList: React.FC = () => {
  const [filter, setFilter] = useState<Filter>({
    paymentStatus: SHOW_PAYMENT_STATUS
  });

  const [donationFilter, setDonationFilter] = useState<any>({});

  const query: Filter = { ...filter, ...donationFilter };

  const columns = useMemo(() => {
    const col = [...columnDonations];
    col.push({
      isDummyField: true,
      text: 'Actions',
      dataField: 'actions',
      formatter: (v, donation) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            TransfertModalProvider.create({
              onSubmit: (data) => {
                transfertDonation(donation.id, data);
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faShareSquare} />
        </a>
      )
    });

    return col;
  }, []);

  return (
    <div className="crud-list">
      <h1>Liste des dons.</h1>

      <DonationFilter
        onSubmit={(donationFilter) => {
          setDonationFilter({
            ...donationFilter
          });
        }}
      />

      <TablePagination repository={DonationRepository} getAllQuery={query} columns={columns} keyField="id" itemPerPage={20} />
    </div>
  );
};

export default DonationList;
