import { BaseRepository } from './BaseRepository';

class AiRepository<T = any> extends BaseRepository<T> {
  getArea() {
    return '/api/ai';
  }

  translateProject(projectId: string) {
    const url = this.getBaseEndpoint() + `/projects/${projectId}/translate`;
    return this.client.get(url);
  }
}

export default new AiRepository();
