import { type TransfertData } from '../../payments/modal/transfert.modal';
import DonationRepository from '../../../api/repository/DonationRepository';
import { notify } from '../../../services/notification/notification';

export const transfertDonation = (donationId: string, data: TransfertData) => {
  if (data.type === 'user') {
    DonationRepository.transfertToUser(donationId, data.userId!).then((transfert) => {
      notify({
        title: 'Transfert effectué',
        message: 'Le transfert a été effectué avec succès'
      });
    });
  } else {
    DonationRepository.transfertToProject(donationId, data.projectId!).then(() => {
      notify({
        title: 'Transfert effectué',
        message: 'Le transfert a été effectué avec succès'
      });
    });
  }
};
