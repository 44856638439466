import { type iNotification, Store } from 'react-notifications-component';

export const notify = (options: Partial<iNotification>) => {
  const defaultOptions: Partial<iNotification> = {
    insert: 'top',
    type: 'success',
    container: 'top-right',
    dismiss: {
      duration: 2000,
      onScreen: true
    }
  };

  Store.addNotification({
    ...defaultOptions,
    ...options
  } as iNotification);
};
