import React from 'react';
import { Formik, type FormikConfig } from 'formik';
import { Button, Form } from 'react-bootstrap';
import InputField from '../../../components/form/fields/input.field';
import RichTextField from '../../../components/form/fields/richtext.field';
import BooleanField from '../../../components/form/fields/boolean.field';
import ImageField from '../../../components/form/fields/image.field';
import { type CreateProductType } from '../../../api/repository/types/product.type';
import { type LocaleType } from '../../../api/repository/types/transverse.type';
import * as yup from 'yup';

export interface Props {
  product?: CreateProductType;
  onSubmit: FormikConfig<CreateProductType>['onSubmit'];
  locale: LocaleType;
}

const ProductCreate: React.FC<Props> = ({ locale, product, onSubmit }) => {
  const initialValue: CreateProductType = {
    title: '',
    description: '',
    askQuantity: false,
    askName: false,
    isVariable: true,
    price: undefined,
    position: 1,
    locale: locale,
    canBeRecurrent: true,
    nameMaxLength: undefined,
    commentAboutName: undefined,
    ...product,
    image: ''
  };

  const baseImage = typeof product?.image === 'string' ? product.image : product?.image?.url;

  const schema = yup.object<Partial<CreateProductType>>({
    title: yup.string().required(),
    description: yup.string().required(),
    image: baseImage ? yup.string().notRequired() : yup.string().required()
  });

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValue} validationSchema={schema} validateOnMount>
      {(formik) => {
        const { setFieldValue, errors, handleChange, values, handleSubmit, isValid } = formik;

        const { title, description, askName, askQuantity, isVariable, price, position, canBeRecurrent, nameMaxLength, commentAboutName, image } = values;
        const baseProps = {
          errors: errors,
          onChange: handleChange,
          showError: true
        };

        const richProps = {
          onChange: async (name: string, value: any) => await setFieldValue(name, value),
          errors: errors,
          showError: true
        };

        return (
          <Form onSubmit={handleSubmit}>
            <RichTextField
              label="Titre*"
              name={'title'}
              defaultValue={title}
              onChange={() => {}}
              config={{ toolbar: ['bold'] }}
              onBlur={(name, value) => {
                const striped = value.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '');
                setFieldValue(name, striped);
              }}
            />

            <InputField type="text" label="Position" name={'position'} defaultValue={position} {...baseProps} />

            <RichTextField label="Description" name={'description'} defaultValue={description} {...richProps} />

            <hr />
            <BooleanField
              type="switch"
              label={'Est ce que le produit peut être payé mensuellement'}
              name="canBeRecurrent"
              defaultValue={canBeRecurrent}
              helper="Si oui, l'utilisateur pourra effectuer un don mensuellement sur ce produit."
              {...baseProps}
              onChange={handleChange}
            />
            <hr />
            <BooleanField
              type="switch"
              label={'Est ce un produit à prix variable'}
              name="isVariable"
              defaultValue={isVariable}
              helper="Si oui, l'utilisateur pourra entrer le prix qu'il souhaite et non celui définit dans prix."
              {...baseProps}
              onChange={(v: any) => {
                handleChange(v);
                if (v.target.checked) {
                  setFieldValue('price', undefined);
                  setFieldValue('askName', false);
                } else {
                  setFieldValue('price', 0);
                }
              }}
            />

            {price !== undefined && <InputField label="Prix*" name={'price'} defaultValue={price} type="number" {...baseProps} />}
            <hr />
            <BooleanField
              type="switch"
              label={'Aciver la saisie de la quantité pour les donateurs'}
              name="askQuantity"
              defaultValue={askQuantity}
              helper="Si oui, le donateur pourra saisir la quantité pour ce produit."
              {...baseProps}
              onChange={handleChange}
            />
            <hr />
            {price !== undefined && (
              <BooleanField
                type="switch"
                label={'Demander un nom de projet ?'}
                name="askName"
                defaultValue={askName}
                helper="Si oui, l'utilisateur devra entrer un nom de projet avant d'ajouter au panier ce produit."
                {...baseProps}
                onChange={(v: any) => {
                  handleChange(v);
                  if (v.target.checked) {
                    setFieldValue('nameMaxLength', undefined);
                    setFieldValue('commentAboutName', undefined);
                  } else {
                    setFieldValue('nameMaxLength', 25);
                  }
                }}
              />
            )}

            {askName === true && (
              <>
                <hr />
                <InputField type="number" label="Nombre de caractère demander pour le nom du projet" name={'nameMaxLength'} {...baseProps} value={nameMaxLength} />
                <RichTextField label="Description" name={'commentAboutName'} defaultValue={commentAboutName} {...richProps} />
              </>
            )}

            <hr />

            <ImageField
              showError
              errors={errors}
              label="Image du produit"
              cropProps={{ width: 630, height: 300 }}
              name="image"
              defaultValue={baseImage}
              onChange={(name, value) => {
                setFieldValue(name, value);
              }}
            />

            <div className="text-center">
              <Button type="submit" disabled={!isValid}>
                Valider
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProductCreate;
