import React from 'react';
import NavigationItem from './navigation-item/NavigationItem';
import './Navigation.scss';
import { menuState } from '../../../state-management/global-store';
import { navigationMenu } from './generate-menu';

const Navigation: React.FC = () => {
  return (
    <nav className="navigation-wrapper">
      <ul className="list-unstyled no-margin">
        {navigationMenu.map((item, index) => (
          <NavigationItem key={index} isActive={menuState.value.activeMenu === index} {...item} onClick={() => (menuState.value.activeMenu = index)} />
        ))}
      </ul>
    </nav>
  );
};

export default Navigation;
