import React from 'react';
import ReactDOM from 'react-dom';
import TransfertProjectModal, { type TransfertProjectModalProps } from './transfert.modal';
import Modal from '../../../components/modal/modal';

export interface TransfertModalProps {
  onSubmit: TransfertProjectModalProps['onSubmit'];
}

export const ProjectTransfertModalProvider = {
  create: (props: TransfertModalProps) => {
    const container = document.createElement('transfert');
    document.body.append(container);
    const options = {
      onClose: () => {
        container.remove();
        ReactDOM.unmountComponentAtNode(container);
      }
    };

    ReactDOM.render(
      <Modal title="Transférer tous les dons de 2 projets" {...options}>
        <TransfertProjectModal
          onSubmit={(transfert, formik) => {
            props.onSubmit?.(transfert, formik);
            options.onClose();
          }}
        />
      </Modal>,
      container
    );
  }
};
