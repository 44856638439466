import React from 'react';
import PaymentForm from './form/payment.form';
import DonationRepository from '../../api/repository/DonationRepository';
import { notify } from '../../services/notification/notification';

const PaymentCreate: React.FC = () => {
  return (
    <PaymentForm
      onSubmit={(donation) => {
        DonationRepository.createManual(donation).then(() => {
          notify({
            title: 'Création effectuée avec succès',
            message: 'Le payment a bien été créé'
          });
        });
      }}
    />
  );
};

export default PaymentCreate;
