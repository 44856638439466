import React from 'react';
import { type FormikConfig } from 'formik';
import * as yup from 'yup';
import KeyNumberRepository from '../../api/repository/KeyNumberRepository';
import { type CreateKeyNumber } from '../../api/repository/types/key-number.type';
import { notify } from '../../services/notification/notification';
import KeyNumberForm from './form/key-number.form';

export interface Props {
  id?: string;
}

const KeyNumberCreate: React.FC<Props> = () => {
  const onSubmit: FormikConfig<CreateKeyNumber>['onSubmit'] = (entity: CreateKeyNumber) => {
    KeyNumberRepository.new(entity).then(() => {
      notify({
        title: 'Création effectuée.',
        message: 'La faq a été créé'
      });
    });
  };

  const schema = yup.object<Partial<CreateKeyNumber>>({
    number: yup.number().required(),
    key: yup.object().shape({
      fr: yup.string().required(),
      en: yup.string().required()
    })
  });

  return <KeyNumberForm onSubmit={onSubmit} validationSchema={schema} />;
};

export default KeyNumberCreate;
