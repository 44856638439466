import React, { useState } from 'react';
import { columns } from './services/columns';
import TablePagination from '../../../components/table/TablePagination';
import { Button } from 'react-bootstrap';
import { notify } from '../../../services/notification/notification';
import RequestProjectRepository from '../../../api/repository/RequestProjectRepository';
import { RequestProjectFilter, type GetRequestProjectType } from '../../../api/repository/types/request-project.type';
import RequestProjectFilterForm from '../form/request-project-filter.form';

const RequestProjectList: React.FC = () => {
  const [selected, setSelected] = useState<GetRequestProjectType[]>([]);
  const [deletedRows, setDeletedRows] = useState<any[]>([]);
  const [filters, setFilters] = useState<RequestProjectFilter>({});
  const deleteContact = () => {
    selected.map(async (row) => {
      await RequestProjectRepository.delete(row.id).then(() => {
        deletedRows.push(row.id);
        setDeletedRows([...deletedRows]);
      });

      notify({
        title: 'Suppression effectuée',
        message: 'Demande a été supprimée'
      });
    });
  };

  return (
    <div className="crud-list">
      <h1>Liste des demandes de création de collectes.</h1>

      <RequestProjectFilterForm onSubmit={setFilters} />

      <div className="action-wrapper">
        <Button disabled={selected.length === 0} onClick={deleteContact}>
          Supprimer
        </Button>
      </div>

      <TablePagination
        repository={RequestProjectRepository}
        columns={columns}
        keyField="id"
        rowClasses={(row) => {
          return deletedRows.includes(row.id) ? 'd-none' : '';
        }}
        selectRow={{
          mode: 'checkbox',
          hideSelectAll: true,
          onSelect: (contact, isSelected) => {
            if (isSelected) {
              setSelected([...selected, contact]);
            } else {
              setSelected([...selected.filter((s) => s.id !== contact.id)]);
            }
          }
        }}
        getAllQuery={filters}
      />
    </div>
  );
};

export default RequestProjectList;
