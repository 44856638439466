import React, { useEffect, useState } from 'react';
import AdminRepository from '../../../api/repository/AdminRepository';
import { type AdminCreateType } from '../types/admin.create.type';
import { type FormikConfig } from 'formik';
import AdminForm from '../admin-form/admin.form';
import { type AdminType } from '../../../api/repository/types/admin.type';
import { useParams } from 'react-router-dom';
import { notify } from '../../../services/notification/notification';
import * as yup from 'yup';

export interface Props {
  id?: string;
}

const AdminEdit: React.FC<Props> = () => {
  const [user, setUser] = useState<AdminType>();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    AdminRepository.get(id).then((response) => {
      setUser(response.data);
    });
  }, [id]);

  const onSubmit: FormikConfig<AdminCreateType>['onSubmit'] = (admin: AdminCreateType) => {
    const { firstName, lastName, email, isActive, roles, password } = admin;
    const edit = { firstName, lastName, email, isActive, roles, password };

    AdminRepository.edit(id, edit).then(() => {
      notify({
        title: 'Edition effectuée.',
        message: `${admin.firstName} a été modifié`
      });
    });
  };

  const schema = yup.object<Partial<AdminCreateType>>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required()
  });

  if (!user) {
    return <>Chargement...</>;
  }

  return <AdminForm initialUser={user} onSubmit={onSubmit} validationSchema={schema} />;
};

export default AdminEdit;
