import React from 'react';
import { type FormikConfig } from 'formik';
import { notify } from '../../services/notification/notification';
import { type CreateCurrency } from '../../api/repository/types/currency.type';
import CurrencyRepository from '../../api/repository/CurrencyRepository';
import CurrencyForm from './form/currency.form';

export interface Props {
  id?: string;
}

const CurrencyCreate: React.FC<Props> = () => {
  const onSubmit: FormikConfig<CreateCurrency>['onSubmit'] = (entity: CreateCurrency) => {
    CurrencyRepository.new(entity).then(() => {
      notify({
        title: 'Création effectuée.',
        message: 'La faq a été créé'
      });
    });
  };

  return <CurrencyForm onSubmit={onSubmit} disabled={false} />;
};

export default CurrencyCreate;
