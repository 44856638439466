import { BaseRepository } from './BaseRepository';
import { type Asset, type VideoAsset } from './types/project.type';

class AssetRepository<T = Asset> extends BaseRepository<T> {
  getArea() {
    return '/api/assets';
  }

  createAssetVideo(asset: VideoAsset) {
    const url = this.getBaseEndpoint() + '/asset-video-type';

    return this.client.post<VideoAsset>(url, asset);
  }

  editAssetVideo(assetId: string, asset: VideoAsset | any) {
    const url = this.getBaseEndpoint() + `/asset-video-type/${assetId}`;

    return this.client.put<VideoAsset>(url, asset);
  }

  duplicate(productId: string, projectId: string) {
    const url = this.getBaseEndpoint() + `/${productId}/duplicate-to-project`;

    return this.client.post(url, {
      project_id: projectId
    });
  }
}

export default new AssetRepository();
