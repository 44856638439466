import React from 'react';
import { Form, Formik, type FormikConfig } from 'formik';
import * as yup from 'yup';
import UserAutocomplete from '../../../components/autocomplete/user.autocomplete';
import ProjectAutocomplete from '../../../components/autocomplete/project.autocomplete';
import InputField from '../../../components/form/fields/input.field';
import { Button } from 'react-bootstrap';
import TagsField from '../../../components/form/fields/tags.field';
import { generateBaseProps, generateOptionsItemSelected } from '../../../components/form/fields/services/select.service';

interface Props {
  onSubmit: FormikConfig<CreateManualPayment>['onSubmit'];
}

export interface CreateManualPayment {
  total: number;
  project: string;
  user: string;
  data: string;
  paymentSources: string[];
}

const PaymentForm: React.FC<Props> = ({ onSubmit }) => {
  const initialValue: CreateManualPayment = {
    total: 0,
    project: '',
    user: '',
    data: '',
    paymentSources: ['other']
  };

  const paymentSourcesOptions = [
    {
      label: 'Espèce',
      value: 'cash'
    },
    {
      label: 'Carte bleu',
      value: 'card'
    },
    {
      label: 'Virement',
      value: 'transfer'
    },
    {
      label: 'Autre',
      value: 'other'
    }
  ];

  const schema = yup.object<Partial<CreateManualPayment>>({
    total: yup.number().required(),
    data: yup.string(),
    project: yup.string().required(),
    user: yup.string().required()
  });

  return (
    <Formik validationSchema={schema} initialValues={initialValue} onSubmit={onSubmit}>
      {(formik) => {
        const { values, setFieldValue, handleChange } = formik;
        const { paymentSources } = values;
        return (
          <Form onSubmit={formik.handleSubmit}>
            <label>Utilisateur: </label>
            <UserAutocomplete onChange={async (user) => await setFieldValue('user', user.value)} />
            <label>Projet: </label>
            <ProjectAutocomplete onChange={async (project) => await setFieldValue('project', project.value)} />
            <InputField helper={'prix en euro'} defaultValue={values.total} name="total" onChange={handleChange} type="number" />
            <label>Nom du projet: </label>
            <InputField helper={'pour les projes nominatifs'} defaultValue={values.data} name="data" onChange={handleChange} type="text" />
            <TagsField
              placeholder={'Comment cela a été payé'}
              label={'Type de paiement'}
              {...generateBaseProps(formik, 'paymentSources')}
              name={'paymentSources'}
              options={paymentSourcesOptions}
              defaultValue={generateOptionsItemSelected(paymentSources, paymentSourcesOptions)}
            />

            <div className="text-center">
              <Button type="submit">Créer</Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PaymentForm;
