import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { type GetListProject } from '../../../api/repository/types/project.type';
import { type ColumnsType } from '../../../components/table/types/columns.type';
import React from 'react';
import dayjs from 'dayjs';
import ProjectUnpublishModal from '../modal/project-unpublish.modal';

export const columns: Array<ColumnsType<GetListProject>> = [
  {
    dataField: 'name',
    text: 'Nom',
    sort: true,
    headerStyle: () => ({ width: 200 })
  },
  {
    dataField: 'goal',
    text: 'Objectif',
    sort: true,
    classes: (_, project) => (project.goal && project.goal <= project.total ? 'bg-success' : ''),
    formatter: (value, project) => (
      <>
        {value ? value + '€' : ''} <br />
        {project.goalReachedAt ? `Objectif atteint le ${dayjs(project.goalReachedAt).format('DD/MM/YYYY HH:mm:ss')}` : ''}
      </>
    ),
    headerStyle: { width: 100 }
  },
  {
    dataField: 'visibleOn',
    text: 'Visible sur ?',
    sort: true,
    formatter: (value, project) => (
      <ul className="m-0 p-0 list-inline">
        {project.visibleOn?.map((v) => (
          <li key={v} className="list-inline-item">
            {v}
          </li>
        ))}
      </ul>
    )
  },
  {
    dataField: 'parent',
    text: 'Parent',
    formatter: (_, project) => <>{project.parent?.name}</>,
    headerStyle: { width: 100, textOverflow: 'ellipsis' }
  },
  {
    dataField: 'position',
    text: 'Position',
    sort: true
  },
  {
    dataField: 'total',
    text: 'Total dons',
    formatter: (value) => <>{value} €</>
  },
  {
    dataField: 'createdAt',
    text: 'Date de création',
    preRender: {
      type: 'datetime'
    },
    sort: true
  },
  {
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    headerStyle: { width: 100, textOverflow: 'ellipsis' },
    formatter: (_cell, project) => {
      return (
        <>
          <Link to={`/projects/${project.id}/edit/informations`}>
            <FontAwesomeIcon icon={faEdit} />
          </Link>
          {'  '}
          <ProjectUnpublishModal project={project} />
        </>
      );
    },
    classes: 'actions'
  }
];
