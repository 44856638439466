import React, { useMemo } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { type FormikProps } from 'formik';
import { type Asset, type DonAsset, type GetOneProject, type ProjectTranslationType } from '../../../api/repository/types/project.type';
import { type LocaleType } from '../../../api/repository/types/transverse.type';
import RichTextField from '../../../components/form/fields/richtext.field';
import InputField from '../../../components/form/fields/input.field';
import SelectField from '../../../components/form/fields/select.field';
import { states } from '../services/constants';
import AssetList from '../assets/asset.list';
import AssetRepository from '../../../api/repository/AssetRepository';
import ProjectRepository from '../../../api/repository/ProjectRepository';
import { notify } from '../../../services/notification/notification';
import ProductList from '../products/product.list';
import ProductRepository from '../../../api/repository/ProductRepository';
import AiRepository from '../../../api/repository/AiRepository';
import { confirm } from 'react-bootstrap-confirmation';
import { toggleLoading } from '../../../state-management/global-store';

interface Props {
  formik: FormikProps<ProjectTranslationType>;
  initialTranslation?: ProjectTranslationType;
  locale: LocaleType;
  project: GetOneProject;
  assets: Asset[];
  images: DonAsset[];
}

const ProjectTranslation: React.FC<Props> = ({ assets, images, formik, initialTranslation, project, locale }) => {
  const { handleChange, setFieldValue, errors, values } = formik;

  const baseProps = {
    onChange: handleChange,
    errors: errors
  };

  const richProps = {
    onChange: async (name: string, value: any) => await setFieldValue(name, value),
    errors: errors
  };

  const { title, content, summary, state, slug, assetTitle, assetDescription, childrenTitle, donatorVideosUrl } = values;

  const nbDonatorAssets = useMemo(() => {
    let nbDonator = 0;
    assets.forEach((asset) => {
      if (asset.visibility == 'private') {
        nbDonator++;
      }
    });

    return nbDonator;
  }, [assets]);

  const translate = async () => {
    const result = await confirm(`Veuillez sauvegarder la traduction courante avant de continuer.\n\n\n Voulez vous vraiment traduire ce projet en anglais. Ceci écrasera le contenu existant ? `);
    if (!result) {
      return;
    }

    toggleLoading();

    AiRepository.translateProject(project.id)
      .then(() => {
        toggleLoading();

        notify({
          title: 'Le projet a été traduis',
          message: 'Rechargement de la page...',
          onRemoval: () => {
            window.location.reload();
          }
        });
      })
      .catch(() => {
        notify({
          title: 'Erreur de la traduction',
          message: "La traduction n'a pas été effectuée..."
        });

        toggleLoading();
      });
  };

  const canCreateAsset = initialTranslation !== undefined && assets && assets.length - nbDonatorAssets < 4;

  const url = slug ? `${process.env.REACT_APP_API_BASE_URL}/donations/${slug}` : '';

  return (
    <div className="mt-2">
      <Row className="mb-2">
        {locale === 'fr' && (
          <Col className="d-flex justify-content-end">
            <Button variant="outline-danger" onClick={translate}>
              Traduire en anglais
            </Button>
          </Col>
        )}
      </Row>
      <Row className="mb-2">
        <Col>
          <span>
            Url du projet: <a href={url}>{url}</a>
          </span>
          <hr />
        </Col>
      </Row>

      <Row>
        <Col>
          <RichTextField
            label="Titre"
            name={'title'}
            defaultValue={title}
            onChange={() => {}}
            config={{ toolbar: ['bold'] }}
            onBlur={(name, value) => {
              const striped = value.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '');
              setFieldValue('title', striped);
            }}
          />
        </Col>

        <Col>
          <InputField label="Slug" name={'slug'} defaultValue={slug} {...baseProps} helper={"Permet d'avoir le liens de l'url, (éviter accents, espaces, séparer avec -)"} />
        </Col>
      </Row>
      <RichTextField label={'Contenu'} name={'content'} defaultValue={content} {...richProps} />
      <RichTextField label={'Contenu résumé'} name={'summary'} defaultValue={summary} {...richProps} helper={'Contenu visible sur les autres pages (listes).'} />

      <SelectField options={states} name="state" value={state} helper="seul les projets publiés seront visibles par les utilisateurs." {...baseProps} />

      <Row>
        <Col>
          <RichTextField
            label="Titre liste des enfants"
            helper="Le titre devant la liste des enfants. Exemple: 'Nos actions dans différents pays'"
            name={'childrenTitle'}
            defaultValue={childrenTitle}
            onChange={() => {}}
            config={{ toolbar: ['bold'] }}
            onBlur={(name, value) => {
              const striped = value.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '');
              setFieldValue(name, striped);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <RichTextField
            label="Titre asset"
            helper="Titre au dessus de la liste des assets"
            name={'assetTitle'}
            defaultValue={assetTitle}
            onChange={() => {}}
            config={{ toolbar: ['bold'] }}
            onBlur={(name, value) => {
              const striped = value.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '');
              setFieldValue(name, striped);
            }}
          />
        </Col>

        <Col>
          <RichTextField
            label="Description asset"
            helper="Description au dessus de la liste des assets"
            name={'assetDescription'}
            defaultValue={assetDescription}
            onChange={() => {}}
            config={{ toolbar: ['bold'] }}
            onBlur={(name, value) => {
              const striped = value.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '');
              setFieldValue(name, striped);
            }}
          />
        </Col>
      </Row>

      <hr />
      <AssetList
        project={project}
        locale={locale}
        assets={assets}
        canCreate={canCreateAsset}
        onCreate={(asset) => {
          AssetRepository.createAssetVideo(asset).then((response) => {
            ProjectRepository.attachAsset(project.id, locale, response.data.id!, 'attach').then(() => {
              notify({
                title: "Creation de l'asset effectué",
                message: "L'asset a été rattaché au projet pour cette langue."
              });
            });
          });
        }}
        onEdit={(asset) => {
          AssetRepository.editAssetVideo(asset.id!, asset).then(() => {
            notify({
              title: "Mise à jour de l'asset",
              message: "Mise à jour de l'asset effectué"
            });
          });
        }}
        onDelete={(asset) => {
          ProjectRepository.attachAsset(project.id, locale, asset.id!, 'detach').then(() => {
            notify({
              title: 'Asset détaché',
              message: "L'asset a été détaché, mais reste disponible pour les autres projets"
            });
          });
        }}
      />
      <br />
      <hr />
      <br />
      <ProductList
        locale={locale}
        products={project.products ? project.products.filter((p) => p.locale === locale) : []}
        project={project}
        onCreate={(product) => {
          ProductRepository.new(product).then((response) => {
            ProjectRepository.attachProduct(project.id, locale, response.data.id, 'attach').then(() => {
              notify({
                title: 'Creation du produit effectué',
                message: 'Le produit a été rattaché au projet pour cette langue.'
              });
            });
          });
        }}
        onEdit={(product) => {
          ProductRepository.edit(product.id!, product).then(() => {
            notify({
              title: 'Mise à jour du produit',
              message: 'Mise à jour du produit effectué'
            });
          });
        }}
        onDelete={(product) => {
          ProjectRepository.attachProduct(project.id, locale, product.id, 'detach').then(() => {
            notify({
              title: 'Asset détaché',
              message: "L'asset a été détaché, mais reste disponible pour les autres projets"
            });
          });
        }}
      />
    </div>
  );
};

export default ProjectTranslation;
