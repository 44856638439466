import { type ColumnsType } from '../../../../components/table/types/columns.type';
import { type UserListType } from '../../../../api/repository/types/user.type';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDonate, faEdit } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export const columns: Array<ColumnsType<UserListType>> = [
  {
    dataField: 'email',
    text: 'Email',
    preRender: {
      type: 'email'
    },
    sort: true
  },
  {
    dataField: 'firstName',
    text: 'Prénom - Nom',
    sort: true,
    formatter: (_cell, user) => <>{user.firstName + ' ' + user.lastName}</>
  },
  {
    dataField: 'phone',
    text: 'Téléphone',
    preRender: {
      type: 'tel'
    }
  },
  {
    dataField: 'createdAt',
    text: 'Date de création',
    sort: true,
    preRender: {
      type: 'datetime'
    }
  },
  {
    dataField: 'lastLoggedAt',
    text: 'Dernière connexion',
    sort: true,
    preRender: {
      type: 'datetime'
    }
  },
  {
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    formatter: (_cell, user) => {
      return (
        <div>
          <Link to={`/users/${user.id}/edit`}>
            <FontAwesomeIcon icon={faEdit} />
          </Link>{' '}
          <Link to={`/userDonations/${user.id}`} target="_blank">
            <FontAwesomeIcon icon={faDonate} />
          </Link>
        </div>
      );
    }
  }
];
