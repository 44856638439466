import React, { useRef } from 'react';
import AutocompleteField, { type AutocompleteProps } from '../form/fields/autocomplete.field';
import axios, { type CancelTokenSource } from 'axios';
import ProjectRepository from '../../api/repository/ProjectRepository';

interface Props {
  defaultValue?: string;
  onChange: AutocompleteProps['onChange'];
}

const ProjectAutocomplete: React.FC<Props> = ({ defaultValue, onChange }) => {
  let cancelTokenSource = useRef<CancelTokenSource>();

  return (
    <AutocompleteField
      name="autocomplete-project"
      defaultValue={defaultValue}
      onChange={onChange}
      loadOptions={async (input, callback) => {
        if (cancelTokenSource.current !== undefined) {
          cancelTokenSource.current.cancel();
        }

        cancelTokenSource.current = axios.CancelToken.source();

        return await ProjectRepository.getAll({ name: input, groups: 'short' }, { cancelToken: cancelTokenSource.current.token }).then((response) => {
          callback(
            response.data.results.map((project) => ({
              value: project.id,
              label: project.name
            }))
          );
        });
      }}
    />
  );
};

export default ProjectAutocomplete;
