import axios from 'axios';
import { Auth } from '../services/Auth';
import Qs from 'qs';

export class HttpClient {
  constructor() {
    const token = Auth.getToken();

    if (token) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + token;
      axios.defaults.paramsSerializer = (params: any) => {
        return Qs.stringify(params);
      };
    }
  }

  post<T>(url: string, data: any) {
    return axios.post<T>(url, data);
  }

  get<T>(url: string, data: any = {}, otherConfig = {}) {
    return axios.get<T>(url, {
      ...otherConfig,
      params: data
    });
  }

  put<T>(url: string, data: any) {
    return axios.put<T>(url, data);
  }

  delete<T>(url: string, data: any = {}) {
    return axios.delete<T>(url, data);
  }
}
