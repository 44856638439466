import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Formik, type FormikConfig } from 'formik';
import InputField from '../../../components/form/fields/input.field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSearchMinus } from '@fortawesome/free-solid-svg-icons';

interface Props {
  onSubmit: FormikConfig<ProjectFilterType>['onSubmit'];
}

interface ProjectFilterType {
  name?: string;
}

const ProjectFilter: React.FC<Props> = ({ onSubmit }) => {
  const filter: ProjectFilterType = {};
  const [showFilter, setShowFilter] = useState<boolean>(false);

  return (
    <div className="my-4">
      <Button
        onClick={() => {
          setShowFilter(!showFilter);
        }}
      >
        <FontAwesomeIcon icon={showFilter ? faSearchMinus : faSearch} /> Filtrer
      </Button>
      <Formik initialValues={filter} onSubmit={onSubmit}>
        {(formik) => {
          const { handleSubmit, handleChange, resetForm, values } = formik;
          const { name } = values;

          return (
            <div className="row">
              <div className="col-md-6 col-sm-12 d-flex justify-content-center">
                <Form className={showFilter ? 'alert-primary p-2 my-2 w-100 rounded' : 'd-none'} onSubmit={handleSubmit}>
                  <Row>
                    <Col sm="4" md="6" xs="12">
                      <InputField name="name" value={name} placeholder="Nom du projet" onChange={handleChange} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="d-flex justify-content-end">
                        <Button className="mr-2" variant="primary" type="submit">
                          Filtrer
                        </Button>
                        <Button
                          variant="secondary"
                          type="reset"
                          onClick={() => {
                            resetForm();
                            handleSubmit();
                          }}
                        >
                          Supprimer les filtres
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default ProjectFilter;
