import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import './toggle-show.wrapper.scss';

interface ToggleShowWrapperProps {
  toggleElement?: any;
  children?: (toggle: ToggleChildren) => any;
  defaultShow?: boolean;
  toggleElementProps?: Partial<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>;
}

interface ToggleChildren {
  toggle: () => void;
  isShow: boolean;
  shown: boolean;
  hidden: boolean;
  setShow: (show: boolean) => void;
}

const ToggleShowWrapper: React.FC<ToggleShowWrapperProps> = ({ defaultShow = false, children, toggleElement, toggleElementProps }) => {
  const [show, setShow] = useState<boolean>(defaultShow);

  const toggleShow = () => {
    setShow(!show);
  };

  const toggler = toggleElement !== undefined ? toggleElement : <IconEdit />;

  return (
    <div className="toggle-show-wrapper" {...toggleElementProps}>
      <div className="toggle-element" tabIndex={-1} onClick={toggleShow}>
        {toggler}
      </div>
      <div className="toggle-children">
        {children?.({
          hidden: !show,
          shown: show,
          toggle: toggleShow,
          isShow: show,
          setShow: setShow
        })}
      </div>
    </div>
  );
};

export const IconEdit: React.FC<{ icon?: any }> = (props) => {
  return (
    <div className="icon-edit">
      <FontAwesomeIcon icon={props.icon ? props.icon : faEdit} />
    </div>
  );
};

export default ToggleShowWrapper;
