import React, { useState } from 'react';
import { Formik, type FormikConfig } from 'formik';
import InputField from '../../../components/form/fields/input.field';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { type OptionItem } from '../../../components/form/fields/types/option.type';
import SelectField from '../../../components/form/fields/select.field';
import { type CreateConfiguration, type GetOneConfiguration } from '../../../api/repository/types/configuration.type';

export interface Props {
  initialValue?: GetOneConfiguration;
  onSubmit: FormikConfig<CreateConfiguration>['onSubmit'];
  validationSchema?: any;
}

const ConfigurationForm: React.FC<Props> = ({ validationSchema, initialValue, onSubmit }) => {
  const keyNumber: CreateConfiguration = {
    key: '',
    value: '',
    type: 'string',
    ...initialValue
  };

  const types: OptionItem[] = [
    {
      value: 'string',
      label: 'Chaine de caractère'
    },
    {
      value: 'array',
      label: 'Tableau json'
    }
  ];

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  return (
    <Formik initialValues={keyNumber} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange={submittedOnce}>
      {(formik) => {
        const { values, handleChange, errors } = formik;
        const { type, value, key } = values;

        const baseProps = {
          onChange: handleChange,
          errors: errors,
          showError: true
        };

        return (
          <Form
            onSubmit={(e) => {
              setSubmittedOnce(true);
              formik.handleSubmit();
              e.preventDefault();
            }}
            noValidate
          >
            <Container fluid>
              <Row>
                <Col>
                  <InputField type="text" placeholder={'Key (unique)*'} name="key" value={key} {...baseProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField as="textarea" type="text" placeholder={'Value*'} name="value" value={value} {...baseProps} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <SelectField options={types} label="Type" name={'type'} value={type} {...baseProps} />
                </Col>
              </Row>

              <div className="text-center">
                <Button variant="primary" type="submit" size="lg">
                  Valider
                </Button>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ConfigurationForm;
