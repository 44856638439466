import React from 'react';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { Donation } from '../../../api/repository/types/payment.type';
import InputField from '../../../components/form/fields/input.field';
import * as yup from 'yup';
import DonationRepository from '../../../api/repository/DonationRepository';

export interface MetadataPaymentModalProps {
  donation: Donation;
  onSuccess?: (donation: Donation) => void;
}

interface MetadataDonation {
  data: string;
}

const MetadataPaymentModal: React.FC<MetadataPaymentModalProps> = ({ donation, onSuccess }) => {
  const data: MetadataDonation = {
    data: donation.data ? JSON.stringify(donation.data) : ''
  };

  const schema = yup.object({
    data: yup.string().test('data', 'Format JSON invalide', (value: any) => {
      try {
        JSON.parse(value);
        return true;
      } catch (error) {
        return false;
      }
    })
  });

  const onSubmit = (values: MetadataDonation) => {
    DonationRepository.patchMetadata(donation.id, JSON.parse(values.data)).then((response) => onSuccess?.(response.data));
  };

  return (
    <Formik initialValues={data} validationSchema={schema} onSubmit={onSubmit} validateOnMount validateOnChange>
      {(formikProps) => {
        const { values, isValid, errors, setFieldValue } = formikProps;
        const { data } = values;

        return (
          <Form onSubmit={formikProps.handleSubmit}>
            <InputField
              errors={errors}
              showError
              name="data"
              defaultValue={data}
              onChange={(event) => {
                setFieldValue('data', event.target.value, true);
              }}
            />
            <Button size="sm" type="submit" disabled={!isValid}>
              Sauvegarder
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MetadataPaymentModal;
