import React from 'react';
import { type BaseOption, type Field, type FieldType } from './form.field';
import { Button, Row } from 'react-bootstrap';
import InputField from '../../components/form/fields/input.field';
import { type FormikProps } from 'formik';
import TranslatableField from '../../components/form/fields/translatable.field';
import SelectField from '../../components/form/fields/select.field';

class FormBuilder<T = any> {
  fields: Record<string, Field> = {};
  debug: boolean = false;
  disable: boolean = false;

  setDisable(disable: boolean) {
    this.disable = disable;
  }

  setDebug(debug: boolean) {
    this.debug = debug;
  }

  add(name: keyof T, type: FieldType, options: BaseOption | any = {}) {
    let field: Partial<Field> = { name: name as string, label: name as string, type };
    switch (type) {
      default:
        field = {
          ...options,
          ...field
        };
    }

    this.fields[name as string] = field as Field;

    return this;
  }

  remove(name: string) {
    delete this.fields[name];
  }

  buildBaseProps(field: Field, formik: FormikProps<any>) {
    const { handleChange, values, errors } = formik;

    return {
      ...field,
      name: field.name,
      errors: errors,
      value: values[field.name],
      onChange: handleChange,
      label: field.label,
      disabled: this.disable
    };
  }

  renderView(formik: FormikProps<any>) {
    const renderFields = Object.keys(this.fields).map((name) => {
      const currentField = this.fields[name];
      let field: any = null;
      switch (currentField.type) {
        case 'text':
        case 'number':
          field = <InputField {...this.buildBaseProps(currentField, formik)} />;
          break;
        case 'translation':
          field = <TranslatableField {...this.buildBaseProps(currentField, formik)} />;
          break;
        case 'select':
          field = <SelectField {...this.buildBaseProps(currentField, formik)} options={currentField.items} />;
      }

      return <Row key={name}>{field}</Row>;
    });

    if (this.debug) {
      renderFields.push(<div key={'debug'}>{JSON.stringify(formik.values)}</div>);
    }

    if (!this.disable) {
      const submitButton = (
        <div className="text-center">
          <Button variant="primary" type="submit" size="lg">
            Valider
          </Button>
        </div>
      );

      renderFields.push(submitButton);
    }

    return renderFields;
  }
}

export default FormBuilder;
