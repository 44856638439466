import React, { useState } from 'react';
import { columns } from './services/columns';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserRepository from '../../../api/repository/UserRepository';
import TablePagination from '../../../components/table/TablePagination';
import UserFilter from './user.filter';

const UserList: React.FC = () => {
  const [filter, setFilter] = useState({});

  return (
    <div className="crud-list">
      <h1>Liste des utilisateurs</h1>
      <div className="action-wrapper position-relative">
        <Link to="/users/new" className="position-absolute" style={{ top: 0, right: 0 }}>
          <Button>Nouveau</Button>
        </Link>
      </div>

      <UserFilter
        onSubmit={(filter) => {
          setFilter(filter);
        }}
      />
      <TablePagination repository={UserRepository} columns={columns} keyField="id" remote={{ sort: true, pagination: true }} getAllQuery={filter} />
    </div>
  );
};

export default UserList;
