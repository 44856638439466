import React from 'react';
import './loading.scss';
import { loadingState } from '../../../state-management/global-store';

const LoadingLayout: React.FC = () => {
  if (!loadingState.value) {
    return null;
  }

  return (
    <div className="loading-wrapper">
      <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingLayout;
