import React, { useState } from 'react';
import { columns } from './services/columns';
import TablePagination from '../../../components/table/TablePagination';
import { type GetContactType } from '../../../api/repository/types/contact.type';
import { Button } from 'react-bootstrap';
import { notify } from '../../../services/notification/notification';
import FaqRepository from '../../../api/repository/FaqRepository';
import { Link } from 'react-router-dom';

const FaqList: React.FC = () => {
  const [selected, setSelected] = useState<GetContactType>();
  const [deletedRows, setDeletedRows] = useState<any[]>([]);

  const deleteContact = () => {
    FaqRepository.delete(selected!.id).then(() => {
      deletedRows.push(selected!.id);
      setDeletedRows([...deletedRows]);
      notify({
        title: 'Faq supprimé avec succès',
        message: 'La question de la FAQ a bien été supprimée'
      });
    });
  };

  return (
    <div className="crud-list">
      <h1>Liste des Faq.</h1>

      <div className="action-wrapper">
        <Button disabled={selected === undefined} onClick={deleteContact}>
          Supprimer
        </Button>

        <Link to={'/faqs/new'}>
          <Button>Nouveau</Button>
        </Link>
      </div>

      <TablePagination
        repository={FaqRepository}
        columns={columns}
        keyField="id"
        rowClasses={(row) => {
          return deletedRows.includes(row.id) ? 'd-none' : '';
        }}
        selectRow={{
          clickToSelect: true,
          mode: 'radio',
          onSelect: (contact, _selected) => {
            setSelected(contact);
          }
        }}
      />
    </div>
  );
};

export default FaqList;
