import ProjectAutocomplete from '../autocomplete/project.autocomplete';
import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Form } from 'react-bootstrap';

interface ProjectSelectFormProps {
  onSubmit: (project: ProjectSelectType) => void;
  validationSchema?: any;
}

interface ProjectSelectType {
  project: string; // project id
}

const ProjectSelectForm: React.FC<ProjectSelectFormProps> = ({ onSubmit, validationSchema }) => {
  const data: ProjectSelectType = {
    project: ''
  };

  const schema = validationSchema
    ? validationSchema
    : yup.object<ProjectSelectType>({
        project: yup.string().required()
      });

  return (
    <Formik initialValues={data} onSubmit={onSubmit} validationSchema={schema} validateOnChange>
      {({ setFieldValue, isValid, handleSubmit }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <ProjectAutocomplete onChange={(project) => setFieldValue('project', project.value)} />

            <div className="text-center">
              <Button size="sm" type="submit" disabled={!isValid}>
                Valider
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProjectSelectForm;
