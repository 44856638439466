import { BaseRepository } from './BaseRepository';

export interface MaintenanceStatus {
  maintenance?: any;
}
class MaintenanceStatusRepository<T = MaintenanceStatus> extends BaseRepository<T> {
  getArea() {
    return '/api/maintenance';
  }

  toggleMaintenance(maintenance: boolean) {
    const url = this.getBaseEndpoint();
    return this.client.post(url, { maintenance });
  }

  getMaintenanceStatus() {
    const url = this.getBaseEndpoint();
    return this.client.get<{ maintenance: boolean }>(url);
  }
}

export default new MaintenanceStatusRepository();
