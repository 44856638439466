import { BaseRepository } from './BaseRepository';
import { Newsletter } from './types/newsletter.type';

class NewsletterRepository<T = Newsletter> extends BaseRepository<T> {
  getArea() {
    return '/api/newsletters';
  }

  export() {
    const url = this.getBaseEndpoint() + '/export.csv';

    return this.client.get(
      url,
      {},
      {
        responseType: 'blob'
      }
    );
  }
}

export default new NewsletterRepository();
