import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { notify } from '../../services/notification/notification';
import TablePagination from '../../components/table/TablePagination';
import { columns } from './services/columns';
import NewsletterRepository from '../../api/repository/NewsletterRepository';
import { Newsletter } from '../../api/repository/types/newsletter.type';

const NewsletterList: React.FC = () => {
  const [selectedNewsletters, setSelectedNewsletters] = useState<Newsletter[]>([]);
  const [deletedRows, setDeletedRows] = useState<any[]>([]);

  const deleteContact = () => {
    selectedNewsletters?.map(async (selected) => {
      await NewsletterRepository.delete(selected!.email).then(() => {
        deletedRows.push(selected!.email);
        setDeletedRows([...deletedRows]);
      });
    });

    notify({
      title: 'Newsletter supprimé',
      message: 'L enregistrement a été supprimé.'
    });
  };

  const exportCSV = () => {
    NewsletterRepository.export().then((response) => {
      const href = URL.createObjectURL(response.data as any);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'export.csv'); //or any other extension
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <div className="crud-list">
      <h1>Liste des inscrits à la newsletter.</h1>

      <div className="action-wrapper mb-3">
        <Button disabled={selectedNewsletters.length === 0} onClick={deleteContact}>
          Supprimer
        </Button>

        <Button className="float-end" variant="success" onClick={exportCSV}>
          Exporter
        </Button>
      </div>

      <TablePagination
        repository={NewsletterRepository}
        columns={columns}
        keyField="email"
        rowClasses={(row) => {
          return deletedRows.includes(row.email) ? 'd-none' : '';
        }}
        selectRow={{
          clickToSelect: true,
          mode: 'checkbox',
          hideSelectAll: true,
          onSelect: (newsletter, isSelected) => {
            if (isSelected) {
              setSelectedNewsletters([...selectedNewsletters, newsletter]);
            } else {
              setSelectedNewsletters([...selectedNewsletters.filter((s) => s.email !== newsletter.email)]);
            }
          }
        }}
      />
    </div>
  );
};

export default NewsletterList;
