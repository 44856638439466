import React, { useState } from 'react';
import { Formik, type FormikConfig } from 'formik';
import InputField from '../../../components/form/fields/input.field';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { type OptionItem } from '../../../components/form/fields/types/option.type';
import { generateBaseProps } from '../../../components/form/fields/services/select.service';
import { type CreateDemandeRecuType } from '../../../api/repository/types/demande_recu.type';
import SelectField from '../../../components/form/fields/select.field';

export interface Props {
  initialDemande?: CreateDemandeRecuType;
  onSubmit: FormikConfig<CreateDemandeRecuType>['onSubmit'];
  validationSchema?: any;
}

const DemandeRecuForm: React.FC<Props> = ({ validationSchema, initialDemande, onSubmit }) => {
  const demande: CreateDemandeRecuType = {
    state: 'new',
    name: '',
    email: '',
    fromDate: '',
    toDate: '',
    ...initialDemande
  };

  const states: OptionItem[] = [
    {
      label: 'Nouveau',
      value: 'new'
    },
    {
      label: 'En attente',
      value: 'pending'
    },
    {
      label: 'Terminé',
      value: 'done'
    }
  ];

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  return (
    <Formik initialValues={demande} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange={submittedOnce}>
      {(formik) => {
        const { values, handleChange, errors } = formik;

        const baseProps = {
          onChange: handleChange,
          errors: errors,
          showError: true
        };

        const multipleProps = generateBaseProps(formik, 'state');

        return (
          <Form
            onSubmit={(e) => {
              setSubmittedOnce(true);
              formik.handleSubmit();
              e.preventDefault();
            }}
            noValidate
          >
            <Container fluid>
              <Row>
                <Col>
                  <InputField placeholder={'Name*'} name="name" value={values.name} {...baseProps} />
                </Col>
                <Col>
                  <InputField placeholder={'Email*'} name="email" value={values.email} {...baseProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField placeholder={'Date début*'} name="fromDate" value={values.fromDate} {...baseProps} />
                </Col>
                <Col>
                  <InputField placeholder={'Date Fin*'} name="toDate" value={values.toDate} {...baseProps} />
                </Col>
              </Row>
              <SelectField label={'Statut'} name="state" options={states} defaultValue={values.state} {...baseProps} />
              <div className="text-center">
                <Button variant="primary" type="submit" size="lg">
                  Valider
                </Button>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DemandeRecuForm;
