import { Form, Formik } from 'formik';
import InputField, { InputFieldProps } from '../form/fields/input.field';
import { Button } from 'react-bootstrap';
import React from 'react';
import * as yup from 'yup';
import DefaultRepository from '../../api/repository/DefaultRepository';

export type BasicEmailType = {
  subject: string;
  mail: string;
  message: string;
};

type BasicKey = keyof BasicEmailType;

export interface BasicEmailProps {
  defaultData?: Partial<BasicEmailType>;
  inputProps?: {
    [A in BasicKey]?: Partial<InputFieldProps>;
  };
  onSend?: () => void;
}

const schema = yup.object<BasicEmailType>({
  subject: yup.string().required(),
  message: yup.string().required(),
  mail: yup.string().email().required()
});

const BasicMail: React.FC<BasicEmailProps> = ({ defaultData, inputProps, onSend }) => {
  const data: BasicEmailType = {
    subject: '',
    mail: '',
    message: '',
    ...defaultData
  };

  const onSubmit = (email: BasicEmailType) => {
    DefaultRepository.sendEmail(email).then(() => {
      onSend?.();
    });
  };

  return (
    <Formik initialValues={data} validationSchema={schema} onSubmit={onSubmit} validateOnBlur>
      {(formikProps) => {
        const { values, isValid, errors, handleChange } = formikProps;

        return (
          <Form onSubmit={formikProps.handleSubmit}>
            <InputField label="Envoyer à" errors={errors} showError name="mail" defaultValue={values.mail} onChange={handleChange} {...inputProps?.mail} />

            <InputField label="Sujet" errors={errors} showError name="subject" defaultValue={values.subject} onChange={handleChange} {...inputProps?.subject} />

            <InputField label="Message" as="textarea" errors={errors} showError name="message" defaultValue={values.message} onChange={handleChange} {...inputProps?.message} />

            <Button size="sm" type="submit" disabled={!isValid}>
              Envoyer
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BasicMail;
