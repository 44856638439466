import React, { useEffect, useState } from 'react';
import { Modal as BaseModal, type ModalProps } from 'react-bootstrap';

interface Props {
  defaultShow?: boolean;
  title: string;
  onClose?: () => void;
  children?: any;
  show?: boolean;
  modalProps?: ModalProps;
}

const Modal: React.FC<Props> = (props) => {
  const { defaultShow = true, onClose, title, children, modalProps } = props;
  const [show, setShow] = useState<boolean>(defaultShow);
  useEffect(() => {
    if (props.show !== undefined) {
      setShow(props.show);
    }
  }, [props.show]);

  return (
    <BaseModal
      {...modalProps}
      show={show}
      onHide={() => {
        onClose?.();
      }}
    >
      <BaseModal.Header closeButton>
        <BaseModal.Title>{title}</BaseModal.Title>
      </BaseModal.Header>
      <BaseModal.Body>{children}</BaseModal.Body>
    </BaseModal>
  );
};

export default Modal;
