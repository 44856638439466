import { type ColumnsType } from '../../../../components/table/types/columns.type';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { type GetFaqType } from '../../../../api/repository/types/faq.type';
import { toggleLoading } from '../../../../state-management/global-store';
import SelectTable from '../../../../components/form/table/select.table';
import FaqRepository from '../../../../api/repository/FaqRepository';
import { FAQStates } from '../../form/faq.form';

export const columns: Array<ColumnsType<GetFaqType>> = [
  {
    dataField: 'title',
    text: 'Titre'
  },
  {
    dataField: 'position',
    text: 'Position',
    sort: true
  },
  {
    dataField: 'page',
    text: 'Page'
  },
  {
    dataField: 'state',
    text: 'Etat',
    formatter: (_cell, entity) => {
      const onChange = (e) => {
        entity.state = e.target.value;
        toggleLoading();
        FaqRepository.editState(entity.id, entity.state).then(() => {
          toggleLoading();
        });
      };

      return <SelectTable onChange={onChange} defaultValue={entity.state} options={FAQStates}></SelectTable>;
    }
  },
  {
    dataField: 'createdAt',
    text: 'Créé le',
    preRender: {
      type: 'date'
    }
  },
  {
    dataField: 'updatedAt',
    text: 'Date dernière modif.',
    preRender: {
      type: 'date'
    }
  },
  {
    dataField: 'actions',
    text: 'Actions',
    isDummyField: true,
    formatter: (_cell, faq) => {
      return (
        <Link to={`/faqs/${faq.id}/edit`}>
          <FontAwesomeIcon icon={faEdit} />
        </Link>
      );
    }
  }
];
