import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { type AssetProps, AssetProvider } from './services/CreateAsset';
import { type LocaleType } from '../../../api/repository/types/transverse.type';
import { type Asset } from '../../../api/repository/types/project.type';

import './styles/asset.scss';

export interface AssetViewProps {
  asset: Asset;
  onCreate: AssetProps['onSubmit'];
  onDelete?: (asset: Asset) => void;
  locale: LocaleType;
}

const AssetView: React.FC<AssetViewProps> = ({ locale, asset, onCreate, onDelete }) => {
  const image = typeof asset?.image === 'string' ? asset.image : asset?.image?.url;

  return (
    <Card className="asset-card my-2">
      <Card.Img variant="top" src={image} />
      <div className={'actions'}>
        <i>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => {
              AssetProvider.create({
                locale: locale,
                asset: asset,
                onSubmit: onCreate
              });
            }}
          />
        </i>
        <i>
          <FontAwesomeIcon icon={faTimesCircle} onClick={() => onDelete?.(asset)} />
        </i>
      </div>
      <Card.Body>
        <Card.Title className="bg-primary c-white" as="div" dangerouslySetInnerHTML={{ __html: asset.title }}></Card.Title>
        <Card.Text>Video Url: {asset.videoUrl}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default AssetView;
