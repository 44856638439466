import { type LoginType } from '../pages/login/types/login.type';
import { HttpClient } from './HttpClient';

export class AuthRepository {
  static async login(login: LoginType) {
    const url = process.env.REACT_APP_API_BASE_URL + '/api/login_check';

    return await new HttpClient().post<{ token: string }>(url, login);
  }
}
