import { HttpClient } from '../HttpClient';
import { Paginator } from './types/paginator.type';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export abstract class BaseRepository<BaseType> {
  protected client: HttpClient;
  public baseUrl: string;

  constructor() {
    this.client = new HttpClient();
    this.baseUrl = process.env.REACT_APP_API_BASE_URL as string;
  }

  /**
   * return /admin |/...
   */
  abstract getArea(): string;

  getBaseEndpoint() {
    return this.baseUrl + this.getArea();
  }

  async get<T = BaseType>(id: any): Promise<AxiosResponse<T>> {
    const url = this.getBaseEndpoint() + '/' + id;

    return await this.client.get<T>(url);
  }

  async getByName<T = BaseType>(name: any): Promise<AxiosResponse<T>> {
    const url = this.getBaseEndpoint() + '/getByName/' + name;

    return await this.client.get<T>(url);
  }

  async getAll<A = BaseType>(filters: any = {}, otherConfig: Partial<AxiosRequestConfig> = {}) {
    const url = this.getBaseEndpoint() + '/';

    return await this.client.get<Paginator<A>>(url, filters, otherConfig);
  }

  async new<T = BaseType>(type: BaseType | any) {
    const url = this.getBaseEndpoint() + '/';

    return await this.client.post<T>(url, type);
  }

  async edit<T = BaseType>(id: any, type: T | any) {
    const url = this.getBaseEndpoint() + '/' + id;

    return await this.client.put<T>(url, type);
  }

  async delete<T = BaseType>(id: any, type?: T | any) {
    const url = this.getBaseEndpoint() + '/' + id;
    return await this.client.delete<T>(url, type);
  }
}
