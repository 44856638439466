import React from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Login from '../pages/login/Login';
import { menu, type Route as RouteType, routes } from './route/route';
import BaseLayout from './layout/base/Base.layout';
import RequireAuth from '../pages/RequireAuth';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';

const App: React.FC = () => {
  const allPages: RouteType[] = routes.concat(menu);

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  return (
    <div>
      <main className="main-content">
        {
          <Routes>
            {allPages.map((navigation) => {
              const { route, page } = navigation;

              return (
                <Route
                  key={route}
                  path={route}
                  element={
                    <RequireAuth route={navigation}>
                      <BaseLayout>{page}</BaseLayout>
                    </RequireAuth>
                  }
                />
              );
            })}

            <Route path="/login" element={<Login />} />
            <Route path="/">Page not found...</Route>
          </Routes>
        }
      </main>
    </div>
  );
};

export default App;
