import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop, { type Crop, type ReactCropProps } from 'react-image-crop';
import { type FieldTypeDefaultValue, type OnChange } from './types/base.field.type';
import { Alert } from 'react-bootstrap';
import 'react-image-crop/dist/ReactCrop.css';

export type CropImageProps = FieldTypeDefaultValue & {
  cropProps?: Partial<Crop>;
  reactCropProps?: Partial<ReactCropProps>;
  showPreview?: boolean;
  onChange: OnChange;
};

const ImageField: React.FC<CropImageProps> = (props) => {
  const { onChange, name, defaultValue = '', cropProps, showPreview, reactCropProps, showError, errors } = props;

  const [upImg, setUpImg] = useState<string>(defaultValue);
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop | any>(cropProps || undefined);
  const [previewUrl, setPreviewUrl] = useState<string>(defaultValue);

  useEffect(() => {
    setPreviewUrl(defaultValue);
    !upImg && setUpImg(defaultValue);
  }, [defaultValue]);

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setUpImg(reader.result as string);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const onLoad: React.ReactEventHandler<HTMLImageElement> = useCallback((e) => {
    imgRef.current = e.currentTarget as HTMLImageElement;
  }, []);

  const makeClientCrop = async (crop: Crop) => {
    if (imgRef.current && crop.width && crop.height) {
      createCropPreview(imgRef.current, crop, 'file.jpeg');
    }
  };

  const createCropPreview = async (image: HTMLImageElement, crop: Crop, fileName: string) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = crop.width!;
    canvas.height = crop.height!;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    const promise = new Promise((_resolve, reject) => {
      onChange(name, canvas.toDataURL('image/jpeg'));

      showPreview &&
        canvas.toBlob((blob: any) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(previewUrl);

          setPreviewUrl(window.URL.createObjectURL(blob));
        }, 'image/jpeg');
    });

    return await promise;
  };

  return (
    <div className="crop-field">
      <div className={'crop-button-wrapper'}>
        <input accept="image/*" type="file" onChange={onSelectFile} id="raised-button-file" />
      </div>

      <div className="crop-item-wrapper">
        <ReactCrop
          crop={{ x: 0, y: 0, unit: 'px', ...crop }}
          onChange={(c) => {
            setCrop(c);
          }}
          onComplete={makeClientCrop}
          {...reactCropProps}
        >
          <img src={upImg} onLoad={onLoad} />
        </ReactCrop>
      </div>

      {showPreview && previewUrl && (
        <div className="preview-wrapper">
          <img alt="Crop preview" src={previewUrl} />
        </div>
      )}

      {showError && errors?.[name] && <Alert variant="danger">{`${errors[name]}`}</Alert>}
    </div>
  );
};

export default ImageField;
