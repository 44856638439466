import React, { useEffect, useState } from 'react';
import TablePagination from '../../components/table/TablePagination';
import { columns } from './services/columns';
import PaymentRepository from '../../api/repository/PaymentRepository';
import { type Sort } from '../../api/repository/types/paginator.type';
import { SHOW_PAYMENT_STATUS, ALL_PAYMENT_STATUS } from '../../api/repository/types/payment.type';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PaymentFilter from './form/payment-filter';

interface Filter {
  emailPayments?: string;
  status?: string[];
}

const PaymentList: React.FC = () => {
  const [showAllPayment, setShowAllPayment] = useState<boolean>(false);
  const [filter, setFilter] = useState<Filter>({});

  useEffect(() => {
    setFilter(filter);
  }, [showAllPayment, filter]);

  filter.status = showAllPayment ? ALL_PAYMENT_STATUS : SHOW_PAYMENT_STATUS;

  const query: Partial<Sort> & { filter: Filter } = {
    sortField: 'createdAt',
    sortOrder: 'desc',
    filter: filter
  };

  return (
    <div className="crud-list">
      <h1>Liste des paiements.</h1>

      <div className="d-flex">
        <Link to="/payments/new">
          <Button>Nouveau paiement</Button>
        </Link>
      </div>

      <div className="d-flex my-2 justify-content-end">
        <Button
          onClick={() => {
            setShowAllPayment(!showAllPayment);
          }}
        >
          {showAllPayment ? 'Montrer les paiements non finalisés' : 'Montrer les paiements finalisés'}.
        </Button>
      </div>

      <PaymentFilter
        onSubmit={(filter) => {
          setFilter(filter);
        }}
      />

      <TablePagination defaultSort={{ sortField: 'createdAt', sortOrder: 'desc' }} repository={PaymentRepository} getAllQuery={query} columns={columns} keyField="id" remote={{ sort: true, pagination: true }} />
    </div>
  );
};

export default PaymentList;
