import React from 'react';
import { Form } from 'react-bootstrap';
import { type BaseFieldType, type HandleChange } from './types/base.field.type';
import Label from './transverse/label';
import Error from './transverse/error';
import { type OptionItem } from './types/option.type';
import Helper from './transverse/helper';

type Props = BaseFieldType & {
  options: OptionItem[];
  emptyLabel?: string;
  onChange: HandleChange;
};

const SelectField: React.FC<Props> = ({ formGroupProps, label, ...inputProps }) => {
  const { options, name, showError, onChange, errors, emptyLabel, helper, ...restFieldProps } = inputProps;

  const valueProps = {
    value: restFieldProps.value,
    defaultValue: restFieldProps.defaultValue
  };

  return (
    <Form.Group className="mb-3" {...formGroupProps}>
      <Label label={label} />
      <Form.Control as="select" {...restFieldProps} name={name} onChange={onChange} {...valueProps} isInvalid={showError && undefined !== errors?.[name]}>
        {emptyLabel ? <option value="">{emptyLabel}</option> : null}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Form.Control>
      <Error error={errors?.[name]} showError={showError} />
      <Helper text={helper} />
    </Form.Group>
  );
};

export default SelectField;
