import { BaseRepository } from './BaseRepository';
import { type CreateProject, type GetListProject, type GetOneProject } from './types/project.type';
import { type AxiosRequestConfig } from 'axios';

class ProjectRepository<T = CreateProject> extends BaseRepository<T> {
  getArea() {
    return '/api/projects';
  }

  get<A = GetOneProject>(id: any) {
    return super.get<A>(id);
  }

  getByName<A = GetOneProject>(name: any) {
    return super.getByName<A>(name);
  }

  getAll<A = GetListProject>(filters: any = {}, otherConfig: Partial<AxiosRequestConfig> = {}) {
    return super.getAll<A>(filters, otherConfig);
  }

  getLastProjectReached(limit = 10) {
    const filters = {
      sortField: 'goalReachedAt',
      sortOrder: 'desc',
      isReached: true,
      itemPerPage: limit
    };

    return this.getAll<GetListProject>(filters);
  }

  duplicate<A = GetOneProject>(id: any) {
    const url = this.getBaseEndpoint() + `/duplicate/${id}`;

    return this.client.post<A>(url, {});
  }

  editTranslation(projectId: string, values: any) {
    const url = this.getBaseEndpoint() + `/${projectId}/translations/${values.locale}`;

    return this.client.put(url, values);
  }

  // attach un asset a un projet.
  attachAsset(projectId: string, locale: string, assetId: string, attach: 'attach' | 'detach' = 'attach') {
    const url = this.getBaseEndpoint() + `/${projectId}/translation/${locale}/assets/${attach}`;

    return this.client.post(url, {
      assetId: assetId
    });
  }

  // attach une image  a un projet.
  attachImageAsset(projectId: string, locale: string, assetId: string, attach: 'attach' | 'detach' = 'attach') {
    const url = this.getBaseEndpoint() + `/${projectId}/translation/${locale}/imageAssets/${attach}`;

    return this.client.post(url, {
      assetId: assetId
    });
  }

  // attach un asset a un projet.
  attachProduct(projectId: string, locale: string, productId: string, attach: 'attach' | 'detach' = 'attach') {
    const url = this.getBaseEndpoint() + `/${projectId}/translation/${locale}/products/${attach}`;

    return this.client.post(url, {
      productId: productId
    });
  }

  sendMediaNotification(id: string) {
    const url = this.getBaseEndpoint() + `/${id}/send-media-notification`;

    return this.client.post(url, {});
  }

  unpublish(id: string) {
    const url = this.getBaseEndpoint() + `/${id}/unpublish`;

    return this.client.post(url, {});
  }

  /**
   * Set project.visibleOn empty.
   *
   * @param id
   */
  invisible(id: string) {
    const url = this.getBaseEndpoint() + `/${id}/invisible`;

    return this.client.post(url, {});
  }
}

const projectRepository = new ProjectRepository();

export default projectRepository;
