import React from 'react';
import { Form } from 'react-bootstrap';
import { type HandleChange } from '../fields/types/base.field.type';
import { type OptionItem } from '../fields/types/option.type';

interface Props extends React.HTMLProps<HTMLSelectElement> {
  value?: any;
  defaultValue?: any;
  options: OptionItem[];
  emptyLabel?: string;
  onChange: HandleChange;
}

const SelectTable: React.FC<Props> = (inputProps: any) => {
  const { options, onChange, emptyLabel, ...restFieldProps } = inputProps;

  const valueProps = {
    value: restFieldProps.value,
    defaultValue: restFieldProps.defaultValue
  };

  return (
    <Form.Control as="select" {...restFieldProps} {...valueProps} onChange={onChange}>
      {emptyLabel ? <option value="">{emptyLabel}</option> : null}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Control>
  );
};

export default SelectTable;
