import React, { useState } from 'react';
import { Formik, type FormikConfig } from 'formik';
import InputField from '../../../components/form/fields/input.field';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { type OptionItem } from '../../../components/form/fields/types/option.type';
import SelectField from '../../../components/form/fields/select.field';
import { type CreateFaqType } from '../../../api/repository/types/faq.type';
import RichTextField from '../../../components/form/fields/richtext.field';

export interface Props {
  initialValue?: CreateFaqType;
  onSubmit: FormikConfig<CreateFaqType>['onSubmit'];
  validationSchema?: any;
}

export const FAQStates: OptionItem[] = [
  {
    label: 'Publié',
    value: 'published'
  },
  {
    label: 'Non publié',
    value: 'unpublished'
  }
];

const FaqForm: React.FC<Props> = ({ validationSchema, initialValue, onSubmit }) => {
  const contact: CreateFaqType = {
    description: '',
    title: '',
    locale: 'fr',
    position: 1,
    state: 'published',
    page: 'faq',
    ...initialValue
  };

  const locales: OptionItem[] = [
    {
      label: 'Français',
      value: 'fr'
    },
    {
      label: 'Anglais',
      value: 'en'
    }
  ];

  const pages: OptionItem[] = [
    {
      label: 'FAQ',
      value: 'faq'
    },
    {
      label: 'Contact',
      value: 'contact'
    },
    {
      label: 'Création de collect',
      value: 'create_collect'
    }
  ];

  const [submittedOnce, setSubmittedOnce] = useState<boolean>(false);

  return (
    <Formik initialValues={contact} onSubmit={onSubmit} validationSchema={validationSchema} validateOnChange validateOnMount>
      {(formik) => {
        const { values, handleChange, errors, isValid, setFieldValue } = formik;
        const { state, position, description, title, locale, page } = values;

        const baseProps = {
          onChange: handleChange,
          errors: errors,
          showError: true
        };

        const richProps = {
          onChange: async (name: string, value: any) => await setFieldValue(name, value),
          errors: errors
        };

        return (
          <Form
            onSubmit={(e) => {
              setSubmittedOnce(true);
              formik.handleSubmit();
              e.preventDefault();
            }}
            noValidate
          >
            <Container fluid>
              <Row>
                <Col>
                  <RichTextField
                    label="Titre*"
                    onBlur={(name, value) => {
                      const striped = value.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '');
                      debugger;
                      setFieldValue('title', striped);
                    }}
                    name={'title'}
                    defaultValue={title}
                    config={{ toolbar: ['bold'] }}
                    onChange={() => {}}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <RichTextField label="Description*" name={'description'} defaultValue={description} {...richProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputField type="number" label="Position" name={'position'} value={position} {...baseProps} />
                </Col>

                <Col>
                  <SelectField label={'Langue'} name="locale" options={locales} defaultValue={locale} {...baseProps} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectField label={'Etat*'} name="state" options={FAQStates} defaultValue={state} {...baseProps} helper={"Seul l'état publié sera visible pour l'utilistaeur"} />
                </Col>
              </Row>

              <Row>
                <Col>
                  <SelectField label={'Page*'} name="page" options={pages} defaultValue={page} {...baseProps} helper={'Visible sur quelle page'} />
                </Col>
              </Row>

              <div className="text-center">
                <Button disabled={!isValid} variant="primary" type="submit" size="lg">
                  Valider
                </Button>

                <Button
                  className="ml-2"
                  disabled={!isValid}
                  onClick={() => {
                    formik.handleSubmit();
                    setTimeout(() => {
                      window.location.replace('/faqs/new');
                    }, 2000);
                  }}
                  variant="primary"
                  type="button"
                  size="lg"
                >
                  Valider et nouveau
                </Button>
              </div>
            </Container>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FaqForm;
