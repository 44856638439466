import React from 'react';
import { Form, Formik, type FormikConfig } from 'formik';
import { Button } from 'react-bootstrap';
import { type GetOnePayment, PAYMENT_STATUS } from '../../../api/repository/types/payment.type';
import { type OptionItem } from '../../../components/form/fields/types/option.type';
import SelectField from '../../../components/form/fields/select.field';

interface Props {
  onSubmit: FormikConfig<PaymentStatusFormValue>['onSubmit'];
  payment: GetOnePayment;
}

export interface PaymentStatusFormValue {
  status: PAYMENT_STATUS;
}

const PaymentStatusForm: React.FC<Props> = ({ onSubmit, payment }) => {
  const initialValue: PaymentStatusFormValue = {
    status: payment.status
  };

  const paymentStatus: Array<OptionItem<PAYMENT_STATUS>> = [
    {
      label: 'Paiement mensuel terminé (fin du paiement recurrent)',
      value: PAYMENT_STATUS.ABORTED_STATUS
    },
    {
      label: 'Paiement mensuel actif (en cours)',
      value: PAYMENT_STATUS.ACTIVE_STATUS
    },
    {
      label: 'Paiement validé (ponctuel)',
      value: PAYMENT_STATUS.VALIDATED_STATUS
    },
    {
      label: 'Paiement remboursé',
      value: PAYMENT_STATUS.REFUNDED_STATUS
    },
    {
      label: 'Paiement créé mais non finalisé (par retour de stripe / paypal)',
      value: PAYMENT_STATUS.CREATED_STATUS
    },
    {
      label: "Paiement annulé par l'utilisateur",
      value: PAYMENT_STATUS.CANCELLED_STATUS
    }
  ];

  return (
    <Formik initialValues={initialValue} onSubmit={onSubmit}>
      {(formik) => {
        const { values, handleChange } = formik;
        const { status } = values;
        return (
          <Form onSubmit={formik.handleSubmit}>
            <div className="text-center">
              <SelectField helper="Seul les status 'validé' et 'actif' sont comptabilisé dans le total" label="Status du paiement" name="status" defaultValue={status} options={paymentStatus} onChange={handleChange} />
              <Button type="submit">Modifier</Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PaymentStatusForm;
