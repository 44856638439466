import { type LocaleType } from '../../api/repository/types/transverse.type';

export const locales: Array<{ locale: LocaleType; title: string }> = [
  {
    locale: 'fr',
    title: 'Français'
  },
  {
    locale: 'en',
    title: 'Anglais'
  }
];
