import React from 'react';
import { Form } from 'react-bootstrap';
import { type BaseFieldType, type HandleChange } from './types/base.field.type';
import Label from './transverse/label';
import Error from './transverse/error';
import Helper from './transverse/helper';

export type InputFieldProps = BaseFieldType & {
  type?: string;
  as?: 'input' | 'textarea';
  onChange: HandleChange;
};

const InputField: React.FC<InputFieldProps> = React.memo(
  ({ formGroupProps, label, ...inputProps }) => {
    const { type = 'text', as = 'input', showError, name, onChange, errors, helper, ...restFieldProps } = inputProps;

    const valueProps = {
      value: restFieldProps.value,
      defaultValue: restFieldProps.defaultValue
    };

    return (
      <Form.Group className="mb-3" {...formGroupProps}>
        <Label label={label} />
        <Form.Control as={as} {...restFieldProps} type={type} name={name} onChange={onChange} isInvalid={showError && undefined !== errors?.[name]} {...valueProps} />
        {errors ? <Error error={errors[name]} showError={showError} /> : null}

        <Helper text={helper} />
      </Form.Group>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.errors?.[prevProps.name] === nextProps.errors?.[nextProps.name] && prevProps.value === nextProps.value;
  }
);

export default InputField;
