import type { GetRequestProjectType } from '../../api/repository/types/request-project.type';

export const getProjectName = (requestProject: GetRequestProjectType): string => {
  if (!requestProject.project) {
    return '';
  }

  const translation = requestProject.project.translations.find((translation) => translation.locale === 'fr') || requestProject.project.translations.find(() => true);

  return translation ? translation.title : requestProject.project.name;
};
