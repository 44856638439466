import { type OptionItem } from '../../../components/form/fields/types/option.type';
import { type ProjectTemplate } from '../../../api/repository/types/project.type';

export const states: OptionItem[] = [
  {
    value: 'draft',
    label: 'brouillon'
  },
  {
    value: 'unpublished',
    label: 'Non publié'
  },
  {
    value: 'published',
    label: 'publié'
  }
];

export const closeReasons: OptionItem[] = [
  {
    label: 'Fermé parce que objectif atteint',
    value: 'goal_done'
  },
  {
    label: 'Fermeture administrative',
    value: 'admin_close'
  }
];

export const visibleOnOptions: OptionItem[] = [
  {
    label: 'Accueil - Section action',
    value: 'home_action'
  },
  {
    label: 'Accueil - Section collectes phares',
    value: 'home_highlight_collects'
  },
  {
    label: 'Accueil - Section collection',
    value: 'home_collect'
  },
  {
    label: 'Menu action',
    value: 'menu_action'
  },
  {
    label: 'Page liste des collectes',
    value: 'collect_page'
  },
  {
    label: 'Formulaire de paiement rapide',
    value: 'fastpayment_form'
  }
];

export const templates: Array<OptionItem<ProjectTemplate>> = [
  {
    label: 'Une colonne',
    value: 'one_column'
  },
  {
    label: 'Deux colonnes',
    value: 'two_column'
  }
];
