import { BaseRepository } from './BaseRepository';
import { type AdminType } from './types/admin.type';

export class AdminRepository<T = AdminType> extends BaseRepository<T> {
  getArea() {
    return '/api/admins';
  }

  getMe() {
    const url = this.getBaseEndpoint() + '/me';

    return this.client.get<T>(url);
  }
}

export default new AdminRepository();
