import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import AssetView, { type AssetViewProps } from './asset.view';
import { type Asset, type GetOneProject, type VideoAsset } from '../../../api/repository/types/project.type';
import { type LocaleType } from '../../../api/repository/types/transverse.type';
import { AssetProvider } from './services/CreateAsset';
import { notify } from '../../../services/notification/notification';
import AssetRepository from '../../../api/repository/AssetRepository';

interface Props {
  assets?: Asset[];
  onEdit?: AssetViewProps['onCreate'];
  onCreate?: (asset: VideoAsset, formikHelpers: any, assets: Asset[]) => void;
  onDelete?: AssetViewProps['onDelete'];
  canCreate?: boolean;
  locale: LocaleType;
  project: GetOneProject;
}

const AssetList: React.FC<Props> = (props) => {
  const { canCreate = true, onDelete, onEdit, onCreate, locale, project } = props;
  const [assets, setAssets] = useState<Asset[]>(props.assets ? props.assets : []);

  return (
    <Container>
      <div className="clearfix my-3">
        <Button
          className={'float-right'}
          onClick={() => {
            AssetProvider.create({
              locale: locale,
              onSubmit: (asset, helper) => {
                assets.push(asset);
                setAssets([...assets]);
                onCreate?.(asset, helper, assets);
              }
            });
          }}
          disabled={!canCreate}
        >
          Ajouter un asset
        </Button>

        <Button
          className={'float-right mx-2'}
          onClick={() => {
            AssetProvider.duplicate({
              locale: locale,
              onSubmit: (asset, helper) => {
                AssetRepository.duplicate(asset.asset, project.id).then(({ data }) => {
                  notify({
                    title: 'Dupliquer avec succès',
                    message: 'Veuillez recharger la page pour voir les assets dupliqués'
                  });
                });
              }
            });
          }}
          disabled={!canCreate}
        >
          Dupliquer un asset existant
        </Button>
      </div>

      <Row>
        {assets
          .filter((asset) => asset.locale === locale)
          .map((asset, number) => {
            if (!asset.visibility || (asset.visibility && asset.visibility == 'public'))
              return (
                <Col xs={12} sm={3} md={4} key={asset.title}>
                  <AssetView
                    locale={locale}
                    asset={asset}
                    onCreate={(asset, formikHelper) => {
                      assets[number] = asset;
                      setAssets([...assets]);
                      onEdit?.(asset, formikHelper);
                    }}
                    onDelete={(asset) => {
                      assets.splice(number, 1);
                      onDelete?.(asset);
                      setAssets([...assets]);
                    }}
                  />
                </Col>
              );
          })}
      </Row>
    </Container>
  );
};

export default AssetList;
