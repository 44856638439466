import React, { useEffect, useState } from 'react';
import { type FormikConfig } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { notify } from '../../../services/notification/notification';
import { type CreateContactType } from '../../../api/repository/types/contact.type';
import ContactRepository from '../../../api/repository/ContactRepository';
import { pick } from 'underscore';
import ContactForm from '../form/contact.form';

export interface Props {
  id?: string;
}

const ContactEdit: React.FC<Props> = () => {
  const [contact, setContact] = useState<CreateContactType>();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    ContactRepository.get(id).then((response) => {
      setContact(response.data);
    });
  }, [id]);

  const onSubmit: FormikConfig<CreateContactType>['onSubmit'] = (contact: CreateContactType) => {
    const edit = pick(contact, ['name', 'email', 'subject', 'message', 'phone', 'state']);

    ContactRepository.edit(id, edit).then(() => {
      notify({
        title: 'Edition effectuée.',
        message: 'La prise de contact a été modifiée'
      });
    });
  };

  const schema = yup.object<Partial<CreateContactType>>({
    email: yup.string().email().required()
  });

  if (!contact) {
    return <>Chargement...</>;
  }

  return <ContactForm initialContact={contact} onSubmit={onSubmit} validationSchema={schema} />;
};

export default ContactEdit;
