import React from 'react';
import { Form } from 'react-bootstrap';
import { type FieldTypeDefaultValue } from './types/base.field.type';
import Label from './transverse/label';
import Error from './transverse/error';
import Helper from './transverse/helper';
import { type ActionMeta } from 'react-select';
import AsyncSelect, { type AsyncProps } from 'react-select/async';

export type AutocompleteProps = FieldTypeDefaultValue & {
  onChange: (value: any, action: ActionMeta<any>) => void;
  isMulti?: boolean;
  loadOptions: AsyncProps<any, any, any>['loadOptions'] | any;
};

const AutocompleteField: React.FC<AutocompleteProps> = ({ formGroupProps, label, ...inputProps }) => {
  const { name, showError, onChange, defaultValue, errors, helper, isMulti = false, loadOptions, placeholder } = inputProps;

  return (
    <Form.Group className="mb-3" {...formGroupProps}>
      <Label label={label} />
      <AsyncSelect placeholder={placeholder} isMulti={isMulti} loadOptions={loadOptions} defaultValue={defaultValue} onChange={onChange} />
      <Error error={errors?.[name]} showError={showError} />
      <Helper text={helper} />
    </Form.Group>
  );
};

export default AutocompleteField;
