import React from 'react';
import { Formik, type FormikConfig } from 'formik';
import { type LoginType } from './types/login.type';
import InputField from '../../components/form/fields/input.field';
import { Button, Form } from 'react-bootstrap';
import { AuthRepository } from '../../api/AuthRepository';
import { AdminRepository } from '../../api/repository/AdminRepository';
import { toggleLoading, userState } from '../../state-management/global-store';
import { Auth } from '../../services/Auth';
import { notify } from '../../services/notification/notification';
import * as yup from 'yup';
import './Login.scss';

const Login: React.FC = () => {
  const login: LoginType = {
    password: '',
    username: ''
  };
  const onSubmit: FormikConfig<LoginType>['onSubmit'] = (login) => {
    toggleLoading();
    AuthRepository.login(login)
      .then(({ data: { token } }) => {
        new Auth().setToken(token);
        notify({
          title: 'Connexion réussie',
          message: 'Redirection en cours',
          container: 'center'
        });
        setTimeout(() => {
          new AdminRepository().getMe().then(({ data }) => {
            userState.value = data;
            new Auth().setUser(data);
            window.location.href = '/';
          });
        }, 1);
      })
      .catch(() => {
        notify({
          title: 'Login ou mot de passe incorrect',
          message: 'Veuillez réessayer',
          type: 'danger'
        });
      })
      .finally(toggleLoading);
  };

  const schema = yup.object<Partial<LoginType>>({
    username: yup.string().email().required(),
    password: yup.string().required()
  });

  return (
    <div className="login-page">
      <Formik initialValues={login} onSubmit={onSubmit} validationSchema={schema} validateOnMount>
        {(formik) => {
          const baseProps = {
            onChange: formik.handleChange,
            errors: formik.errors
          };

          const { password, username } = formik.values;

          return (
            <Form className="form-wrapper text-center" onSubmit={formik.handleSubmit}>
              <img src={'/logo.png'} alt={'logo'} className="mb-3" />
              <InputField placeholder="Email" type="email" name={'username'} value={username} {...baseProps} />
              <InputField placeholder="Password" type="password" name={'password'} value={password} {...baseProps} />
              <div className="d-grid">
                <Button type="submit" variant="primary" disabled={!formik.isValid}>
                  Connexion
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default Login;
