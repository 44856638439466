import React, { useEffect, useState } from 'react';
import { type FormikConfig } from 'formik';
import { type UserCreateType, type UserEditType } from '../types/user.create.type';
import * as yup from 'yup';
import UserForm from '../user-form/user.form';
import UserRepository from '../../../api/repository/UserRepository';
import { type UserGet } from '../../../api/repository/types/user.type';
import { useParams } from 'react-router-dom';
import { notify } from '../../../services/notification/notification';
export interface Props {
  onEdit?: (user: UserGet) => void;
}
const UserEdit: React.FC<Props> = ({ onEdit }) => {
  const [user, setUser] = useState<UserGet>();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    UserRepository.get(id).then((response) => {
      setUser(response.data);
    });
  }, [id]);

  const onSubmit: FormikConfig<UserCreateType>['onSubmit'] = (user) => {
    const { firstName, lastName, email, isActive, enterprise, language, phone } = user;
    const edit: UserEditType = {
      firstName,
      lastName,
      email,
      isActive,
      address: user.address,
      enterprise,
      language,
      phone
    };

    UserRepository.edit(id, edit)
      .then((response) => {
        notify({
          title: "L'utilisateur a été modifié",
          message: `${user.firstName} vient d'être créé`
        });
        onEdit?.(response.data);
      })
      .catch((reason) => {
        notify({
          title: 'Erreur lors de la création',
          message: 'Une erreur est survenue',
          type: 'danger'
        });
      });
  };

  const schema = yup.object<Partial<UserCreateType>>({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    enterprise: yup.string().notRequired()
  });

  if (!user) {
    return <>Chargement...</>;
  }

  return <UserForm onSubmit={onSubmit} validationSchema={schema} initialUser={user} removeFields={{ password: true }} />;
};

export default UserEdit;
