import { type OptionItem } from '../types/option.type';
import { type FormikProps } from 'formik';

/**
 *
 */
export const generateBaseProps = (formik: FormikProps<any>, fieldName: string) => {
  const { setFieldValue } = formik;

  return {
    onChange: (values: OptionItem[] | null) => {
      if (values) {
        setFieldValue(
          fieldName,
          values.map((val) => val.value)
        );
      } else {
        setFieldValue(fieldName, []);
      }
    }
  };
};

/**
 * Not set options if u want same label / value.
 *
 * @param selectedValues
 * @param options
 */
export const generateOptionsItemSelected = (selectedValues: string[], options?: OptionItem[]): OptionItem[] => {
  if (!selectedValues) {
    return [];
  }

  if (options) {
    return options.filter((option) => selectedValues.includes(option.value));
  } else {
    return selectedValues.map((v) => ({ value: v, label: v }));
  }
};
