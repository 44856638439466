import React, { useMemo, useState } from 'react';
import DonationsTablePagination from '../../components/table/DonationsTablePagination';
import { columns as columnDonations } from './services/project.donations.columns';
import { SHOW_PAYMENT_STATUS } from '../../api/repository/types/payment.type';
import DonationRepository from '../../api/repository/DonationRepository';
import { TransfertModalProvider } from '../payments/modal/Transfert';
import { transfertDonation } from '../donations/services/manager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { type GetOneProject } from '../../api/repository/types/project.type';
import UserDonationFilter from './form/project.donation.filter';

interface Filter {
  paymentStatus?: string[];
  project?: string;
  user?: string;
}
interface Props {
  project: GetOneProject;
}
const ProjectDonationList: React.FC<Props> = ({ project }) => {
  const baseFilter = {
    paymentStatus: SHOW_PAYMENT_STATUS,
    project: project.id
  };

  const [filterUser, setFilterUser] = useState<any>({});
  `
`;
  const query: Filter = { ...baseFilter, ...filterUser };

  const columns = useMemo(() => {
    const col = columnDonations;
    col.push({
      isDummyField: true,
      text: 'Actions',
      dataField: 'actions',
      formatter: (v, donation) => (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            TransfertModalProvider.create({
              onSubmit: (data) => {
                transfertDonation(donation.id, data);
              }
            });
          }}
        >
          <FontAwesomeIcon icon={faShareSquare} />
        </a>
      )
    });

    return col;
  }, []);

  return (
    <div className="crud-list">
      <h1>Liste des dons.</h1>

      <UserDonationFilter onSubmit={setFilterUser} />
      <DonationsTablePagination getAllQuery={query} columns={columns} keyField="id" itemPerPage={20} />
    </div>
  );
};

export default ProjectDonationList;
