import React from 'react';
import ReactDOM from 'react-dom';
import CreateAsset from '../create.asset';
import { type Asset, type VideoAsset } from '../../../../api/repository/types/project.type';
import { type FormikConfig } from 'formik';
import Modal from '../../../../components/modal/modal';
import { type LocaleType } from '../../../../api/repository/types/transverse.type';
import AssetDuplicate, { type AssetDuplicateValue } from '../asset.duplicate';

export interface AssetProps {
  asset?: Asset;
  onSubmit?: FormikConfig<VideoAsset>['onSubmit'];
  locale: LocaleType;
}

export interface DuplicateAssetProps {
  asset?: Asset;
  onSubmit?: FormikConfig<AssetDuplicateValue>['onSubmit'];
  locale: LocaleType;
}

export const AssetProvider = {
  create: (props: AssetProps) => {
    const container = document.createElement('AddPopup');
    document.body.prepend(container);
    const options = {
      onClose: () => {
        container.remove();
        ReactDOM.unmountComponentAtNode(container);
      }
    };

    ReactDOM.render(
      <Modal modalProps={{ size: 'lg' }} title={props.asset ? 'Editer un asset' : 'Créer un asset'} {...options}>
        <CreateAsset
          locale={props.locale}
          asset={props.asset}
          onSubmit={(asset, formik) => {
            props.onSubmit?.(asset, formik);
            options.onClose();
          }}
        />
      </Modal>,
      container
    );
  },

  duplicate: (props: DuplicateAssetProps) => {
    const { onSubmit } = props;
    const container = document.createElement('DuplicatePopup');
    document.body.prepend(container);
    const options = {
      onClose: () => {
        container.remove();
        ReactDOM.unmountComponentAtNode(container);
      }
    };

    ReactDOM.render(
      <Modal modalProps={{ size: 'lg' }} title={'Dupliquer un produit pour ce projet'} {...options}>
        <AssetDuplicate
          locale={props.locale}
          onSubmit={(asset, formik) => {
            onSubmit?.(asset, formik);
          }}
        />
      </Modal>,
      container
    );
  }
};
