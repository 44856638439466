import React from 'react';
import { type VideoAsset } from '../../../api/repository/types/project.type';
import { Formik, type FormikConfig } from 'formik';
import InputField from '../../../components/form/fields/input.field';
import ImageField from '../../../components/form/fields/image.field';
import { Button, Form } from 'react-bootstrap';
import { type LocaleType } from '../../../api/repository/types/transverse.type';
import RichTextField from '../../../components/form/fields/richtext.field';
import * as yup from 'yup';

export interface Props {
  asset?: VideoAsset;
  onSubmit: FormikConfig<VideoAsset>['onSubmit'];
  locale: LocaleType;
}

const CreateAsset: React.FC<Props> = ({ asset, onSubmit, locale }) => {
  const initialValue: VideoAsset = {
    title: '',
    videoUrl: '',
    position: 1,
    locale: locale,
    ...asset,
    image: ''
  };

  const baseImage = typeof asset?.image === 'string' ? asset.image : asset?.image?.url;

  const schema = yup.object<Partial<VideoAsset>>({
    title: yup.string().required(),
    videoUrl: yup.string().url().required(),
    image: baseImage ? yup.string().notRequired() : yup.string().required()
  });

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValue} validationSchema={schema} validateOnMount>
      {(formik) => {
        const { setFieldValue, errors, handleChange, values, handleSubmit, isValid } = formik;

        const { title, videoUrl, position } = values;
        const baseProps = {
          errors: errors,
          onChange: handleChange,
          showError: true
        };

        const richProps = {
          onChange: () => {},
          errors: errors,
          showError: true
        };

        return (
          <Form onSubmit={handleSubmit}>
            <RichTextField
              label="Titre*"
              onBlur={(name, value) => {
                const striped = value.replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '');
                setFieldValue('title', striped);
              }}
              name={'title'}
              defaultValue={title}
              config={{ toolbar: ['bold'] }}
              {...richProps}
            />
            <InputField {...baseProps} label="videoUrl" name={'videoUrl'} defaultValue={videoUrl} />

            <ImageField
              showError
              errors={errors}
              cropProps={{ width: 630, height: 300 }}
              name="image"
              defaultValue={baseImage}
              onChange={(name, value) => {
                setFieldValue(name, value);
              }}
            />

            <InputField type="number" label="Position" name={'position'} defaultValue={position} {...baseProps} />

            <div className="text-center">
              <Button type="submit" disabled={!isValid}>
                Valider
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateAsset;
