import React, { useEffect, useState } from 'react';
import { type FormikConfig } from 'formik';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { pick } from 'underscore';
import { type CreateConfiguration, type GetOneConfiguration } from '../../api/repository/types/configuration.type';
import ConfigurationRepository from '../../api/repository/ConfigurationRepository';
import { notify } from '../../services/notification/notification';
import ConfigurationForm from './form/configuration.form';

export interface Props {
  id?: string;
}

const ConfigurationEdit: React.FC<Props> = () => {
  const [entity, setEntity] = useState<GetOneConfiguration>();
  const { id } = useParams() as { id: string };

  useEffect(() => {
    ConfigurationRepository.get(id).then((response) => {
      setEntity(response.data);
    });
  }, [id]);

  const onSubmit: FormikConfig<CreateConfiguration>['onSubmit'] = (entity: CreateConfiguration) => {
    const edit = pick(entity, ['key', 'type', 'value']);

    ConfigurationRepository.edit(id, edit).then(() => {
      notify({
        title: 'Modifié',
        message: "L'entrée a été modifié."
      });
    });
  };

  const schema = yup.object<Partial<CreateConfiguration>>({
    key: yup.string().required(),
    value: yup.string().required()
  });

  if (!entity) {
    return <>Chargement...</>;
  }

  return <ConfigurationForm initialValue={entity} onSubmit={onSubmit} validationSchema={schema} />;
};

export default ConfigurationEdit;
