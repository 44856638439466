import { type Address, type UserGet } from './user.type';
import { type GetProductType } from './product.type';
import { type GetOneProject } from './project.type';
import { type GetOneCurrency } from './currency.type';

export const ALL_PAYMENT_STATUS = ['created', 'active', 'incomplete', 'validated', 'cancelled', 'aborted', 'refunded'];
export const SHOW_PAYMENT_STATUS = ['active', 'incomplete', 'validated', 'cancelled', 'aborted', 'refunded'];

export type PaymentType = 'recurrent' | 'punctual';

export interface GetPayments {
  address: Address;
  createdAt: string;
  gateway: string;
  id: string;
  type: PaymentType;
  status: string;
  total: number;
  updatedAt: string;
  user: UserGet;
}

export interface GetOnePayment {
  address: Address;
  gateway: string;
  id: string;
  type: PaymentType;
  status: PAYMENT_STATUS;
  total: number;
  updatedAt: string;
  createdAt: string;
  user: UserGet;
  currency: GetOneCurrency;
  donations: Donation[];
}

export interface Donation {
  id: string;
  product: GetProductType;
  project: GetOneProject;
  payment: GetOnePayment;
  total: number;
  status?: string;
  data?: any;
}
export interface DonationDetails {
  id: string;
  user: UserGet;
  product?: GetProductType;
  project: GetOneProject;
  payment?: GetOnePayment;
  total: number;
  status?: string;
  data?: any;
}

export interface UserDonation {
  id: string;
  product: GetProductType;
  project: GetOneProject;
  payment: GetOnePayment;
  total: number;
  status?: string;
  media?: string;
  data?: any;
}
export enum PAYMENT_STATUS {
  CREATED_STATUS = 'created',
  ACTIVE_STATUS = 'active',
  INCOMPLETE_STATUS = 'incomplete',
  VALIDATED_STATUS = 'validated',
  CANCELLED_STATUS = 'cancelled',
  ABORTED_STATUS = 'aborted',
  REFUNDED_STATUS = 'refunded'
}
