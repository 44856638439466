import React, { useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ProjectTransfertModalProvider } from './modal/ProjectTransfertModalProvider';
import DonationRepository from '../../api/repository/DonationRepository';
import { notify } from '../../services/notification/notification';
import TablePagination from '../../components/table/TablePagination';
import ProjectRepository from '../../api/repository/ProjectRepository';
import { columns } from './services/columns';
import ProjectFilter from './form/project-filter';

interface Filter {
  name?: string;
  state?: string;
  closeAfterGoal?: boolean;
  close?: boolean;
}

const ProjectList: React.FC = () => {
  const [filterName, setFilterName] = useState<Filter>({});
  const [filterAllProjects, setFilterAllProjects] = useState<Filter>({
    closeAfterGoal: false,
    close: false
  });
  const [filterOnGoingCollects, setFilterOnGoingCollects] = useState<Filter>({
    closeAfterGoal: true,
    close: false
  });
  const [filterFinishedCollects, setFilterFinishedCollects] = useState<Filter>({
    closeAfterGoal: true,
    close: true
  });
  const [currentTab, setCurrentTab] = useState('AllProjects');

  return (
    <div className="crud-list">
      <h1>Liste des projets</h1>
      <div className="action-wrapper">
        <div>
          <Link to="/projects/new">
            <Button className="mr-2">Nouveau</Button>
          </Link>

          <Button
            variant="warning"
            onClick={() => {
              ProjectTransfertModalProvider.create({
                onSubmit: (transfertDonation) => {
                  DonationRepository.transfertDonationsFrom(transfertDonation).then((result) => {
                    notify({
                      title: 'Transfert effectué',
                      message: 'Transfert effectué avec succès'
                    });
                  });
                }
              });
            }}
          >
            Transférer des dons
          </Button>
        </div>

        <ProjectFilter
          onSubmit={(filterName) => {
            setFilterAllProjects({ ...filterAllProjects, name: filterName.name });
            setFilterOnGoingCollects({
              ...filterOnGoingCollects,
              name: filterName.name
            });
            setFilterFinishedCollects({
              ...filterFinishedCollects,
              name: filterName.name
            });
          }}
        />
      </div>
      <div className="mt-2">
        <Tabs
          defaultActiveKey="AllProjects"
          activeKey={currentTab}
          onSelect={(k) => {
            setCurrentTab(k!);
          }}
          className="mb-3"
          id="fill-tab-example"
          fill
        >
          <Tab key="AllProjects" eventKey="AllProjects" title="Tous les projets">
            <TablePagination getAllQuery={filterAllProjects} repository={ProjectRepository} columns={columns} keyField="id" remote={{ sort: true, pagination: true }} />
          </Tab>
          <Tab key="OnGoingCollects" eventKey="OnGoingCollects" title="Collectes en cours">
            <TablePagination getAllQuery={filterOnGoingCollects} repository={ProjectRepository} columns={columns} keyField="id" remote={{ sort: true, pagination: true }} />
          </Tab>
          <Tab key="FinishedCollects" eventKey="FinishedCollects" title="Collectes terminées">
            <TablePagination getAllQuery={filterFinishedCollects} repository={ProjectRepository} columns={columns} keyField="id" remote={{ sort: true, pagination: true }} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default ProjectList;
