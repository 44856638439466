import React from 'react';
import ReactDOM from 'react-dom';
import MediaModal, { type MediaModalProps } from './media.modal';
import Modal from '../../../components/modal/modal';

export interface MediaModalPropsInterface {
  onSubmit: MediaModalProps['onSubmit'];
}

export const MediaModalProvider = {
  create: (props: MediaModalProps) => {
    const container = document.createElement('media');
    document.body.append(container);
    const options = {
      onClose: () => {
        container.remove();
        ReactDOM.unmountComponentAtNode(container);
      }
    };

    ReactDOM.render(
      <Modal title="Lien média" {...options}>
        <MediaModal
          onSubmit={(media, formik) => {
            props.onSubmit?.(media, formik);
            options.onClose();
          }}
        />
      </Modal>,
      container
    );
  }
};
