import { BaseRepository } from './BaseRepository';
import { type GetProductType } from './types/product.type';

class ProductRepository<T = GetProductType> extends BaseRepository<T> {
  getArea() {
    return '/api/products';
  }

  duplicate(productId: string, projectId: string) {
    const url = this.getBaseEndpoint() + `/${productId}/duplicate-to-project`;

    return this.client.post(url, {
      project_id: projectId
    });
  }

  getByName<A = any>(name: any) {
    return super.getByName<A>(name);
  }

  getProductsByProjectName(projectName: string) {
    const url = this.getBaseEndpoint() + `/getByProject/${projectName}/`;
    return this.client.get(url);
  }
}

export default new ProductRepository();
