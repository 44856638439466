import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { type CreateProductType, type GetProductType } from '../../../api/repository/types/product.type';
import ProductView, { type ProductViewProps } from './product.view';
import { type LocaleType } from '../../../api/repository/types/transverse.type';
import { ProductProvider } from './services/CreateProduct';
import { notify } from '../../../services/notification/notification';
import ProductRepository from '../../../api/repository/ProductRepository';
import { type GetOneProject } from '../../../api/repository/types/project.type';

interface Props {
  products?: GetProductType[];
  onEdit?: ProductViewProps['onCreate'];
  onCreate?: (asset: CreateProductType, formikHelpers: any, assets: CreateProductType[]) => void;
  onDelete?: ProductViewProps['onDelete'];
  canCreate?: boolean;
  locale: LocaleType;
  project: GetOneProject;
}

const ProductList: React.FC<Props> = (props) => {
  const { canCreate = true, onDelete, onEdit, onCreate, locale, project } = props;
  const [products, setProducts] = useState<CreateProductType[]>(props.products ? props.products : []);

  return (
    <Container>
      <div className="clearfix my-3">
        <Button
          className={'float-right'}
          onClick={() => {
            ProductProvider.create({
              locale: locale,
              onSubmit: (product, helper) => {
                products.push(product);
                setProducts([...products]);
                onCreate?.(product, helper, products);
              }
            });
          }}
          disabled={!canCreate}
        >
          Ajouter un Produit
        </Button>

        <Button
          className={'mx-2 float-right'}
          onClick={() => {
            ProductProvider.duplicate({
              locale: locale,
              onSubmit: (product, helper) => {
                ProductRepository.duplicate(product.product, project.id).then(({ data }) => {
                  notify({
                    title: 'Dupliquer avec succès',
                    message: 'Veuillez recharger la page pour voir les produits dupliqués'
                  });
                });
              }
            });
          }}
          disabled={!canCreate}
        >
          Dupliquer un produit existant
        </Button>
      </div>

      <Row>
        {products
          .filter((product) => product.locale === locale)
          .map((product, number) => {
            return (
              <Col xs={12} sm={3} md={4} key={product.title}>
                <ProductView
                  locale={locale}
                  product={product as GetProductType}
                  onCreate={(product, formikHelper) => {
                    products[number] = product;
                    setProducts([...products]);
                    onEdit?.(product, formikHelper);
                  }}
                  onDelete={(product) => {
                    products.splice(number, 1);
                    onDelete?.(product);
                    setProducts([...products]);
                  }}
                />
              </Col>
            );
          })}
      </Row>
    </Container>
  );
};

export default ProductList;
